import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory,Link } from 'react-router-dom';

export default function Products() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [products, setproducts] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      }
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/products", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setproducts(data);

      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0)
  }, [])

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Produits" title="Produits" />
      <Container fluid={true} className='pb-4 mb-4'>
        <Row>
          <Col>
            <Button color='dark' onClick={() => history.push(`${process.env.PUBLIC_URL}/products/add/`)}>
              Ajouter
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className='o-auto'>
            <Table style={{ backgroundColor: "white" }}>
              <thead>
                <tr>
                  <th>Produits</th>
                  <th>DCI</th>
                  <th>Forme</th>
                  <th>Action</th>
                </tr>
              </thead>
              {products.map((p) => {
                return (
                  <tbody key={products.indexOf(p)}>
                    <tr>
                      <td>{p.product_name}</td>
                      <td>{p.product_dci}</td>
                      <td>{p.product_form}</td>
                      <td style={{ textAlign: "center" }}>
                            <Link to={{
                              pathname: `${process.env.PUBLIC_URL}/product/edit:${p.product_id}`,
                              state: {
                                product: p,
                              }
                            }}>
                              <i className="fa fa-edit" id="TooltipExample"></i>
                            </Link>
                          </td>
                    </tr>
                  </tbody>
                )
              })
              }
            </Table>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
