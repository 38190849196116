import React, { Fragment, useState } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import forms from "./form.json";

export default function AddProducts() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({
    product_name: "",
    product_dci: "",
    product_form: "",
    product_can_sale: "",
  });
  const [filterParams, setFilterParams] = useState({
    product_name: "",
    product_dci: "",
    product_form: "",
    product_price: "",
    product_can_sale: "",
    product_sales_unit: "",
  });

  const formValidation = async () => {
    for (var property in formError) {
      if (filterParams[property] === null || filterParams[property] === "") {
        formError[property] = "Champ obligatoire!";
        let obj = { ...formError };
        setFormError(obj);
      } else {
        formError[property] = "";
        let obj = { ...formError };
        setFormError(obj);
      }
    }
    await addProduct();
  }

  const addProduct = async () => {
    window.scrollTo(0, 0);
    for (var property in formError) {
      if (formError[property]) {
        return;
      }
    }
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        product_name: filterParams.product_name,
        product_dci: filterParams.product_dci,
        product_form: filterParams.product_form,
        product_price: filterParams.product_price || 0,
        product_can_sale: filterParams.product_can_sale,
        product_sales_unit: filterParams.product_sales_unit,
      })
    }
    try {
      setLoading(true);
      const responce = await fetch("https://lsreport.shls.care/api/products/new", request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success("Produit ajouter avec success");
        await sleep(2000);
        setLoading(false);
        history.push(`${process.env.PUBLIC_URL}/products`);
      } else {
        toast.error(await responce.json());
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  }

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Produits" title="Ajouter produit" />
      <Container fluid={true} className='pb-4 mb-4'>
        <p>
          <em className='mb-3'>
            * Champ obligatoire
          </em>
        </p>
        <Row>
          <form
            id='addProductForm'
            onSubmit={(e) => { e.preventDefault() }}
          >
          </form>
          <Col md="4 mb-3">
            <div className="addproduct-inputgroup">
              <label htmlFor="">Nom de produit *</label>
              <input
                form='addProductForm'
                type="text"
                placeholder='Nom'
                value={filterParams.product_name}
                onChange={(e) => { setFilterParams({ ...filterParams, product_name: e.target.value }) }} />
            </div>
            <span style={{ color: "red" }}>{filterParams.product_name ? "" : formError.product_name}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addproduct-inputgroup">
              <label htmlFor="">DCI *</label>
              <input
                form='addProductForm'
                type="text"
                placeholder='Nom'
                value={filterParams.product_dci}
                onChange={(e) => { setFilterParams({ ...filterParams, product_dci: e.target.value }) }} />
            </div>
            <span style={{ color: "red" }}>{filterParams.product_dci ? "" : formError.product_dci}</span>
          </Col>

          <Col md="4 mb-3">
            <div className="addproduct-inputgroup">
              <label htmlFor="form">Forme *</label>
              <select
                form='addProductForm'
                name="form"
                value={filterParams.product_form}
                onChange={(e) => { setFilterParams({ ...filterParams, product_form: e.target.value }) }}
              >
                <option value="" disabled>Selectioner la forme</option>
                {forms.map((form) => {
                  return (
                    <option value={form.value}>{form.value}</option>
                  )
                })}
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.product_form ? "" : formError.product_form}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addproduct-inputgroup">
              <label htmlFor="price"><span>Prix *</span></label>
              <input
                form='addProductForm'
                type="number"
                placeholder='Prix'
                value={filterParams.product_price}
                onChange={(e) => { setFilterParams({ ...filterParams, product_price: e.target.value }) }} />
            </div>
          </Col>
          <Col md="4 mb-3">
            <div className="addproduct-inputgroup">
              <label htmlFor="can_sale">Vendre*</label>
              <select
                name="can_sale"
                value={filterParams.product_can_sale}
                onChange={(e) => { setFilterParams({ ...filterParams, product_can_sale: e.target.value }) }}
              >
                <option value="" disabled>Vendre</option>
                <option value={true}>Oui</option>
                <option value={false}>Non</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.product_can_sale ? "" : formError.product_can_sale}</span>
          </Col>

          <Col md="4 mb-3">
            <div className="addproduct-inputgroup">
              <label htmlFor="sales_unit">Unité de vente</label>
              <input
                form='addProductForm'
                type="text"
                placeholder='Unité de vente'
                value={filterParams.product_sales_unit}
                onChange={(e) => { setFilterParams({ ...filterParams, product_sales_unit: e.target.value }) }} />
            </div>
          </Col>

        </Row>
        <Row>
          <Col md={12} className='justify-content-center my-3' style={{ display: "flex" }}>
            <Button color='dark' form='addProductForm' type='submit' onClick={formValidation}>Ajouter</Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
