import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import { toast } from 'react-toastify';
import TagsInput from 'react-tagsinput';
import "./AddSyntheses.css";
import wilayas from '../contacts/auxiliary/wilayas.json';
import Select from 'react-select';

function WilayaOptions() {
    let optionsArray = []
    wilayas.map((wilaya) => {
        optionsArray.push({ value: wilaya.nom, label: wilaya.nom });
    })
    optionsArray.shift();
    return optionsArray;
}

export default function AddSyntheses() {
    const token = document.cookie.split(`jwt=`).pop().split(';').shift();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState({
        sector: "",
        list_produit: "",
    });
    const [products, setProducts] = useState([]);
    const [first, setFirst] = useState("");
    const removeElement = (indexToRemove, key) => {
        [key].splice(indexToRemove, 1);
        const dr = document.getElementById(indexToRemove)
        dr.remove()
    };
    const [Verticalcenter, setVerticalcenter] = useState(false);
    const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter);
    const [Verticalcenter2, setVerticalcenter2] = useState(false);
    const Verticalcentermodaltoggle2 = () => setVerticalcenter2(!Verticalcenter2);
    const [Verticalcenter3, setVerticalcenter3] = useState(false);
    const Verticalcentermodaltoggle3 = () => setVerticalcenter3(!Verticalcenter3);
    const [Verticalcenter4, setVerticalcenter4] = useState(false);
    const Verticalcentermodaltoggle4 = () => setVerticalcenter4(!Verticalcenter4);
    const [Verticalcenter5, setVerticalcenter5] = useState(false);
    const Verticalcentermodaltoggle5 = () => setVerticalcenter5(!Verticalcenter5);
    const [Verticalcenter6, setVerticalcenter6] = useState(false);
    const Verticalcentermodaltoggle6 = () => setVerticalcenter6(!Verticalcenter6);
    const [tags, setTags] = useState([]);

    const handleChange = (newTags) => {
        setTags(newTags);
        setFilterParams({ ...filterParams, concurrent: newTags })
    };
    const [filterParams, setFilterParams] = useState({
        "sector": "",
        "remarque": "",
        "objections": "",
        "requetes_medecins": "",
        "list_produit": [{ produit: "", commentaire: "" }],
        "pharmacovigilance": [{ produit: "", commentaire: "" }],
        "experiance_produit": [{ produit: "", commentaire: "" }],
        "materiels_promotionnel": "",
        "concurrent": "",
        "produit_concurrent": [{ produit: "", commentaire: "" }],
        "nouveau_produit": [{ produit: "", commentaire: "" }],
        "disponibilite_en_pharmacie": [{produit: "", status: "", commentaire: ""}],
        "other": ""
    });

    const formValidation = async () => {
        for (var property in formError) {
            if (filterParams[property] === null || filterParams[property] === "") {
                formError[property] = "Champ obligatoire";
                let obj = { ...formError };
                setFormError(obj);
            } else {
                formError[property] = "";
                let obj = { ...formError };
                setFormError(obj);
            }
        }

        await addSynthese();
    }

    const getProducts = async () => {
        const request = {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
                'Authorization': 'Bearer ' + token
            }
        }
        try {
            var responce = await fetch("https://lsreport.shls.care/api/products", request, { mode: "cors" });
            if (responce.status === 200) {
                let data = await responce.json();
                setProducts(data);
                setFirst(data[0].product_name)
                handleChangee(0, data[0].product_name, "produit", "list_produit")
                handleChangee(0, data[0].product_name, "produit", "pharmacovigilance")
                handleChangee(0, data[0].product_name, "produit", "experiance_produit")
                handleChangee(0, data[0].product_name, "produit", "disponibilite_en_pharmacie")
            } else {
                toast.error(await responce.json());
            }
        } catch (err) {
            toast.error(err.message);
        }
    }

    const addSynthese = async () => {
        // console.log(filterParams);
        // return
        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        window.scrollTo(0, 0);
        const request = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(filterParams)
        }
        try {
            setLoading(true);
            var responce = await fetch("https://lsreport.shls.care/api/synthese/new", request, { mode: "cors" });
            if (responce.status === 200) {
                toast.success("Synthese ajouter avec success");
                await sleep(2000)
                setLoading(false);
                // history.push(`${process.env.PUBLIC_URL}/contacts`);
            } else {
                toast.error(await responce.json());
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            toast.error(err.message);
        }
    }


    useEffect(() => {
        getProducts()
        setFormError({
            sector: formError.sector,
            list_produit: formError.list_produit,
        })
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
    }, [])

    const handleChangee = (index, e, param, key) => {
        const newValue = e;

        setFilterParams(prevState => {
            // Make a shallow copy of the list_produit array
            const newListProduit = [...prevState[key]];

            // Update the specific element in the array
            newListProduit[index] = { ...newListProduit[index], [param]: newValue };

            // Return the new state with the updated list_produit
            return { ...prevState, [key]: newListProduit };
        });
        console.log(filterParams.list_produit);
    };

    const handleChangeComment = (index, e, param, key) => {
        const newValue = e.target.value;

        setFilterParams(prevState => {
            // Make a shallow copy of the list_produit array
            const newListProduit = [...prevState[key]];

            // Update the specific element in the array
            newListProduit[index] = { ...newListProduit[index], [param]: newValue };

            // Return the new state with the updated list_produit
            return { ...prevState, [key]: newListProduit };
        });
        console.log(filterParams.list_produit);
    };
    const handleChangeStatus = (index, e, param, key) => {
        const newValue = e;

        setFilterParams(prevState => {
            // Make a shallow copy of the list_produit array
            const newListProduit = [...prevState[key]];

            // Update the specific element in the array
            newListProduit[index] = { ...newListProduit[index], [param]: newValue };

            // Return the new state with the updated list_produit
            return { ...prevState, [key]: newListProduit };
        });
        console.log(filterParams.list_produit);
    };
    const AddNewProduct = (key) => {

        setFilterParams(prevState => {
            // Make a shallow copy of the list_produit array
            const newProduit = [...prevState[key]];

            // Update the specific element in the array
            if ((key === "list_produit") || (key === "pharmacovigilance") || (key === "experiance_produit")) {
                newProduit.push({ produit: first, commentaire: "" })
            } else {
                newProduit.push({ produit: "", commentaire: "" })
            }

            // Return the new state with the updated list_produit
            return { ...prevState, [key]: newProduit };
        });
    }

    return (
        <Fragment>
            <Loader isLoading={loading} />
            <Breadcrumbs parent="Syntheses" title="Ajouter Synthese" />
            <Container fluid={true} className='pb-4 mb-4'>
                <p>
                    <em className='mb-3'>
                        * Champ obligatoire
                    </em>
                </p>
                <Row>
                    <form
                        id='addContactForm'
                        onSubmit={(e) => { e.preventDefault() }}
                    >
                    </form>

                    <Col md="12">
                        <p style={{ fontSize: 24, fontWeight: "bold" }}>Information sur le secteur</p>
                    </Col>

                    <Col md="4 mb-3">
                        <div className=" ">
                            <label htmlFor="">Secteur</label>
                            <Select
                                isMulti
                                placeholder="Wilaya"
                                options={WilayaOptions()}
                                onChange={(e) => {
                                    setFilterParams({
                                        ...filterParams,
                                        sector: (e.map(obj => { return obj.value }))
                                    })
                                    console.log(filterParams)
                                }}
                            // onChange={(e) => { setFilterParams({ ...filterParams, wilaya: e.target.value }) }}
                            />
                        </div>
                    </Col>

                    <Col md="4 mb-3">
                        <div className=" ">
                            <label htmlFor="">Remarque</label>
                            <input
                                form='addContactForm'
                                type="text"
                                placeholder='Remarque'
                                value={filterParams.remarque}
                                onChange={(e) => { setFilterParams({ ...filterParams, remarque: e.target.value }) }} />
                        </div>
                    </Col>
                    <Col md="4 mb-3">
                        <div className=" ">
                            <label htmlFor="">Objections</label>
                            <input
                                form='addContactForm'
                                type="text"
                                placeholder='Objections'
                                value={filterParams.objections}
                                onChange={(e) => { setFilterParams({ ...filterParams, objections: e.target.value }) }} />
                        </div>
                    </Col>
                    <Col md="4 mb-3">
                        <div className=" ">
                            <label htmlFor="">Requetes Médecins</label>
                            <input
                                form='addContactForm'
                                type="text"
                                placeholder='Requetes Médecins'
                                value={filterParams.requetes_medecins}
                                onChange={(e) => { setFilterParams({ ...filterParams, requetes_medecins: e.target.value }) }} />
                        </div>
                    </Col>
                    <Col md="8 mb-3"></Col>
                    <Col md="12 ">
                        <p style={{ fontSize: 24, fontWeight: "bold" }}>Informations sur le laboratoire Salem</p>
                    </Col>
                    <Col md="4" className="mb-3">
                        <label>Nos produits *</label>
                        <button className='btn btn-block btn-primary' onClick={() => {
                            Verticalcentermodaltoggle()
                        }}>
                            <i className="fa fa-plus" id="TooltipExample"></i>
                        </button>

                    </Col>
                    <Col md="4" className="mb-3">
                        <label>Pharmacovigilance</label>
                        <button className='btn btn-block btn-primary' onClick={() => {
                            Verticalcentermodaltoggle2()
                        }}>
                            <i className="fa fa-plus" id="TooltipExample"></i>
                        </button>

                    </Col>
                    <Col md="4" className="mb-3">
                        <label>Experience Produit</label>
                        <button className='btn btn-block btn-primary' onClick={() => {
                            Verticalcentermodaltoggle3()
                        }}>
                            <i className="fa fa-plus" id="TooltipExample"></i>
                        </button>

                    </Col>
                    <Col md="4 mb-3">
                        <div className=" ">
                            <label htmlFor="">Materiel Promotionnel</label>
                            <input
                                form='addContactForm'
                                type="text"
                                placeholder='Materiel Promotionnel'
                                value={filterParams.materiels_promotionnel}
                                onChange={(e) => { setFilterParams({ ...filterParams, materiels_promotionnel: e.target.value }) }}
                            />
                        </div>
                    </Col>
                    <Col md="4" className="mb-3">
                        <label>Disponibilite En Pharmacie</label>
                        <button className='btn btn-block btn-primary' onClick={() => {
                            Verticalcentermodaltoggle6()
                        }}>
                            <i className="fa fa-plus" id="TooltipExample"></i>
                        </button>

                    </Col>
                    <Col md="4 mb-3"></Col>
                    {/* concurrent */}
                    <Col md="12 mb-3">
                        <p style={{ fontSize: 24, fontWeight: "bold" }}>Information sur les concurrents</p>
                    </Col>

                    <Col md="4 mb-3">
                        <div className=" ">
                            <label htmlFor="">Concurrent</label>
                            <TagsInput
                                value={tags}
                                onChange={handleChange}
                                inputProps={{ placeholder: 'Concurrents' }}
                            />
                        </div>
                    </Col>
                    <Col md="4" className="mb-3">
                        <label>Produit Concurrent</label>
                        <button className='btn btn-block btn-primary' onClick={() => {
                            Verticalcentermodaltoggle4()
                        }}>
                            <i className="fa fa-plus" id="TooltipExample"></i>
                        </button>

                    </Col>
                    <Col md="4" className="mb-3">
                        <label>Nouveau Produit</label>
                        <button className='btn btn-block btn-primary' onClick={() => {
                            Verticalcentermodaltoggle5()
                        }}>
                            <i className="fa fa-plus" id="TooltipExample"></i>
                        </button>

                    </Col>

                    <Col md="4 mb-3">
                        <div className=" ">
                            <label htmlFor="">Autres</label>
                            <input
                                form='addContactForm'
                                type="text"
                                placeholder='Autres'
                                value={filterParams.other}
                                onChange={(e) => { setFilterParams({ ...filterParams, other: e.target.value }) }} />
                        </div>
                    </Col>

                </Row>
                <Row className='pb-4'>
                    <Col md={12} className='justify-content-center my-3 pb-4' style={{ display: "flex" }}>
                        <Button className='mb-4' color='dark' form='addContactForm' type='submit' onClick={() => { addSynthese() }}>Ajouter</Button>
                    </Col>
                </Row>
            </Container>
            <Modal
                isOpen={Verticalcenter}
                toggle={Verticalcentermodaltoggle}
                centered
                size="lg"
            >
                <ModalHeader toggle={Verticalcentermodaltoggle}>
                    Ajouter des produits
                </ModalHeader>
                <ModalBody>

                    {filterParams.list_produit.map((_, index) => (
                        <div key={index} className=" " id={"list_produit" + index}>
                            <Row className='mb-2'>
                                <Col md="4">
                                    <label>Produit</label>
                                    <select id={index + "produit"} value={filterParams.list_produit[index].produit}
                                        onChange={(e) => { handleChangee(index, e.target.value, "produit", "list_produit") }}
                                    >
                                        {products.map((prod, prodIndex) => (
                                            <option key={prodIndex} value={prod.product_name}>
                                                {prod.product_name}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col md="4">
                                    <label>Commentaire</label>
                                    <input
                                        type="text"
                                        placeholder='Commentaire'
                                        id={index + "commentaire"}
                                        value={filterParams.list_produit[index].commentaire}
                                        onChange={(e) => { handleChangeComment(index, e, "commentaire", "list_produit") }}
                                    />
                                </Col>
                                <Col md="2" className='d-flex align-items-end'>
                                    <Button color='danger' onClick={() => removeElement("list_produit" + index, "list_produit")}>
                                        <i className="fa fa-minus"></i>
                                    </Button>
                                </Col>
                                {index == (filterParams.list_produit.length - 1) ? <Col md="2" className='d-flex align-items-end'>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            AddNewProduct("list_produit")
                                        }}
                                        color="success"
                                    >
                                        +
                                    </Button>
                                </Col> : ""}
                            </Row>
                        </div>
                    ))}

                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={Verticalcentermodaltoggle}>
                        Fermer
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={Verticalcenter2}
                toggle={Verticalcentermodaltoggle2}
                centered
                size="lg"
            >
                <ModalHeader toggle={Verticalcentermodaltoggle2}>
                    Ajouter Pharmacivigilance
                </ModalHeader>
                <ModalBody>

                    {filterParams.pharmacovigilance.map((_, index) => (
                        <div key={index} className=" " id={"pharmacovigilance" + index}>
                            <Row className='mb-2'>
                                <Col md="4">
                                    <label>Produit</label>
                                    <select id={index + "pharmaproduit"} value={filterParams.pharmacovigilance[index].produit}
                                        onChange={(e) => { handleChangee(index, e.target.value, "produit", "pharmacovigilance") }}
                                    >
                                        {products.map((prod, prodIndex) => (
                                            <option key={prodIndex} value={prod.product_name}>
                                                {prod.product_name}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col md="4">
                                    <label>Commentaire</label>
                                    <input
                                        type="text"
                                        placeholder='Commentaire'
                                        id={index + "pharmacommentaire"}
                                        value={filterParams.pharmacovigilance[index].commentaire}
                                        onChange={(e) => { handleChangeComment(index, e, "commentaire", "pharmacovigilance") }}
                                    />
                                </Col>
                                <Col md="2" className='d-flex align-items-end'>
                                    <Button color='danger' onClick={() => removeElement("pharmacovigilance" + index, "pharmacovigilance")}>
                                        <i className="fa fa-minus"></i>
                                    </Button>
                                </Col>
                                {index == (filterParams.pharmacovigilance.length - 1) ? <Col md="2" className='d-flex align-items-end'>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            AddNewProduct("pharmacovigilance")
                                        }}
                                        color="success"
                                    >
                                        +
                                    </Button>
                                </Col> : ""}

                            </Row>
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={Verticalcentermodaltoggle2}>
                        Fermer
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={Verticalcenter3}
                toggle={Verticalcentermodaltoggle3}
                centered
                size="lg"
            >
                <ModalHeader toggle={Verticalcentermodaltoggle3}>
                    Ajouter Experience produit
                </ModalHeader>
                <ModalBody>

                    {filterParams.experiance_produit.map((_, index) => (
                        <div key={index} className=" " id={"experience" + index}>
                            <Row className='mb-2'>
                                <Col md="4">
                                    <label>Produit</label>
                                    <select id={index + "experienceproduit"}
                                        value={filterParams.experiance_produit[index].produit}
                                        onChange={(e) => { handleChangee(index, e.target.value, "produit", "experiance_produit") }}
                                    >
                                        {products.map((prod, prodIndex) => (
                                            <option key={prodIndex} value={prod.product_name}>
                                                {prod.product_name}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col md="4">
                                    <label>Commentaire</label>
                                    <input
                                        type="text"
                                        placeholder='Commentaire'
                                        id={index + "experiencecommentaire"}
                                        value={filterParams.experiance_produit[index].commentaire}
                                        onChange={(e) => { handleChangeComment(index, e, "commentaire", "experiance_produit") }}
                                    />
                                </Col>
                                <Col md="2" className='d-flex align-items-end'>
                                    <Button color='danger' onClick={() => removeElement("experience" + index, "experiance_produit")}>
                                        <i className="fa fa-minus"></i>
                                    </Button>
                                </Col>
                                {index == (filterParams.experiance_produit.length - 1) ? <Col md="2" className='d-flex align-items-end'>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            AddNewProduct("experiance_produit")

                                        }}
                                        color="success"
                                    >
                                        +
                                    </Button>
                                </Col> : ""}
                            </Row>
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={Verticalcentermodaltoggle3}>
                        Fermer
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={Verticalcenter4}
                toggle={Verticalcentermodaltoggle4}
                centered
                size="lg"
            >
                <ModalHeader toggle={Verticalcentermodaltoggle4}>
                    Ajouter Produit Concurrent
                </ModalHeader>
                <ModalBody>

                    {filterParams.produit_concurrent.map((_, index) => (
                        <div key={index} className=" " id={"concurrent" + index}>
                            <Row className='mb-2'>
                                <Col md="4">
                                    <label>Produit</label>
                                    <input
                                        type="text"
                                        placeholder='Produit'
                                        id={index + "concurrentproduit"}
                                        value={filterParams.produit_concurrent[index].produit}
                                        onChange={(e) => { handleChangee(index, e.target.value, "produit", "produit_concurrent") }}
                                    />

                                </Col>
                                <Col md="4">
                                    <label>Commentaire</label>
                                    <input
                                        type="text"
                                        placeholder='Commentaire'
                                        id={index + "concurrentcommentaire"}
                                        value={filterParams.produit_concurrent[index].commentaire}
                                        onChange={(e) => { handleChangeComment(index, e, "commentaire", "produit_concurrent") }}
                                    />
                                </Col>
                                <Col md="2" className='d-flex align-items-end'>
                                    <Button color='danger' onClick={() => removeElement("concurrent" + index, "produit_concurrent")}>
                                        <i className="fa fa-minus"></i>
                                    </Button>
                                </Col>
                                {index == (filterParams.produit_concurrent.length - 1) ? <Col md="2" className='d-flex align-items-end'>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            AddNewProduct("produit_concurrent")
                                        }}
                                        color="success"
                                    >
                                        +
                                    </Button>
                                </Col> : ""}
                            </Row>
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={Verticalcentermodaltoggle4}>
                        Fermer
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={Verticalcenter5}
                toggle={Verticalcentermodaltoggle5}
                centered
                size="lg"
            >
                <ModalHeader toggle={Verticalcentermodaltoggle5}>
                    Ajouter Nouveau Produit
                </ModalHeader>
                <ModalBody>

                    {filterParams.nouveau_produit.map((_, index) => (
                        <div key={index} className=" " id={"nouveau" + index}>
                            <Row className='mb-2'>
                                <Col md="4">
                                    <label>Produit</label>
                                    <input
                                        type="text"
                                        placeholder='Produit'
                                        id={index + "nproduitproduit"}
                                        value={filterParams.nouveau_produit[index].produit}
                                        onChange={(e) => { handleChangee(index, e.target.value, "produit", "nouveau_produit") }}
                                    />

                                </Col>
                                <Col md="4">
                                    <label>Commentaire</label>
                                    <input
                                        type="text"
                                        placeholder='Commentaire'
                                        id={index + "nproduitcommentaire"}
                                        value={filterParams.nouveau_produit[index].commentaire}
                                        onChange={(e) => { handleChangeComment(index, e, "commentaire", "nouveau_produit") }}
                                    />
                                </Col>
                                <Col md="2" className='d-flex align-items-end'>
                                    <Button color='danger' onClick={() => removeElement("nouveau" + index, "nouveau_produit")}>
                                        <i className="fa fa-minus"></i>
                                    </Button>
                                </Col>
                                {index == (filterParams.nouveau_produit.length - 1) ? <Col md="2" className='d-flex align-items-end'>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            AddNewProduct("nouveau_produit")
                                        }}
                                        color="success"
                                    >
                                        +
                                    </Button>
                                </Col> : ""}
                            </Row>
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={Verticalcentermodaltoggle5}>
                        Fermer
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={Verticalcenter6}
                toggle={Verticalcentermodaltoggle6}
                centered
                size="lg"
            >
                <ModalHeader toggle={Verticalcentermodaltoggle6}>
                    Disponibilite En Pharmacie
                </ModalHeader>
                <ModalBody>

                    {filterParams.disponibilite_en_pharmacie.map((_, index) => (
                        <div key={index} className=" " id={"nouveau" + index}>
                            <Row className='mb-2'>
                                <Col md="3">
                                    <label>Produit</label>
                                    <select id={index + "produit"} value={filterParams.disponibilite_en_pharmacie[index].produit}
                                        onChange={(e) => { handleChangee(index, e.target.value, "produit", "disponibilite_en_pharmacie") }}
                                    >
                                        {products.map((prod, prodIndex) => (
                                            <option key={prodIndex} value={prod.product_name}>
                                                {prod.product_name}
                                            </option>
                                        ))}
                                    </select>

                                </Col>
                                <Col md="2">
                                    <label>Disponible ?</label>
                                    <select id={index + "pharmaproduit"} value={filterParams.disponibilite_en_pharmacie[index].status}
                                        onChange={(e) => { handleChangeStatus(index, e.target.value, "status", "disponibilite_en_pharmacie"); console.log(e.target.value) }}
                                    >
                                            <option value={"Oui"}>
                                                Oui
                                            </option>
                                            <option value={"Non"}>
                                                Non
                                            </option>
                                    </select>
                                </Col>
                                <Col md="3">
                                    <label>Commentaire</label>
                                    <input
                                        type="text"
                                        placeholder='Commentaire'
                                        id={index + "nproduitcommentaire"}
                                        value={filterParams.disponibilite_en_pharmacie[index].commentaire}
                                        onChange={(e) => { handleChangeComment(index, e, "commentaire", "disponibilite_en_pharmacie") }}
                                    />
                                </Col>
                                <Col md="2" className='d-flex align-items-end'>
                                    <Button color='danger' onClick={() => removeElement("nouveau" + index, "disponibilite_en_pharmacie")}>
                                        <i className="fa fa-minus"></i>
                                    </Button>
                                </Col>
                                {index == (filterParams.disponibilite_en_pharmacie.length - 1) ? <Col md="2" className='d-flex align-items-end'>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            AddNewProduct("disponibilite_en_pharmacie")
                                        }}
                                        color="success"
                                    >
                                        +
                                    </Button>
                                </Col> : ""}
                            </Row>
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={Verticalcentermodaltoggle6}>
                        Fermer
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}
