import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Nav, NavItem, Table, Card, CardHeader, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link, useLocation, useParams } from 'react-router-dom';
import * as FileSaver from 'file-saver';
import XlsxPopulate from "xlsx-populate";

export default function ValidesDetailed() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([

  ]);
  const { slug } = useParams();
  const currentGTId = slug;
  const location = useLocation();
  const [tourId, settourId] = useState("");
  const [activeTab, setActiveTab] = useState('1');
  const [exportList, setExportList] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        global_tour_id: currentGTId
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/tours/getvaliddetail", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setdata(data);
        settourId(data[0].tour_id);
        let exports = data.map((jour)=>{
                      return jour.tour_contacts.map((contact)=>{
                       return(
                         [jour.tour_title,
                       moment(jour.tour_start_date).format("YYYY-MM-DD"),
                       jour.tour_type,
                     location.state == null ? "" : location.state.user_lastname+" "+location.state.user_firstname,
                      contact.contact_lastname +" "+contact.contact_firstname,
                       contact.contact_speciality,
                       contact.contact_sector,
                        contact.contact_potential[location.state == null ? "" : location.state.user_network] ?? contact.contact_potential["Visite Pharma"],
                       contact.contact_wilaya, 
                       contact.contact_city,
                      contact.contact_id]
                       )
                       
                     })
                    
                  })
                  setExportList(exports.flat())
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  async function saveAsExcel() {
        let header = ["Journée","Date", "Type","Visiteur","Contact","Spécialité",
      "Secteur","Potentiel","Wilaya","Commune","ID Compte"];
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          const sheet1 = workbook.sheet(0).name("Plan de Tournée");
          const sheetData = [header,[exportList]]
          const totalColumns = header.length;
          sheet1.cell("A1").value(sheetData);
          const range = sheet1.usedRange();
          const endColumn = String.fromCharCode(64 + totalColumns);
          sheet1.row(1).style("bold", true);
          sheet1.range("A1:" + endColumn + "1").style("fill", "339EFF");
          range.style("border", true);
    
          return workbook.outputAsync().then((res) => {
            FileSaver.saveAs(res, location.state && ("Plan de tournée "+location.state.user_lastname+" "+location.state.user_firstname +" ("+location.state.startDate+" - "+location.state.endDate+")")+".xlsx");
          });
        });
      }

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [])

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Tournée" title="Tournée Validés" />
      <Container fluid={true}  className='pb-4 mb-4'>
        <div className="email-wrap bookmark-wrap">

          <Row className='p-4'>
            <Col md={3} className="box-col-6">
              <div className="email-left-aside">
                <Card>
                  <CardHeader>
                    <h6>{location.state == null ? "" : location.state.user_lastname} {location.state == null ? "" : location.state.user_firstname}</h6>
                    <p className='mt-0' style={{ color: "DarkBlue" }}>{location.state == null ? "" : location.state.user_network}</p>
                    <p className='mt-3 mb-1'>Du: {location.state == null ? "" : location.state.startDate}</p>
                    <p className='mb-0'>Au: {location.state == null ? "" : location.state.endDate}</p>
                  </CardHeader>
                  <CardHeader>
                  <button className='btn btn-block btn-success' onClick={saveAsExcel}>Exporter <i className='fa fa-file-excel-o ml-2'></i></button>
                  </CardHeader>
                  <CardBody>
                    <div className="email-app-sidebar left-bookmark">

                      <Nav className="main-menu" role="tablist">

                        <NavItem>
                          <span className="main-title">Tournées</span>
                        </NavItem>
                        {data.map((tour) => {
                          return (
                            <NavItem style={{ cursor: "pointer" }} onClick={() => settourId(tour.tour_id)} key={data.indexOf(tour)}>
                              <a className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                <span className="title"> {tour.tour_title}{" ("+moment(tour.tour_start_date).format("YYYY-MM-DD")+")"}</span>
                              </a>
                            </NavItem>
                          )
                        })
                        }
                      </Nav>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col xl="9" className='o-auto pb-4'>
              <Card className='mb-4'>
                <CardBody>
                  <h5>{data.length > 0 && tourId != "" ? data.filter(function (d) { return d.tour_id === tourId })[0].tour_title : ""} : {data.length > 0 && tourId != "" ? data.filter(function (d) { return d.tour_id === tourId })[0].tour_type : ""}</h5>
                  <h6>{data.length > 0 && tourId != "" ? moment(data.filter(function (d) { return d.tour_id === tourId })[0].tour_start_date).format("DD/MM/YYYY") : ""}</h6>
                  <h6>Liste des contacts</h6>
                  <br />
                  <Table>
                    <thead>
                      <tr>
                        <th>Nom / Prénom</th>
                        <th>Spécialité</th>
                        <th>Secteur</th>
                        <th>Potentiel</th>
                        <th>Wilaya</th>
                        <th>Commune</th>
                        <th>Rapport</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 && tourId != "" ? data.filter(function (d) { return d.tour_id === tourId })[0].tour_contacts.map((contact) => {
                        return (
                          <tr key={data.filter(function (d) { return d.tour_id === tourId })[0].tour_contacts.indexOf(contact)}>
                            <td>{contact.contact_lastname + " " + contact.contact_firstname}</td>
                            <td>{contact.contact_speciality}</td>
                            <td>{contact.contact_sector}</td>
                            <td>{contact.contact_potential[location.state == null ? "" : location.state.user_network] ?? contact.contact_potential["Visite Pharma"]}</td>
                            <td>{contact.contact_wilaya}</td>
                            <td>{contact.contact_city}</td>
                            <td style={{ textAlign: "center" }}>
                              {contact.report_id != null ?
                                <Link to={{
                                  pathname: `${process.env.PUBLIC_URL}/visites/rapports/detailed`,
                                  state: {
                                    reportID: contact.report_id,
                                    contact_lastname: contact.contact_lastname,
                                    contact_firstname: contact.contact_firstname,
                                    contact_speciality: contact.contact_speciality,
                                    contact_address: contact.contact_address,
                                    contact_wilaya: contact.contact_wilaya,
                                    contact_prescription_possibility: contact.contact_prescription_possibility != null ? contact.contact_prescription_possibility[location.state == null ? "" : location.state.user_network] : "",
                                    contact_potentiel: (contact.contact_potential[location.state == null ? "" : location.state.user_network] ?? contact.contact_potential["Visite Pharma"]),
                                    agent_lastname: location.state == null ? "" : location.state.user_lastname,
                                    agent_firstname: location.state == null ? "" : location.state.user_firstname,
                                    agent_network: location.state == null ? "" : location.state.user_network,
                                    contact_id: contact.contact_id,
                                    agent_id: location.state == null ? "" : location.state.user_id
                                  }
                                }}>
                                  <i className="fa fa-table" id="TooltipExample"></i>
                                </Link>
                                :
                                <p style={{ cursor: "not-allowed", color: "red" }}>
                                  <i className="fa fa-table" id="TooltipExample"></i>
                                </p>
                              }
                            </td>
                          </tr>
                        )
                      }) : <></>}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  )
}