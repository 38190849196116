import React, { Fragment, useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Loader from './layout/loader';
import { Provider } from 'react-redux';
import store from './store'
import App from './components/app'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { routes } from './route';

// Signin page
import Signin from './auth/signin'

// Error page
import Error400 from "./pages/errors/error400"
import Error401 from "./pages/errors/error401"
import Error403 from "./pages/errors/error403"
import Error404 from "./pages/errors/error404"
import Error500 from "./pages/errors/error500"
import Error503 from "./pages/errors/error503"




// setup fake backend
// configureFakeBackend();


const Root = (props) => {
  const [anim, setAnim] = useState("");
  const abortController = new AbortController();
  const [authorized, setAuthorized] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  
  //User verification
  const verifyUser = async () => {
    const token = document.cookie.split(`jwt=`).pop().split(';').shift();
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      }
    }
    try {
      setIsloading(true);
      let response = await fetch("https://lsreport.shls.care/api/verify", request, { mode: "cors" });
      if (response.status === 200) {
        setIsloading(false);
        return true;
      } else if (response.status === 401) {
        setIsloading(false);
        return false;
      } else {
        throw new Error('Unknown response')
      }
    } catch (err) {
      setIsloading(false);
      // window.location.href = `${process.env.PUBLIC_URL}/pages/errors/error500`
    }
  }

  useEffect(() => {
    verifyUser().then((data) => {
      setAuthorized(data);
    });
    return function cleanup() {
      abortController.abort();
    }
  }, []);

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Provider store={store}>
        <BrowserRouter basename={`/`}>
          <Switch>
            <Route path={`${process.env.PUBLIC_URL}/login`} component={Signin} />
           

            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400}></Route>
            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401}></Route>
            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403}></Route>
            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404}></Route>
            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500}></Route>
            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503}></Route>

           

            {authorized ?
              <>
                <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                  return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/default/`} />)
                }} />
                <TransitionGroup>
                  {routes.map(({ path, Component }) => (
                    <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                      {({ match }) => (
                        <CSSTransition
                          in={match != null}
                          timeout={100}
                          classNames={anim}
                          unmountOnExit>
                          <App >
                            <div><Component /></div>
                          </App>
                        </CSSTransition>
                      )}
                    </Route>
                  ))}
                </TransitionGroup>
              </>
              :
              <Redirect to={`${process.env.PUBLIC_URL}/login`} />
            }
          </Switch>
        </BrowserRouter>
      </Provider>
    </Fragment>
  )
}

ReactDOM.render(<Root />,
  document.getElementById('root')
);

