import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, Form, FormGroup, Table, Card, CardBody, Pagination, PaginationItem, PaginationLink, } from 'reactstrap';
import { toast } from 'react-toastify';
import "./contacts.css";
import { Link } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import speciality from './auxiliary/speciality.json';
import wilayas from './auxiliary/wilayas.json';
import communes from './auxiliary/communes.json';

export default function Contacts() {

  const [filterParams, setFilterParams] = useState({
    nomContact: "",
    speciality: "Tous",
    wilaya: "Tous",
    wilayaID: "0",
    commune: "Tous",
    adress: "",
    secteur: "Tous",
    potentiel: "Tous",
    patients: "Tous",
    KOL: "Tous",
    delaiAttente: "Tous",
    SONCAS: "Tous",
    interetProduit: "Tous",
    generique: "Tous",
    prescription: "Tous",
    informatique: "Tous"
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();

  const [filterToggle, setFilterToggle] = useState(false);
  const showSidebar = () => setFilterToggle(!filterToggle);

  const exportData = async () => {
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        contact_name: filterParams.nomContact,
        contact_wilaya: filterParams.wilaya,
        contact_city: filterParams.commune,
        contact_speciality: filterParams.speciality,
        potentiel: filterParams.potentiel,
        contact_address: filterParams.adress,
        sector: filterParams.secteur,
        nbr_patient: filterParams.patients,
        kol: filterParams.KOL,
        waiting_duration: filterParams.delaiAttente,
        soncas: filterParams.SONCAS,
        products_intrest: filterParams.interetProduit,
        generic_princeps: filterParams.generique,
        prescription_possibility: filterParams.prescription,
        application: filterParams.informatique
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/contacts/all/export", request, { mode: "cors" });
      if (responce.status === 200) {
        const blob = await responce.blob();
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download =  "Lscontacts"+'.xlsx'; // Set the desired file name
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up resources
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  // Fetchin contact details
  const fetchData = async () => {
    setLoading(true);
    setFilterToggle(false);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        item_per_page: 25,
        page: page,
        contact_name: filterParams.nomContact,
        contact_wilaya: filterParams.wilaya,
        contact_city: filterParams.commune,
        contact_speciality: filterParams.speciality,
        potentiel: filterParams.potentiel,
        contact_address: filterParams.adress,
        sector: filterParams.secteur,
        nbr_patient: filterParams.patients,
        kol: filterParams.KOL,
        waiting_duration: filterParams.delaiAttente,
        soncas: filterParams.SONCAS,
        products_intrest: filterParams.interetProduit,
        generic_princeps: filterParams.generique,
        prescription_possibility: filterParams.prescription,
        application: filterParams.informatique
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/contacts/all", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setData(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }
  // Updating Contact

  const paginationNumber = (page) => {
    setPage(page);
  }
  const totalpages = () => {
    if (data.length > 0) {
      const total = Math.ceil(data[0].full_count / 25);
      return total;
    } else {
      return 0
    }
  }

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [page])

  const filterFunction = () => {
    let newArray = communes.filter(item => item.wilaya_id == filterParams.wilayaID);
    newArray.unshift({ id: 0, nom: "Tous" });
    return newArray;
  }

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Contacts" title="Contacts" />
      <div className={filterToggle ? "filter-toggle-true filter-wrapper" : "filter-toggle-false filter-wrapper"}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <div className="input-wrapper">
              <h4>Filtre</h4>
              <input
                name='nomContact'
                value={filterParams.nomContact}
                placeholder='Nom Contact'
                onChange={(e) => { setFilterParams({ ...filterParams, nomContact: e.target.value }) }}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="specialty"><span>Spécialité: {' '}</span></label>
              <select name="specialty" value={filterParams.speciality} onChange={(e) => { setFilterParams({ ...filterParams, speciality: e.target.value }) }}>
                {speciality.map((s) => {
                  return (
                    <option key={speciality.indexOf(s)} value={s}>{s}</option>
                  )
                })}
              </select>
            </div>
            <div className="input-wrapper">
              <label htmlFor="wilayas">Wilaya: </label>
              <select name="wilayas" value={filterParams.wilaya} onChange={(e) => { setFilterParams({ ...filterParams, wilaya: e.target.value, wilayaID: wilayas.findIndex((wilaya) => wilaya.nom === e.target.value) }) }}>
                {wilayas.map((w) => {
                  return (
                    <option key={wilayas.indexOf(w)} value={w.nom}>{w.nom}</option>
                  )
                })}
              </select>
            </div>
            <div className="input-wrapper">
              <label htmlFor="communes">Communes: </label>
              <select name="communes" disabled={filterParams.wilaya === "Tous" ? true : false} onChange={(e) => { setFilterParams({ ...filterParams, commune: e.target.value }) }}>
                {filterFunction().map(c => {
                  return (
                    <option key={communes.indexOf(c)} value={c.nom}>{c.nom}</option>
                  )
                })}
              </select>
            </div>
            <div className="input-wrapper">
              <input
                name='adress'
                value={filterParams.adress}
                placeholder='Adress'
                onChange={(e) => { setFilterParams({ ...filterParams, adress: e.target.value }) }}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="secteur">Sécteur: </label>
              <select name="secteur" value={filterParams.secteur} onChange={(e) => { setFilterParams({ ...filterParams, secteur: e.target.value }) }}>
                <option value="Tous">Tous</option>
                <option value="Privé">Privé</option>
                <option value="Public">Public</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label htmlFor="potentiel">Potentiel: </label>
              <select name="potentiel" value={filterParams.potentiel} onChange={(e) => { setFilterParams({ ...filterParams, potentiel: e.target.value }) }}>
                <option value="Tous">Tous</option>
                <option value="A+">A+</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label htmlFor="patients">Nombre patients: </label>
              <select name="patients" value={filterParams.patients} onChange={(e) => { setFilterParams({ ...filterParams, patients: e.target.value }) }}>
                <option value="Tous">Tous</option>
                <option value="< 10">{"< 10"}</option>
                <option value="10 - 20">{"10 - 20"}</option>
                <option value="> 20">{"> 20"}</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label htmlFor="kol">KOL: </label>
              <select name="kol" value={filterParams.KOL} onChange={(e) => { setFilterParams({ ...filterParams, KOL: e.target.value }) }}>
                <option value="Tous">Tous</option>
                <option value="true">Oui</option>
                <option value="false">Non</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label htmlFor="delai">Délai d'attente: </label>
              <select name="delai" value={filterParams.delaiAttente} onChange={(e) => { setFilterParams({ ...filterParams, delaiAttente: e.target.value }) }}>
                <option value="Tous">Tous</option>
                <option value="Trés long">Trés long</option>
                <option value="Long">Long</option>
                <option value="Standard">Standard</option>
                <option value="Rapide">Rapide</option>
                <option value="Immédiat">Immédiat</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label htmlFor="SONCAS">SONCAS: </label>
              <select name="SONCAS" value={filterParams.SONCAS} onChange={(e) => { setFilterParams({ ...filterParams, SONCAS: e.target.value }) }}>
                <option value="Tous">Tous</option>
                <option value="Sympathique">Sympathique</option>
                <option value="Confort">Confort</option>
                <option value="Argent">Argent</option>
                <option value="Nouveauté">Nouveauté</option>
                <option value="Orgueil">Orgueil</option>
                <option value="Scientifique">Scientifique</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label htmlFor="interet">Inéret pour notre produit / marque: </label>
              <select name="interet" value={filterParams.interetProduit} onChange={(e) => { setFilterParams({ ...filterParams, interetProduit: e.target.value }) }}>
                <option value="Tous">Tous</option>
                <option value="Neutre">Neutre</option>
                <option value="Favorable">Favorable</option>
                <option value="Trés favorable">Trés favorable</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label htmlFor="generique">Princeps contre génerique: </label>
              <select name="generique" value={filterParams.generique} onChange={(e) => { setFilterParams({ ...filterParams, generique: e.target.value }) }}>
                <option value="Tous">Tous</option>
                <option value="Génerique">Génerique</option>
                <option value="Princeps">Princeps</option>
                <option value="Notre génerique">Notre génerique</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label htmlFor="prescription">Possibilité de prescription: </label>
              <select name="prescription" value={filterParams.prescription} onChange={(e) => { setFilterParams({ ...filterParams, prescription: e.target.value }) }}>
                <option value="Tous">Tous</option>
                <option value="Nulle">Nulle</option>
                <option value="Faible">Faible</option>
                <option value="Moyenne">Moyenne</option>
                <option value="Forte">Forte</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label htmlFor="informatique">Informatique: </label>
              <select name="informatique" value={filterParams.informatique} onChange={(e) => { setFilterParams({ ...filterParams, informatique: e.target.value }) }}>
                <option value="Tous">Tous</option>
                <option value="J'aime pas">J'aime pas</option>
                <option value="Non">Non</option>
                <option value="J'ai besion d'aide">J'ai besion d'aide</option>
                <option value="Oui">Oui</option>
              </select>
            </div>
            <div className='search-btn my-3'>
              <Button color='dark' type='submit' onClick={() => {
                if (page === 1) {
                  fetchData()
                } else {
                  setPage(1);
                }
              }}>Rechercher</Button>
            </div>
            <div className='search-btn my-3'>
              <Button color='dark' onClick={() => setFilterParams({
                nomContact: "",
                speciality: "Tous",
                wilaya: "Tous",
                wilayaID: "0",
                commune: "Tous",
                adress: "",
                secteur: "Tous",
                potentiel: "Tous",
                patients: "Tous",
                KOL: "Tous",
                delaiAttente: "Tous",
                SONCAS: "Tous",
                interetProduit: "Tous",
                generique: "Tous",
                prescription: "Tous",
                informatique: "Tous"
              })}>Réinitialiser</Button>
            </div>
          </FormGroup>
        </Form>
      </div>
      <Container fluid={true} className='pb-4 mb-4'>
        <Row>
          <Col>
            <Button color='dark' className='mr-3 mb-3'>
              <Link to="/contacts/add" style={{ color: "white" }}>Ajouter</Link>
            </Button>
            <Button color='dark' className='mr-3 mb-3' onClick={showSidebar}>
              Filtrer
            </Button>
            <Button color='success' className=' mb-3' onClick={exportData}>
              Exporter
            </Button>
          </Col>
        </Row>
        {data.length === 0 ?
          <Card className='text-align-center'>
            <CardBody style={{ textAlign: "center" }}>
              <h4>Aucun contact à afficher</h4>
            </CardBody>
          </Card>
          :
          <>
            <Row>
              <Col xs={12} className='o-auto'>
                <Table style={{ background: "white" }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Code</th>
                      <th>Nom / Prénom</th>
                      <th>Spécialité</th>
                      <th>Secteur</th>
                      <th>Potentiel</th>
                      <th>Adress</th>
                      <th>Wilaya</th>
                      <th>Commune</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((contact) => {
                      return (
                        <tr key={data.indexOf(contact)}>
                          <th scope='row'> {(data.indexOf(contact) + 1) + ((page - 1) * 25)}</th>
                          <td>{contact.contact_id}</td>
                          <td>{contact.contact_lastname + " " + contact.contact_firstname}</td>
                          <td>{contact.contact_speciality}</td>
                          <td>{contact.contact_sector}</td>
                          <td>{contact.contact_potential[jwt.decode(token).network] ?? contact.contact_potential["Visite Pharma"]}</td>
                          <td>{contact.contact_address}</td>
                          <td>{contact.contact_wilaya}</td>
                          <td>{contact.contact_city}</td>
                          <td style={{ textAlign: "center" }}>
                            <Link to={{
                              pathname: `${process.env.PUBLIC_URL}/contacts/contact:${contact.contact_id}`,
                              state: {
                                contact: contact,
                              }
                            }}>
                              <i className="fa fa-table" id="TooltipExample"></i>
                            </Link>
                          </td>
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className='my-5'>
              <Col>
                <Pagination aria-label="Page navigation example" className="pagination justify-content-center pagination-primary" >
                  <PaginationItem><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : 1)}><span aria-hidden="true">«</span><span className="sr-only">Previous</span></PaginationLink></PaginationItem>
                  {(totalpages() === 1) &&
                    <PaginationItem active={true}><PaginationLink >{page}</PaginationLink></PaginationItem>
                  }
                  {(totalpages() === 2) &&
                    <>
                      <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 1 ? page : page - 1)}>{page === 1 ? page : page - 1}</PaginationLink></PaginationItem>
                      <PaginationItem active={page === 2 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 2 ? page : page + 1)}>{page === 2 ? page : page + 1}</PaginationLink></PaginationItem>
                    </>
                  }
                  {(totalpages() > 2) &&
                    <>
                      <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : page)}>{page === totalpages() ? page - 2 : page > 1 ? page - 1 : page}</PaginationLink></PaginationItem>
                      <PaginationItem active={page != 1 && page != totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page - 1 : page > 1 ? page : page + 1)}>{page === totalpages() ? page - 1 : page > 1 ? page : page + 1}</PaginationLink></PaginationItem>
                      <PaginationItem active={page === totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page : page > 1 ? page + 1 : page + 2)}>{page === totalpages() ? page : page > 1 ? page + 1 : page + 2}</PaginationLink></PaginationItem>
                    </>
                  }
                  <PaginationItem><PaginationLink onClick={() => paginationNumber((totalpages() <= page) ? page : page + 1)}><span aria-hidden="true">»</span><span className="sr-only">Next</span></PaginationLink></PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </>
        }

      </Container>
    </Fragment>
  )
}
