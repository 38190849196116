import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import speciality from './auxiliary/speciality.json';
import wilayas from './auxiliary/wilayas.json';
import communes from './auxiliary/communes.json';
import "./Addcontact.css";

export default function Addcontact() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({
    lastname: "",
    firstname: "",
    wilaya: "",
    commune: "",
    adress: "",
    sexe: "",
    speciality: "",
    secteur: "",
    patients: "",
    area: ""
  });
  const [filterParams, setFilterParams] = useState({
    lastname: "",
    firstname: "",
    establishment: "",
    wilaya: "",
    wilayaID: "0",
    commune: "",
    adress: "",
    sexe: "",
    age: "",
    phone: "",
    email: "",
    speciality: "",
    secteur: "",
    potentiel: "",
    patients: "",
    KOL: "",
    delaiAttente: "",
    SONCAS: "",
    interetProduit: "",
    generique: "",
    prescription: "",
    informatique: "",
    area: ""
  });

  const formValidation = async () => {
    for (var property in formError) {
      if (filterParams[property] === null || filterParams[property] === "") {
        formError[property] = "Champ obligatoire";
        let obj = { ...formError };
        setFormError(obj);
      } else {
        formError[property] = "";
        let obj = { ...formError };
        setFormError(obj);
      }
    }

    await addcontacts();
  }

  const filterFunction = () => {
    let newArray = communes.filter(item => item.wilaya_id == filterParams.wilayaID);
    newArray.unshift({ id: 0, nom: "Tous" });
    return newArray;
  }

  const addcontacts = async () => {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    window.scrollTo(0, 0);
    for (var property in formError) {
      if (formError[property]) {
        return;
      }
    }
    const formData = new FormData();
    formData.append("contact_lastname", filterParams.lastname)
    formData.append("contact_firstname", filterParams.firstname)
    formData.append("contact_wilaya", filterParams.wilaya)
    formData.append("contact_speciality", filterParams.speciality)
    formData.append("contact_age", filterParams.age)
    formData.append("contact_city", filterParams.commune)
    formData.append("contact_email", filterParams.email)
    formData.append("contact_establishment", filterParams.establishment)
    formData.append("contact_sexe", filterParams.sexe)
    formData.append("contact_sector", filterParams.secteur)
    formData.append("contact_nbr_patient", filterParams.patients)
    formData.append("contact_waiting_duration", filterParams.speciality === "Pharmacien" ? "" : filterParams.delaiAttente)
    formData.append("contact_products_intrest", filterParams.speciality === "Pharmacien" ? "" : filterParams.interetProduit)
    formData.append("contact_kol", filterParams.speciality === "Pharmacien" ? "" : filterParams.KOL)
    formData.append("contact_soncas", filterParams.speciality === "Pharmacien" ? "" : filterParams.SONCAS)
    formData.append("contact_address", filterParams.adress)
    formData.append("contact_phone", filterParams.phone)
    formData.append("generic_princeps", filterParams.speciality === "Pharmacien" ? "" : filterParams.generique)
    formData.append("application", filterParams.speciality === "Pharmacien" ? "" : filterParams.informatique)
    formData.append("contact_prescription_possibility", filterParams.speciality === "Pharmacien" ? "" : filterParams.prescription);
    formData.append("pharmacie_area", filterParams.speciality === "Pharmacien" ? filterParams.area : "")
    const request = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: formData
    }
    try {
      setLoading(true);
      var responce = await fetch("https://lsreport.shls.care/api/contacts/new", request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success("Contact ajouter avec success");
        await sleep(2000)
        setLoading(false);
        history.push(`${process.env.PUBLIC_URL}/contacts`);
      } else {
        toast.error(await responce.json());
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  }
  useEffect(() => {
    if (filterParams.speciality === "Pharmacien") {
      setFormError({
        lastname: formError.lastname,
        firstname: formError.firstname,
        wilaya: formError.wilaya,
        commune: formError.commune,
        adress: formError.adress,
        sexe: formError.sexe,
        speciality: formError.speciality,
        secteur: formError.secteur,
        patients: formError.patients,
        area: formError.area
      });
    } else {
      setFormError({
        lastname: formError.lastname,
        firstname: formError.firstname,
        wilaya: formError.wilaya,
        commune: formError.commune,
        adress: formError.adress,
        sexe: formError.sexe,
        speciality: formError.speciality,
        secteur: formError.secteur,
        patients: formError.patients,
        KOL: formError.KOL,
        delaiAttente: formError.delaiAttente,
        SONCAS: formError.SONCAS,
        interetProduit: formError.interetProduit,
        generique: formError.generique,
        prescription: formError.prescription,
        informatique: formError.informatique
      })
    }
    return () => {
    }
  }, [filterParams.speciality])

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, [])

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Contacts" title="Ajouter contact" />
      <Container fluid={true} className='pb-4 mb-4'>
        <p>
          <em className='mb-3'>
            * Champ obligatoire
          </em>
        </p>
        <Row>
          <form
            id='addContactForm'
            onSubmit={(e) => { e.preventDefault() }}
          >
          </form>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="">Nom *</label>
              <input
                form='addContactForm'
                type="text"
                placeholder='Nom'
                value={filterParams.lastname}
                onChange={(e) => { setFilterParams({ ...filterParams, lastname: e.target.value }) }} />
            </div>
            <span style={{ color: "red" }}><em>{filterParams.lastname ? "" : formError.lastname}</em></span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="">Prénom *</label>
              <input
                form='addContactForm'
                type="text"
                placeholder='Prénom'
                value={filterParams.firstname}
                onChange={(e) => { setFilterParams({ ...filterParams, firstname: e.target.value }) }} />
            </div>
            <span style={{ color: "red" }}>{filterParams.firstname ? "" : formError.firstname}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="">Etablissement</label>
              <input
                form='addContactForm'
                type="text"
                placeholder='Etablissement'
                value={filterParams.establishment}
                onChange={(e) => { setFilterParams({ ...filterParams, establishment: e.target.value }) }} />
            </div>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="wilayas">Wilaya *</label>
              <select
                form='addContactForm'
                name="wilayas"
                value={filterParams.wilaya}
                onChange={(e) => { setFilterParams({ ...filterParams, wilaya: e.target.value, wilayaID: wilayas.findIndex((wilaya) => wilaya.nom === e.target.value) }) }}
              >
                {wilayas.map((w) => {
                  return (
                    <option disabled={w.nom === "Tous" ? true : false} key={wilayas.indexOf(w)} value={w.nom === "Tous" ? "" : w.nom}>{w.nom === "Tous" ? "Wilaya" : w.nom}</option>
                  )
                })}
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.wilaya ? "" : formError.wilaya}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="communes">Communes *</label>
              <select required
                form='addContactForm'
                name="communes"
                disabled={filterParams.wilaya === "" ? true : false}
                defaultValue={"Commune"}
                onChange={(e) => { setFilterParams({ ...filterParams, commune: e.target.value }) }}>
                {filterFunction().map(c => {
                  return (
                    <option disabled={c.nom === "Tous" ? true : false} key={communes.indexOf(c)} value={c.nom === "Tous" ? "Commune" : c.nom}>{c.nom === "Tous" ? "Commune" : c.nom}</option>
                  )
                })}
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.commune ? "" : formError.commune}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="">Adresse *</label>
              <input
                form='addContactForm'
                type="text"
                placeholder='Adresse'
                value={filterParams.adress}
                onChange={(e) => { setFilterParams({ ...filterParams, adress: e.target.value }) }} />
            </div>
            <span style={{ color: "red" }}>{filterParams.adress ? "" : formError.adress}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="communes">Sexe *</label>
              <select
                required
                defaultValue={"Sexe"}
                form='addContactForm'
                name="sexe"
                onChange={(e) => { setFilterParams({ ...filterParams, sexe: e.target.value }) }}>
                <option value="Sexe" disabled>Sexe</option>
                <option value="Homme">Homme</option>
                <option value="Femme">Femme</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.sexe ? "" : formError.sexe}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="">Age</label>
              <input
                form='addContactForm'
                type="number"
                placeholder='Age'
                value={filterParams.age}
                onChange={(e) => { setFilterParams({ ...filterParams, age: e.target.value }) }} />
            </div>
            <span style={{ color: "red" }}>{filterParams.age ? "" : formError.age}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="">Téléphone</label>
              <input
                form='addContactForm'
                type="tel"
                placeholder='Téléphone'
                value={filterParams.phone}
                onChange={(e) => { setFilterParams({ ...filterParams, phone: e.target.value }) }} />
            </div>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="">Email</label>
              <input
                form='addContactForm'
                type="email"
                placeholder='Email'
                value={filterParams.email}
                onChange={(e) => { setFilterParams({ ...filterParams, email: e.target.value }) }} />
            </div>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="specialty"><span>Spécialité *</span></label>
              <select
                required
                name="specialty"
                defaultValue={"Spécialité"}
                onChange={(e) => { setFilterParams({ ...filterParams, speciality: e.target.value }) }}>
                {speciality.map((s) => {
                  return (
                    <option disabled={s === "Tous" ? true : false} key={speciality.indexOf(s)} value={s === "Tous" ? "Spécialité" : s}>{s === "Tous" ? "Spécialité" : s}</option>
                  )
                })}
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.speciality ? "" : formError.speciality}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="secteur">Secteur *</label>
              <select required
                form='addContactForm'
                name="secteur"
                defaultValue={"Secteur"}
                onChange={(e) => { setFilterParams({ ...filterParams, secteur: e.target.value }) }}>
                <option value="Secteur" disabled>Secteur</option>
                <option value="Privé">Privé</option>
                <option value="Public">Public</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.secteur ? "" : formError.secteur}</span>

          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="paitents">Nombre patient / jour *</label>
              <select required
                form='addContactForm'
                name="patients"
                defaultValue={"Nombre patient / jour"}
                onChange={(e) => { setFilterParams({ ...filterParams, patients: e.target.value }) }}>
                <option value="Nombre patient / jour" disabled>Nombre patient / jour</option>
                <option value="< 15">{"< 15"}</option>
                <option value="15 - 30">{"15 - 30"}</option>
                <option value="> 30">{"> 30"}</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.patients ? "" : formError.patients}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="area">Surface pharmacie *</label>
              <select required
                form='addContactForm'
                name="area"
                defaultValue={"Surface pharmacie"}
                disabled={filterParams.speciality === "Pharmacien" ? false : true}
                onChange={(e) => { setFilterParams({ ...filterParams, area: e.target.value }) }}>
                <option value="Surface pharmacie" disabled>Surface pharmacie</option>
                <option value="< 40 m²">{"< 40 m²"}</option>
                <option value="40 - 70 m²">{"40 - 70 m²"}</option>
                <option value="70 - 100 m²">{"70 - 100 m²"}</option>
                <option value="> 100 m²">{"> 100 m²"}</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.area ? "" : formError.area}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="delai">Délai d'attente {filterParams.speciality === "Pharmacien" ? "" : "*"}</label>
              <select
                required
                name="delai"
                disabled={filterParams.speciality === "Pharmacien" ? true : false}
                defaultValue={"Délai d'attente"}
                onChange={(e) => { setFilterParams({ ...filterParams, delaiAttente: e.target.value }) }}
              >
                <option value="Délai d'attente" disabled>Délai d'attente</option>
                <option value="Trés long">Trés long</option>
                <option value="Long">Long</option>
                <option value="Standard">Standard</option>
                <option value="Rapide">Rapide</option>
                <option value="Immédiat">Immédiat</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.delaiAttente ? "" : formError.delaiAttente}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="kol">KOL {filterParams.speciality === "Pharmacien" ? "" : "*"}</label>
              <select
                required
                name="kol"
                disabled={filterParams.speciality === "Pharmacien" ? true : false}
                defaultValue={"KOL"}
                onChange={(e) => { setFilterParams({ ...filterParams, KOL: e.target.value }) }}
              >
                <option value="KOL" disabled>KOL</option>
                <option value="true">Oui</option>
                <option value="false">Non</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.KOL ? "" : formError.KOL}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="SONCAS">SONCAS {filterParams.speciality === "Pharmacien" ? "" : "*"}</label>
              <select
                required
                name="SONCAS"
                disabled={filterParams.speciality === "Pharmacien" ? true : false}
                defaultValue={"SONCAS"}
                onChange={(e) => { setFilterParams({ ...filterParams, SONCAS: e.target.value }) }}
              >
                <option value="SONCAS" disabled>SONCAS</option>
                <option value="Sympathique">Sympathique</option>
                <option value="Confort">Confort</option>
                <option value="Argent">Argent</option>
                <option value="Nouveauté">Nouveauté</option>
                <option value="Orgueil">Orgueil</option>
                <option value="Scientifique">Scientifique</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.SONCAS ? "" : formError.SONCAS}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="interet">Inéret pour notre produit / marque {filterParams.speciality === "Pharmacien" ? "" : "*"}</label>
              <select
                required
                name="interet"
                disabled={filterParams.speciality === "Pharmacien" ? true : false}
                defaultValue={"Inéret pour notre produit / marque"}
                onChange={(e) => { setFilterParams({ ...filterParams, interetProduit: e.target.value }) }}
              >
                <option value="Inéret pour notre produit / marque" disabled>Inéret pour notre produit / marque:</option>
                <option value="Neutre">Neutre</option>
                <option value="Favorable">Favorable</option>
                <option value="Trés favorable">Trés favorable</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.interetProduit ? "" : formError.interetProduit}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="generique">Princeps contre génerique {filterParams.speciality === "Pharmacien" ? "" : "*"}</label>
              <select
                required
                name="genérique"
                disabled={filterParams.speciality === "Pharmacien" ? true : false}
                defaultValue={"Princeps contre génerique"}
                onChange={(e) => { setFilterParams({ ...filterParams, generique: e.target.value }) }}
              >
                <option value="Princeps contre génerique" disabled>Princeps contre génerique:</option>
                <option value="Générique">Générique</option>
                <option value="Princeps">Princeps</option>
                <option value="Notre génerique">Notre génerique</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.generique ? "" : formError.generique}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="prescription">Possibilité de prescription {filterParams.speciality === "Pharmacien" ? "" : "*"}</label>
              <select
                required
                name="prescription"
                disabled={filterParams.speciality === "Pharmacien" ? true : false}
                defaultValue={"Possibilité de prescription"}
                onChange={(e) => { setFilterParams({ ...filterParams, prescription: e.target.value }) }}
              >
                <option value="Possibilité de prescription" disabled>Possibilité de prescription:</option>
                <option value="Nulle">Nulle</option>
                <option value="Faible">Faible</option>
                <option value="Moyenne">Moyenne</option>
                <option value="Forte">Forte</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.prescription ? "" : formError.prescription}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="informatique">Informatique {filterParams.speciality === "Pharmacien" ? "" : "*"}</label>
              <select
                required
                name="informatique"
                disabled={filterParams.speciality === "Pharmacien" ? true : false}
                defaultValue={"Informatique"}
                onChange={(e) => { setFilterParams({ ...filterParams, informatique: e.target.value }) }}
              >
                <option value="Informatique" disabled>Informatique: </option>
                <option value="J'aime pas">J'aime pas</option>
                <option value="Non">Non</option>
                <option value="J'ai besion d'aide">J'ai besion d'aide</option>
                <option value="Oui">Oui</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.informatique ? "" : formError.informatique}</span>
          </Col>
        </Row>
        <Row className='pb-4'>
          <Col md={12} className='justify-content-center my-3 pb-4' style={{ display: "flex" }}>
            <Button className='mb-4' color='dark' form='addContactForm' type='submit' onClick={formValidation}>Ajouter</Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
