import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, FormGroup, Form, Input, Modal, ModalFooter, ModalBody, ModalHeader, Button, CardHeader, Table, Card, CardBody, Pagination, PaginationItem, PaginationLink, } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import speciality from '../contacts/auxiliary/speciality.json';
import wilayas from '../contacts/auxiliary/wilayas.json';
import communes from '../contacts/auxiliary/communes.json';
import Swal from 'sweetalert2';
import * as FileSaver from 'file-saver';
import XlsxPopulate from "xlsx-populate";


export default function UserDetails() {
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [addModal, setAddModal] = useState(false);
  const addToggle = () => { setAddModal(!addModal) };
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [user, setUser] = useState({
    user_favorites: [],
    user_wilaya: [],
    user_supervisors: [],
  });
  const { slug } = useParams();
  const current_user_id = slug;
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const location = useLocation();
  const [data, setdata] = useState([]);
  const [tour, settour] = useState({ tour_contacts: [] });
  const [contacts, setcontacts] = useState([]);
  const [pfContacts, setPfContacts] = useState([]);

  const [filterParams, setFilterParams] = useState({
    nomContact: "",
    speciality: "Tous",
    wilaya: "Tous",
    wilayaID: "0",
    commune: "Tous",
    adress: "",
    secteur: "Tous",
    potentiel: "Tous",
    patients: "Tous",
    KOL: "Tous",
    delaiAttente: "Tous",
    SONCAS: "Tous",
    interetProduit: "Tous",
    generique: "Tous",
    prescription: "Tous",
    informatique: "Tous"
  });
  const [page, setPage] = useState(1);
  const paginationNumber = (page) => {
    setPage(page);
  }
  const totalpages = () => {
    if (contacts.length > 0) {
      const total = Math.ceil(contacts[0].full_count / 25);
      return total;
    } else {
      return 0
    }
  }
  const filterFunction = () => {
    let newArray = communes.filter(item => item.wilaya_id == filterParams.wilayaID);
    newArray.unshift({ id: 0, nom: "Tous" });
    return newArray;
  }

  const fetchContacts = async () => {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        item_per_page: 20,
        page: page,
        contact_name: filterParams.nomContact,
        contact_wilaya: filterParams.wilaya,
        contact_city: filterParams.commune,
        contact_speciality: filterParams.speciality,
        potentiel: filterParams.potentiel,
        contact_address: filterParams.adress,
        sector: filterParams.secteur,
        nbr_patient: filterParams.patients,
        kol: filterParams.KOL,
        waiting_duration: filterParams.delaiAttente,
        soncas: filterParams.SONCAS,
        products_intrest: filterParams.interetProduit,
        generic_princeps: filterParams.generique,
        prescription_possibility: filterParams.prescription,
        application: filterParams.informatique
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/contacts/all", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setcontacts(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }
  useEffect(() => {
    fetchContacts();
    window.scrollTo(0, 0);
  }, [page])

  const fetchData = async () => {
    if (current_user_id == null) {
      return;
    }
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ user_id: current_user_id })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/users/user", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        console.log(data);
        setUser(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const confirmDelete = (contact) => {
    Swal.fire({
      title: "Voulez vous vraiment supprimer le contact du portefeuille ?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      denyButtonText: `Annuler`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteContact(contact)
      } else if (result.isDenied) {
        return
      }
    });
  }
  const deleteUser = async () => {
    if (current_user_id == null) {
      return;
    }
    toggle();
    setLoading(true);
    const request = {
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ user_id: current_user_id })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/users/delete", request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success("Utilisateur supprimé avec succès");
        await sleep(2000);
        setLoading(false);
        history.push(`${process.env.PUBLIC_URL}/users`);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const deleteContact = async (contact) => {
    if (current_user_id == null) {
      return;
    }
    setLoading(true);
    const request = {
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ user_id: current_user_id, contact_id: contact })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/users/user/pf/delete", request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success("Contact supprimé avec succès");
        fetchData()
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const addContact = (contact) => {
    let conts = pfContacts
    conts.push(contact)
    setPfContacts(conts)

  }

  const asignContact = async () => {
    if (current_user_id == null) {
      return;
    }
    addToggle();
    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ user_id: current_user_id, contact_ids: pfContacts })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/users/user/pf/add", request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success("Portefeuille mis à jour avec succès");
        fetchData()
      } else {
        toast.error("Erreur !");
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  }
  async function saveAsExcel() {
    let exports = user.user_favorites.map((contact)=>{
      
       return(
        [
          contact.contact_id,
          contact.contact_lastname+" "+contact.contact_firstname,
          contact.contact_speciality,
          contact.contact_sector,
          contact.contact_potential[user.user_network] ?? contact.contact_potential["Visite Pharma"],
          contact.contact_address,
          contact.contact_wilaya,
          contact.contact_city,
        ]
       )
       
  })


    let header = ["Contact ID", "Nom / Prénom","Spécialité", "Secteur","Potentiel","Adresse","Wilaya","Commune"];
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0).name("Plan de Tournée");
      const sheetData = [header,[exports]]
      const totalColumns = header.length;
      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "339EFF");
      range.style("border", true);

      return workbook.outputAsync().then((res) => {
        FileSaver.saveAs(res, "PF De "+user.user_lastname+" "+user.user_firstname+".xlsx");
      });
    });
  }

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Utilisateurs" title="Detail utilisateur" />
      <Container fluid={true}  className='pb-4 mb-4'>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col xl="4">
                    <h4>{user.user_lastname} {user.user_firstname}
                      <i className="fa fa-edit mx-1 text-warning" onClick={() => {
                        history.push({
                          pathname: `${process.env.PUBLIC_URL}/users/modify`,
                          state: {
                            user: user
                          }
                        })
                      }}></i>
                      <i className="fa fa-trash text-danger" onClick={toggle}></i>
                    </h4>
                    <h6 style={{ color: "DarkBlue" }}>{user.user_type == "admin" ? "Administrateur" : user.user_type == "manager" ? "Chéf réseau" : user.user_type == "supervisor" ? "Superviseur" : user.user_type == "agent" ? "Délégué" : ""}</h6>
                    <br />
                    <Table>
                      <tbody>
                        <tr>
                          <th>Email</th>
                          <td style={{ overflowWrap: "anywhere" }}>{user.user_email}</td>
                        </tr>
                        <tr>
                          <th>Tél</th>
                          <td>{user.user_phone}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <Button onClick={() => {
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/visitesDuo/rapports`,
                        state: {
                          user: {user_id:user.user_id, user_lastname:user.user_lastname,user_firstname:user.user_firstname}
                        }
                      })
                    }} color='dark' className=' btn btn-block '>Fiches De Duo</Button>
                    <Modal isOpen={modal} toggle={toggle} >
                      <ModalHeader toggle={toggle}>Attention!</ModalHeader>
                      <ModalBody>
                        Etes vous sure de supprimer l'utilisateur {user.user_lastname} {user.user_firstname}
                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={deleteUser}>Ok</Button>
                        <Button color='dark' onClick={toggle}>Annuler</Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                  <Col xl="4">
                    <Table>
                      <tbody>
                        <tr>
                          <th>Réseau</th>
                          <td>{user.user_network}</td>
                        </tr>
                        <tr>
                          <th>Supérviseurs</th>
                          <td>{user.user_supervisors.map((s) => {
                            return (
                              
                              <Link key={user.user_supervisors.indexOf(s)} to={`${process.env.PUBLIC_URL}/users/user:${s.user_id}`}> {s.user_lastname} {s.user_firstname} ,
                              </Link> 
                             
                            )
                          })}</td>
                        </tr>
                        <tr>
                          <th>Tournée libre</th>
                          <td>{user.user_free_tour ? "Oui" : "Non"}</td>
                        </tr>
                        <tr>
                          <th>GPS</th>
                          <td>{user.user_traking ? "Oui" : "Non"}</td>
                        </tr>
                        <tr>
                          <th>Modifier Portefeuille</th>
                          <td>{user.user_edit_pf ? "Oui" : "Non"}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col xl="4">
                    <Table>
                      <thead>
                        <tr>
                          <th>Zone</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{user.user_wilaya.join(" , ")}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>

                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="12" className='mb-4'>
            <Card>
              <Modal className='modal-xl' isOpen={addModal} toggle={addToggle} >
                <ModalHeader toggle={addToggle}>Selectioner un contact</ModalHeader>
                <ModalBody>
                  <Row className='o-auto'>
                    <Col sm={9} className='o-auto'>
                      <div className="add-contact-table">
                        <Table>
                          <thead>
                            <tr>
                              <td>Nom / Prénom</td>
                              <td>Specialité</td>
                              <td>Secteur</td>
                              <td>Potentiel</td>
                              <td>Wilaya</td>
                              <td>Commune</td>
                              <td>Ajouter</td>
                            </tr>
                          </thead>
                          <tbody>
                            {contacts.map((contact) => {
                              return (
                                <tr key={contacts.indexOf(contact)}>
                                  {/* <td><Input className='form-control input-group-sm' type='checkbox' /></td> */}
                                  <td><input type='checkbox' onChange={() => addContact(contact.contact_id)} /></td>
                                  <td>{contact.contact_lastname + " " + contact.contact_firstname}</td>
                                  <td>{contact.contact_speciality}</td>
                                  <td>{contact.contact_sector}</td>
                                  <td>{contact.contact_potential[jwt.decode(token).network] ?? contact.contact_potential["Visite Pharma"]}</td>
                                  <td>{contact.contact_wilaya}</td>
                                  <td>{contact.contact_city}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col sm={3} className='o-auto'>
                      <h4>Filtre</h4>
                      <div>
                        <Form onSubmit={(e) => e.preventDefault()}>
                          <FormGroup>
                            <div className="input-wrapper">
                              <input
                                name='nomContact'
                                value={filterParams.nomContact}
                                placeholder='Nom Contact'
                                onChange={(e) => { setFilterParams({ ...filterParams, nomContact: e.target.value }) }}
                              />
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="specialty"><span>Spécialité: {' '}</span></label>
                              <select name="specialty" value={filterParams.speciality} onChange={(e) => { setFilterParams({ ...filterParams, speciality: e.target.value }) }}>
                                {speciality.map((s) => {
                                  return (
                                    <option key={speciality.indexOf(s)} value={s}>{s}</option>
                                  )
                                })}
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="wilayas">Wilaya: </label>
                              <select name="wilayas" value={filterParams.wilaya} onChange={(e) => { setFilterParams({ ...filterParams, wilaya: e.target.value, wilayaID: wilayas.findIndex((wilaya) => wilaya.nom === e.target.value) }) }}>
                                {wilayas.map((w) => {
                                  return (
                                    <option key={wilayas.indexOf(w)} value={w.nom}>{w.nom}</option>
                                  )
                                })}
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="communes">Communes: </label>
                              <select name="communes" disabled={filterParams.wilaya === "Tous" ? true : false} onChange={(e) => { setFilterParams({ ...filterParams, commune: e.target.value }) }}>
                                {filterFunction().map(c => {
                                  return (
                                    <option key={communes.indexOf(c)} value={c.nom}>{c.nom}</option>
                                  )
                                })}
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <input
                                name='adress'
                                value={filterParams.adress}
                                placeholder='Adress'
                                onChange={(e) => { setFilterParams({ ...filterParams, adress: e.target.value }) }}
                              />
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="secteur">Sécteur: </label>
                              <select name="secteur" value={filterParams.secteur} onChange={(e) => { setFilterParams({ ...filterParams, secteur: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="Privé">Privé</option>
                                <option value="Public">Public</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="potentiel">Potentiel: </label>
                              <select name="potentiel" value={filterParams.potentiel} onChange={(e) => { setFilterParams({ ...filterParams, potentiel: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="A+">A+</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="patients">Nombre patients: </label>
                              <select name="patients" value={filterParams.patients} onChange={(e) => { setFilterParams({ ...filterParams, patients: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="< 10">{"< 10"}</option>
                                <option value="10 - 20">{"10 - 20"}</option>
                                <option value="> 20">{"> 20"}</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="kol">KOL: </label>
                              <select name="kol" value={filterParams.KOL} onChange={(e) => { setFilterParams({ ...filterParams, KOL: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="true">Oui</option>
                                <option value="false">Non</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="delai">Délai d'attente: </label>
                              <select name="delai" value={filterParams.delaiAttente} onChange={(e) => { setFilterParams({ ...filterParams, delaiAttente: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="Trés long">Trés long</option>
                                <option value="Long">Long</option>
                                <option value="Standard">Standard</option>
                                <option value="Rapide">Rapide</option>
                                <option value="Immédiat">Immédiat</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="SONCAS">SONCAS: </label>
                              <select name="SONCAS" value={filterParams.SONCAS} onChange={(e) => { setFilterParams({ ...filterParams, SONCAS: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="Sympathique">Sympathique</option>
                                <option value="Confort">Confort</option>
                                <option value="Argent">Argent</option>
                                <option value="Nouveauté">Nouveauté</option>
                                <option value="Orgueil">Orgueil</option>
                                <option value="Scientifique">Scientifique</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="interet">Inéret pour notre produit / marque: </label>
                              <select name="interet" value={filterParams.interetProduit} onChange={(e) => { setFilterParams({ ...filterParams, interetProduit: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="Neutre">Neutre</option>
                                <option value="Favorable">Favorable</option>
                                <option value="Trés favorable">Trés favorable</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="generique">Princeps contre génerique: </label>
                              <select name="generique" value={filterParams.generique} onChange={(e) => { setFilterParams({ ...filterParams, generique: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="Génerique">Génerique</option>
                                <option value="Princeps">Princeps</option>
                                <option value="Notre génerique">Notre génerique</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="prescription">Possibilité de prescription: </label>
                              <select name="prescription" value={filterParams.prescription} onChange={(e) => { setFilterParams({ ...filterParams, prescription: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="Nulle">Nulle</option>
                                <option value="Faible">Faible</option>
                                <option value="Moyenne">Moyenne</option>
                                <option value="Forte">Forte</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="informatique">Informatique: </label>
                              <select name="informatique" value={filterParams.informatique} onChange={(e) => { setFilterParams({ ...filterParams, informatique: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="J'aime pas">J'aime pas</option>
                                <option value="Non">Non</option>
                                <option value="J'ai besion d'aide">J'ai besion d'aide</option>
                                <option value="Oui">Oui</option>
                              </select>
                            </div>
                            <div className='search-btn my-3'>
                              <Button type='submit' onClick={() => {
                                if (page === 1) {
                                  fetchContacts()
                                } else {
                                  setPage(1);
                                }
                              }}
                              >Rechercher</Button>
                            </div>
                            <div className='search-btn my-3'>
                              <Button onClick={() => setFilterParams({
                                nomContact: "",
                                speciality: "Tous",
                                wilaya: "Tous",
                                wilayaID: "0",
                                commune: "Tous",
                                adress: "",
                                secteur: "Tous",
                                potentiel: "Tous",
                                patients: "Tous",
                                KOL: "Tous",
                                delaiAttente: "Tous",
                                SONCAS: "Tous",
                                interetProduit: "Tous",
                                generique: "Tous",
                                prescription: "Tous",
                                informatique: "Tous"
                              })}>Réinitialiser</Button>
                            </div>
                          </FormGroup>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                  <Row className='my-1'>
                    <Col>
                      <Pagination aria-label="Page navigation example" className="pagination justify-content-center pagination-primary" >
                        <PaginationItem><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : 1)}><span aria-hidden="true">«</span><span className="sr-only">Previous</span></PaginationLink></PaginationItem>
                        {(totalpages() === 1) &&
                          <PaginationItem active={true}><PaginationLink >{page}</PaginationLink></PaginationItem>
                        }
                        {(totalpages() === 2) &&
                          <>
                            <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 1 ? page : page - 1)}>{page === 1 ? page : page - 1}</PaginationLink></PaginationItem>
                            <PaginationItem active={page === 2 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 2 ? page : page + 1)}>{page === 2 ? page : page + 1}</PaginationLink></PaginationItem>
                          </>
                        }
                        {(totalpages() > 2) &&
                          <>
                            <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : page)}>{page === totalpages() ? page - 2 : page > 1 ? page - 1 : page}</PaginationLink></PaginationItem>
                            <PaginationItem active={page != 1 && page != totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page - 1 : page > 1 ? page : page + 1)}>{page === totalpages() ? page - 1 : page > 1 ? page : page + 1}</PaginationLink></PaginationItem>
                            <PaginationItem active={page === totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page : page > 1 ? page + 1 : page + 2)}>{page === totalpages() ? page : page > 1 ? page + 1 : page + 2}</PaginationLink></PaginationItem>
                          </>
                        }
                        <PaginationItem><PaginationLink onClick={() => paginationNumber((totalpages() <= page) ? page : page + 1)}><span aria-hidden="true">»</span><span className="sr-only">Next</span></PaginationLink></PaginationItem>
                      </Pagination>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color='success' onClick={asignContact}>Ok</Button>
                  <Button color='dark' onClick={addToggle}>Annuler</Button>
                </ModalFooter>
              </Modal>
              <CardHeader className='d-flex justify-content-between'>
                <h4 className="card-title mb-0">Portefeuille Contacts</h4>
                <div>
                <button className='btn btn-success mr-2' onClick={() => { saveAsExcel()}}> Exporter</button>
                <button className='btn btn-primary' onClick={() => { addToggle(); fetchContacts(); }}> Ajouter</button>
                </div>
              </CardHeader>
              {user.user_favorites.length === 0 ?
                <CardBody>
                  <h5>Aucun contact à afficher</h5>
                </CardBody>
                :
                <CardBody style={{ overflow: "auto" }}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Nom / Prénom</th>
                        <th>Spécialité</th>
                        <th>Secteur</th>
                        <th>Potentiel</th>
                        <th>Adresse</th>
                        <th>Wilaya</th>
                        <th>Commune</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.user_favorites.map((contact) => {
                        return (
                          <tr key={user.user_favorites.indexOf(contact)}>
                            <td>{contact.contact_lastname} {contact.contact_firstname}</td>
                            <td>{contact.contact_speciality}</td>
                            <td>{contact.contact_sector}</td>
                            <td>{contact.contact_potential[user.user_network] ?? contact.contact_potential["Visite Pharma"]}</td>
                            <td>{contact.contact_address}</td>
                            <td>{contact.contact_wilaya}</td>
                            <td>{contact.contact_city}</td>
                            <td style={{ textAlign: "center" }}>
                              <Link to={`${process.env.PUBLIC_URL}/contacts/contact:${contact.contact_id}`}>
                                <i className="fa fa-table" id="TooltipExample"></i>
                              </Link>
                              <i className="fa fa-trash text-danger ml-2" id="TooltipExample" style={{ cursor: "pointer" }} onClick={() => confirmDelete(contact.contact_id)}></i>
                            </td>
                          </tr>
                        )
                      })}

                    </tbody>
                  </Table>
                </CardBody>
              }
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
