import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Card, Button, CardBody, Nav, NavItem } from 'reactstrap'
import { toast } from 'react-toastify';
import jwt from 'jsonwebtoken';

import "react-datepicker/dist/react-datepicker.css";

import "./ParametersObjectives.css";

export default function Parameters() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const role = token.length > 0 ? jwt.decode(token).type : "";
  const [activeTab, setActiveTab] = useState('Antidouleur');
  const [loading, setloading] = useState(false);
  const [oant, setoant] = useState({ objectif_doctors: "", objectif_pharmacy: "" });
  const [ogyn, setogyn] = useState({ objectif_doctors: "", objectif_pharmacy: "" });
  const [oneu, setoneu] = useState({ objectif_doctors: "", objectif_pharmacy: "" });
  const [ovis, setovis] = useState({ objectif_doctors: "", objectif_pharmacy: "" });
  const [odia, setodia] = useState({ objectif_doctors: "", objectif_pharmacy: "" });


  const [rant, setrant] = useState({ objectif_doctors: "", objectif_pharmacy: "" });
  const [rgyn, setrgyn] = useState({ objectif_doctors: "", objectif_pharmacy: "" });
  const [rneu, setrneu] = useState({ objectif_doctors: "", objectif_pharmacy: "" });
  const [rvis, setrvis] = useState({ objectif_doctors: "", objectif_pharmacy: "" });
  const [rdia, setrdia] = useState({ objectif_doctors: "", objectif_pharmacy: "" });


  const switchparams = function (key) {
    switch (key) {
      case "Antidouleur":
        return (
          <>
            <Col md="4">
              <Card>
                <CardBody>
                  <label htmlFor="ant-doc">Objectif Médcins</label>
                  <input
                    name='ant-doc'
                    type="number"
                    value={oant.objectif_doctors}
                    onChange={e => setoant({ ...oant, objectif_doctors: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-pha">Objectif Pharmaciens</label>
                  <input
                    name='ant-pha'
                    type="number"
                    min={1} max={100}
                    value={oant.objectif_pharmacy}
                    onChange={e => setoant({ ...oant, objectif_pharmacy: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-total">Total</label>
                  <h4>{oant.objectif_doctors + oant.objectif_pharmacy}</h4>

                  <Button color='dark' onClick={() => modifyObjectives(oant)}>Modifier</Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <label htmlFor="ant-roa+">Ratio A+</label>
                  <input
                    name='ant-roa+'
                    type="number"
                    value={rant.setting_ro_a_plus}
                    onChange={e => setrant({ ...rant, setting_ro_a_plus: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-roa">Ratio A</label>
                  <input
                    name='ant-roa'
                    type="number"
                    min={1} max={100}
                    value={rant.setting_ro_a}
                    onChange={e => setrant({ ...rant, setting_ro_a: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-rob">Ratio B</label>
                  <input
                    name='ant-rob'
                    type="number"
                    min={1} max={100}
                    value={rant.setting_ro_b}
                    onChange={e => setrant({ ...rant, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-roc">Ratio C</label>
                  <input
                    name='ant-roc'
                    type="number"
                    min={1} max={100}
                    value={rant.setting_ro_c}
                    onChange={e => setrant({ ...rant, setting_ro_c: parseInt(e.target.value) })}
                  />
                  <Button className='mt-2' color='dark' onClick={() => modifySettings(rant)}>Modifier</Button>
                </CardBody>
              </Card>
            </Col>
          </>
        )

      case "Gynéco":
        return (
          <>
            <Col md="4">
              <Card>
                <CardBody>
                  <label htmlFor="ant-doc">Objectif Médcins</label>
                  <input
                    name='ant-doc'
                    type="number"
                    value={ogyn.objectif_doctors}
                    onChange={e => setogyn({ ...ogyn, objectif_doctors: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-pha">Objectif Pharmaciens</label>
                  <input
                    name='ant-pha'
                    type="number"
                    min={1} max={100}
                    value={ogyn.objectif_pharmacy}
                    onChange={e => setogyn({ ...ogyn, objectif_pharmacy: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-total">Total</label>
                  <h4>{ogyn.objectif_doctors + ogyn.objectif_pharmacy}</h4>

                  <Button color='dark' onClick={() => modifyObjectives(ogyn)}>Modifier</Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <label htmlFor="ant-roa+">Ratio A+</label>
                  <input
                    name='ant-roa+'
                    type="number"
                    value={rgyn.setting_ro_a_plus}
                    onChange={e => setrgyn({ ...rgyn, setting_ro_a_plus: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-roa">Ratio A</label>
                  <input
                    name='ant-roa'
                    type="number"
                    min={1} max={100}
                    value={rgyn.setting_ro_a}
                    onChange={e => setrgyn({ ...rgyn, setting_ro_a: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-rob">Ratio B</label>
                  <input
                    name='ant-rob'
                    type="number"
                    min={1} max={100}
                    value={rgyn.setting_ro_b}
                    onChange={e => setrgyn({ ...rgyn, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-roc">Ratio C</label>
                  <input
                    name='ant-roc'
                    type="number"
                    min={1} max={100}
                    value={rgyn.setting_ro_c}
                    onChange={e => setrgyn({ ...rgyn, setting_ro_c: parseInt(e.target.value) })}
                  />
                  <Button color='dark' className='mt-2' onClick={() => modifySettings(rgyn)}>Modifier</Button>
                </CardBody>
              </Card>
            </Col>
          </>
        )
      case "Neuropsychiatrie /HGE":
        return (
          <>
            <Col md="4">
              <Card>
                <CardBody>
                  <label htmlFor="ant-doc">Objectif Médcins</label>
                  <input
                    name='ant-doc'
                    type="number"
                    value={oneu.objectif_doctors}
                    onChange={e => setoneu({ ...oneu, objectif_doctors: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-pha">Objectif Pharmaciens</label>
                  <input
                    name='ant-pha'
                    type="number"
                    min={1} max={100}
                    value={oneu.objectif_pharmacy}
                    onChange={e => setoneu({ ...oneu, objectif_pharmacy: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-total">Total</label>
                  <h4>{oneu.objectif_doctors + oneu.objectif_pharmacy}</h4>

                  <Button color='dark' onClick={() => modifyObjectives(oneu)}>Modifier</Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <label htmlFor="ant-roa+">Ratio A+</label>
                  <input
                    name='ant-roa+'
                    type="number"
                    value={rneu.setting_ro_a_plus}
                    onChange={e => setrneu({ ...rneu, setting_ro_a_plus: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-roa">Ratio A</label>
                  <input
                    name='ant-roa'
                    type="number"
                    min={1} max={100}
                    value={rneu.setting_ro_a}
                    onChange={e => setrneu({ ...rneu, setting_ro_a: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-rob">Ratio B</label>
                  <input
                    name='ant-rob'
                    type="number"
                    min={1} max={100}
                    value={rneu.setting_ro_b}
                    onChange={e => setrneu({ ...rneu, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-roc">Ratio C</label>
                  <input
                    name='ant-roc'
                    type="number"
                    min={1} max={100}
                    value={rneu.setting_ro_c}
                    onChange={e => setrneu({ ...rneu, setting_ro_c: parseInt(e.target.value) })}
                  />
                  <Button color='dark' className='mt-2' onClick={() => modifySettings(rneu)}>Modifier</Button>
                </CardBody>
              </Card>
            </Col>
          </>
        )
      case "Visite Pharma":
        return (
          <>
            <Col md="4">
              <Card>
                <CardBody>
                  <label htmlFor="ant-doc">Objectif Médcins</label>
                  <input
                    name='ant-doc'
                    type="number"
                    value={ovis.objectif_doctors}
                    onChange={e => setovis({ ...ovis, objectif_doctors: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-pha">Objectif Pharmaciens</label>
                  <input
                    name='ant-pha'
                    type="number"
                    min={1} max={100}
                    value={ovis.objectif_pharmacy}
                    onChange={e => setovis({ ...ovis, objectif_pharmacy: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-total">Total</label>
                  <h4>{ovis.objectif_doctors + ovis.objectif_pharmacy}</h4>

                  <Button color='dark' onClick={() => modifyObjectives(ovis)}>Modifier</Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <label htmlFor="ant-roa+">Ratio A+</label>
                  <input
                    name='ant-roa+'
                    type="number"
                    value={rvis.setting_ro_a_plus}
                    onChange={e => setrvis({ ...rvis, setting_ro_a_plus: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-roa">Ratio A</label>
                  <input
                    name='ant-roa'
                    type="number"
                    min={1} max={100}
                    value={rvis.setting_ro_a}
                    onChange={e => setrvis({ ...rvis, setting_ro_a: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-rob">Ratio B</label>
                  <input
                    name='ant-rob'
                    type="number"
                    min={1} max={100}
                    value={rvis.setting_ro_b}
                    onChange={e => setrvis({ ...rvis, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-roc">Ratio C</label>
                  <input
                    name='ant-roc'
                    type="number"
                    min={1} max={100}
                    value={rvis.setting_ro_c}
                    onChange={e => setrvis({ ...rvis, setting_ro_c: parseInt(e.target.value) })}
                  />
                  <Button color='dark' className='mt-2' onClick={() => modifySettings(rvis)}>Modifier</Button>
                </CardBody>
              </Card>
            </Col>
          </>
        )
      case "Diabète":
        return (
          <>
            <Col md="4">
              <Card>
                <CardBody>
                  <label htmlFor="ant-doc">Objectif Médcins</label>
                  <input
                    name='ant-doc'
                    type="number"
                    value={odia.objectif_doctors}
                    onChange={e => setodia({ ...odia, objectif_doctors: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-pha">Objectif Pharmaciens</label>
                  <input
                    name='ant-pha'
                    type="number"
                    min={1} max={100}
                    value={odia.objectif_pharmacy}
                    onChange={e => setodia({ ...odia, objectif_pharmacy: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-total">Total</label>
                  <h4>{odia.objectif_doctors + odia.objectif_pharmacy}</h4>

                  <Button color='dark' onClick={() => modifyObjectives(odia)}>Modifier</Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <label htmlFor="ant-roa+">Ratio A+</label>
                  <input
                    name='ant-roa+'
                    type="number"
                    value={rdia.setting_ro_a_plus}
                    onChange={e => setrdia({ ...rdia, setting_ro_a_plus: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-roa">Ratio A</label>
                  <input
                    name='ant-roa'
                    type="number"
                    min={1} max={100}
                    value={rdia.setting_ro_a}
                    onChange={e => setrdia({ ...rdia, setting_ro_a: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-rob">Ratio B</label>
                  <input
                    name='ant-rob'
                    type="number"
                    min={1} max={100}
                    value={rdia.setting_ro_b}
                    onChange={e => setrdia({ ...rdia, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-roc">Ratio C</label>
                  <input
                    name='ant-roc'
                    type="number"
                    min={1} max={100}
                    value={rdia.setting_ro_c}
                    onChange={e => setrdia({ ...rdia, setting_ro_c: parseInt(e.target.value) })}
                  />
                  <Button color='dark' className='mt-2' onClick={() => modifySettings(rdia)}>Modifier</Button>
                </CardBody>
              </Card>
            </Col>
          </>
        )
      default:
        return (<></>);
    }
  }

  const modifyObjectives = async (obj) => {
    setloading(true);
    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        objectif_start_date: obj.objectif_start_date,
        obejctif_end_date: obj.obejctif_end_date,
        objectif_name: obj.objectif_name,
        objectif_id: obj.objectif_id,
        objectif_doctors: obj.objectif_doctors,
        objectif_pharmacy: obj.objectif_pharmacy
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/objectifs/update", request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success("Modifier avec success");
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }

  const getObjectives = async () => {
    setloading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      }
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/objectifs/all", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setoant(data.filter(obj => obj.objectif_name === "Antidouleur")[0]);
        setogyn(data.filter(obj => obj.objectif_name === "Gynéco")[0]);
        setoneu(data.filter(obj => obj.objectif_name === "Neuropsychiatrie /HGE")[0]);
        setovis(data.filter(obj => obj.objectif_name === "Visite Pharma")[0]);
        setodia(data.filter(obj => obj.objectif_name === "Diabète")[0]);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }

  useEffect(() => {
    getObjectives();
  }, []);

  const modifySettings = async (obj) => {
    setloading(true);
    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(obj)
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/settings/update", request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success("Modifier avec success");
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }

  const getSettings = async () => {
    setloading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      }
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/settings/all", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setrant(data.filter(obj => obj.setting_title === "Antidouleur")[0]);
        setrgyn(data.filter(obj => obj.setting_title === "Gynéco")[0]);
        setrneu(data.filter(obj => obj.setting_title === "Neuropsychiatrie /HGE")[0]);
        setrvis(data.filter(obj => obj.setting_title === "Visite Pharma")[0]);
        setrdia(data.filter(obj => obj.setting_title === "Diabète")[0]);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }

  useEffect(() => {
    getSettings();
  }, []);


  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Paramétres" title="Paramétres" />
      {role === "admin" && <Container fluid={true}>
        <div className="email-wrap bookmark-wrap">
          <Row>
            <Col md={4} className="box-col-6">
              <div className="email-left-aside">
                <Card>
                  <CardBody>
                    <div className="email-app-sidebar left-bookmark">
                      <Nav className="main-menu" role="tablist">
                        <NavItem>
                          <span className="main-title">Equipe : {activeTab}</span>
                        </NavItem>

                        <NavItem style={{ cursor: "pointer" }}>
                          <a className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('Antidouleur')}>
                            <span className="title">Antidouleur</span>
                          </a>
                        </NavItem>
                        <NavItem style={{ cursor: "pointer" }}>
                          <a className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('Gynéco')}>
                            <span className="title">Gynéco</span>
                          </a>
                        </NavItem>
                        <NavItem style={{ cursor: "pointer" }}>
                          <a className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('Neuropsychiatrie /HGE')}>
                            <span className="title">Neuropsychiatrie /HGE</span>
                          </a>
                        </NavItem>
                        <NavItem style={{ cursor: "pointer" }}>
                          <a className={activeTab === '4' ? 'active' : ''} onClick={() => setActiveTab('Diabète')}>
                            <span className="title">Diabète</span>
                          </a>
                        </NavItem>
                        <NavItem style={{ cursor: "pointer" }}>
                          <a className={activeTab === '5' ? 'active' : ''} onClick={() => setActiveTab('Visite Pharma')}>
                            <span className="title">Visite pharma</span>
                          </a>
                        </NavItem>
                      </Nav>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
            {switchparams(activeTab)}
          </Row>
        </div>
        <Row>
          {/* <Col md="12">
            <Card>
              <CardBody>
                <div>
                  <p>Antidouleur</p>
                  <label htmlFor="ant-doc">Objectif Médcins</label>
                  <input
                    name='ant-doc'
                    type="number"
                    value={oant.objectif_doctors}
                    onChange={e => setoant({ ...oant, objectif_doctors: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-pha">Objectif Pharmaciens</label>
                  <input
                    name='ant-pha'
                    type="number"
                    min={1} max={100}
                    value={oant.objectif_pharmacy}
                    onChange={e => setoant({ ...oant, objectif_pharmacy: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-total">Total</label>
                  <input
                    name='ant-total'
                    type="number"
                    min={1} max={100}
                    value={oant.objectif_doctors + oant.objectif_pharmacy}
                    readOnly
                  />

                  <Button onClick={() => modifyObjectives(oant)}>Modifier</Button>
                </div>
                <div>
                  <p>Gynéco</p>
                  <label htmlFor="gyn-doc">Objectif Médcins</label>
                  <input
                    name='gyn-doc'
                    type="number"
                    value={gyn.objectif_doctors}
                    onChange={e => setgyn({ ...gyn, objectif_doctors: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="gyn-pha">Objectif Pharmaciens</label>
                  <input
                    name='gyn-pha'
                    type="number"
                    min={1} max={100}
                    value={gyn.objectif_pharmacy}
                    onChange={e => setgyn({ ...gyn, objectif_pharmacy: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="gyn-total">Total</label>
                  <input
                    name='gyn-total'
                    type="number"
                    min={1} max={100}
                    value={gyn.objectif_doctors + gyn.objectif_pharmacy}
                    readOnly
                  />
                  <Button onClick={() => modifyObjectives(gyn)}>Modifier</Button>
                </div>
                <div>
                  <p>Neuropsychiatrie /HGE</p>
                  <label htmlFor="neu-doc">Objectif Médcins</label>
                  <input
                    name='neu-doc'
                    type="number"
                    value={neu.objectif_doctors}
                    onChange={e => setneu({ ...neu, objectif_doctors: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="neu-pha">Objectif Pharmaciens</label>
                  <input
                    name='neu-pha'
                    type="number"
                    min={1} max={100}
                    value={neu.objectif_pharmacy}
                    onChange={e => setneu({ ...neu, objectif_pharmacy: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="neu-total">Total</label>
                  <input
                    name='neu-total'
                    type="number"
                    min={1} max={100}
                    value={neu.objectif_doctors + neu.objectif_pharmacy}
                    readOnly
                  />
                  <Button onClick={() => modifyObjectives(neu)}>Modifier</Button>
                </div>
                <div>
                  <p>Visite Pharma</p>
                  <label htmlFor="vis-doc">Objectif Médcins</label>
                  <input
                    name='vis-doc'
                    type="number"
                    value={vis.objectif_doctors}
                    onChange={e => setvis({ ...vis, objectif_doctors: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="vis-pha">Objectif Pharmaciens</label>
                  <input
                    name='vis-pha'
                    type="number"
                    min={1} max={100}
                    value={vis.objectif_pharmacy}
                    onChange={e => setvis({ ...vis, objectif_pharmacy: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="vis-total">Total</label>
                  <input
                    name='vis-total'
                    type="number"
                    min={1} max={100}
                    value={vis.objectif_doctors + vis.objectif_pharmacy}
                    readOnly
                  />
                  <Button onClick={() => modifyObjectives(vis)}>Modifier</Button>
                </div>
                <div>
                  <p>Diabète</p>
                  <label htmlFor="dia-doc">Objectif Médcins</label>
                  <input
                    name='dia-doc'
                    type="number"
                    value={dia.objectif_doctors}
                    onChange={e => setdia({ ...dia, objectif_doctors: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="dia-pha">Objectif Pharmaciens</label>
                  <input
                    name='dia-pha'
                    type="number"
                    min={1} max={100}
                    value={dia.objectif_pharmacy}
                    onChange={e => setdia({ ...dia, objectif_pharmacy: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="dia-total">Total</label>
                  <input
                    name='dia-total'
                    type="number"
                    min={1} max={100}
                    value={dia.objectif_doctors + dia.objectif_pharmacy}
                    readOnly
                  />
                  <Button onClick={() => modifyObjectives(dia)}>Modifier</Button>
                </div>
                <div>
                </div>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </Container>}
    </Fragment>
  )
}

