import React, { Fragment, useState, useEffect } from 'react';
import man from '../../assets/images/dashboard/profile.jpg'
import { LogIn, MessageSquare, Bell, Minimize } from 'react-feather';
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { translate } from 'react-switch-lang';

import user from "../../assets/images/user.png"

import { Notification, CheckAllNotification, ViewAll, MessageBox } from '../../constant'

import jwt from 'jsonwebtoken';

const Rightbar = () => {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const loggedInUser = jwt.decode(token) ?? {};
  const history = useHistory();
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('')
  // const [searchresponsive, setSearchresponsive] = useState(false)
  // const [langdropdown, setLangdropdown] = useState(false)
  const [moonlight, setMoonlight] = useState(false)
  //const [selected, setSelected] = useState("en")
  //const [cartDropdown, setCartDropDown] = useState(false)
  const [notificationDropDown, setNotificationDropDown] = useState(false)
  const [chatDropDown, setChatDropDown] = useState(false)

  // auth0 profile
  const { logout } = useAuth0()
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))

  // const handleSetLanguage = (key) => {
  //   setLanguage(key);
  //   setSelected(key)
  // };

  useEffect(() => {
    setProfile(localStorage.getItem('profileURL') || man);
    setName(localStorage.getItem('Name'))
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true)
    }
  }, []);

  const logoutAndClear = () => {
    // Clear Cookies
    document.cookie.split(";")
      .forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    history.push(`${process.env.PUBLIC_URL}/login`)
  }

  // const  Logout_From_Auth0 = () =>  {
  //   localStorage.removeItem("auth0_profile")
  //   localStorage.setItem("authenticated",false)
  //   history.push(`${process.env.PUBLIC_URL}/login`)
  //   logout()
  // }

  const RedirectToChats = () => {
    history.push(`${process.env.PUBLIC_URL}/app/chat-app`)
  }

  const RedirectToCart = () => {
    history.push(`${process.env.PUBLIC_URL}/app/ecommerce/cart`)
  }

  const UserMenuRedirect = (redirect) => {
    history.push(redirect)
  }

  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  // const SeacrhResposive = (searchresponsive) => {
  //   if (searchresponsive) {
  //     setSearchresponsive(!searchresponsive)
  //     document.querySelector(".search-full").classList.add("open");
  //     document.querySelector(".more_lang").classList.remove("active");
  //   } else {
  //     setSearchresponsive(!searchresponsive)
  //     document.querySelector(".search-full").classList.remove("open");
  //   }
  // }

  // const LanguageSelection = (language) => {
  //   if (language) {
  //     setLangdropdown(!language)
  //   } else {
  //     setLangdropdown(!language)
  //   }
  // }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light)
      document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light)
      document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }

  return (
    <Fragment>
      <header className=' nav-right header col-8 pull-right right-header  d-none d-print-block ' style={{paddingBottom:"0px",marginBottom:"0px"}}>
      <img className="img-fluid for-light " width={"250"} height={"70"} src={require("../../assets/images/logo/logo-salem.png")} alt="" />
      </header>
      <div className="nav-right col-8 pull-right right-header p-0 d-print-none">
        <ul className="nav-menus">
          
          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
          </li>
          
          <li className="maximize"><a className="text-dark" href="#javascript" onClick={goFull}><Minimize /></a></li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className="b-r-10" src={user} alt="profile" />
              <div className="media-body">
                <span>{loggedInUser.lastname + " " + loggedInUser.firstname}</span>
                <p className="mb-0 font-roboto">{(loggedInUser.type == "admin" ? "Administrateur" : loggedInUser.type == "manager" ? "Chéf réseau" : loggedInUser.type == "supervisor" ? "Superviseur" : loggedInUser.type == "agent" ? "Délégué" : "")} <i className="middle fa fa-angle-down"></i></p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
             
              <li onClick={logoutAndClear}><LogIn /><span>Se déconnecter</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>

  );
}
export default translate(Rightbar);