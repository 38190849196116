import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, DropdownItem, UncontrolledDropdown, Media, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Card, CardBody, } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import "./users.css";

export default function Users() {
  const [loading, setLoading] = useState(false);
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const [usersData, setUsersData] = useState([]);
  const history = useHistory();
  const [selectedteam, setTeam] = useState("Tous");
  const role = token.length > 0 ? jwt.decode(token).type : ""
  const [searchKeyword, setSearchKeyword] = useState("");
  // fetching users
  const fetchData = async (team, searchKeyword) => {
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ user_network: team, user_name: searchKeyword/*user_name: searchKeyword }*/ })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/users/all", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setUsersData(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchData(selectedteam, searchKeyword);
    window.scrollTo(0, 0);
  }, [])
  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Utilisateurs" title="Utilisateurs" />
      {role === "admin" ?
        <Container fluid={true}  className='pb-4 mb-4'>
          
          <Row>
            <Col md={"6"}  className='f-right feature-products'>
              <Form onSubmit={(e) => e.preventDefault()}>
                <FormGroup>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Nom utilisateur"
                    defaultValue=""
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" ? fetchData(selectedteam, searchKeyword) : 1}
                  />
                  <i className="fa fa-search"></i>
                </FormGroup>
              </Form>
            </Col>
            <Col md={"2"} >
                <UncontrolledDropdown  >
                  <DropdownToggle color='dark' caret className="btn-block">
                    {selectedteam}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem  onClick={() => setTeam("Tous")}>Tous</DropdownItem>
                    <DropdownItem  onClick={() => setTeam("Antidouleur")}>Antidouleur</DropdownItem>
                    <DropdownItem  onClick={() => setTeam("Gynéco")}>Gynéco</DropdownItem>
                    <DropdownItem  onClick={() => setTeam("Neuropsychiatrie /HGE")}>Neuropsychiatrie /HGE</DropdownItem>
                    <DropdownItem  onClick={() => setTeam("Visite Pharma")}>Visite Pharma</DropdownItem>
                    <DropdownItem  onClick={() => setTeam("Diabète")}>Diabète</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
            </Col>
            <Col md={2}>
                <Button type='submit' onClick={() => fetchData(selectedteam, searchKeyword)} color='primary' className="btn-block d-inline-block">Rechercher</Button>
            </Col>
            <Col md={2}>
                <Button className='d-inline-block btn-block' color='success' onClick={() => history.push(`${process.env.PUBLIC_URL}/users/add`)}> Ajouter</Button>
            </Col>
          </Row>
        </Container>
        :
        <Container fluid={true}  className='pb-4 mb-4'>
          <Row>
            <Col md={"7"} lg="8" className='f-right feature-products'>
              <Form onSubmit={(e) => e.preventDefault()}>
                <FormGroup>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Nom utilisateur"
                    defaultValue=""
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" ? fetchData(selectedteam, searchKeyword) : 1}
                  />
                  <i className="fa fa-search"></i>
                </FormGroup>
              </Form>
            </Col>
            <Col md={"5"} lg="4">
              <div className="dropdown f-right">
                <Button type='submit' onClick={() => fetchData(selectedteam, searchKeyword)} color='success' className="b-r-0 m-1 ourBtn">Rechercher</Button>
              </div>
            </Col>
          </Row>
        </Container>
      }
      <Container fluid={true} className='pb-4 mb-4'>
        <Row className='mb-4'>
          {
            usersData.length === 0 ?
              <Col>
                <Card className='text-align-center'>
                  <CardBody style={{ textAlign: "center" }}>
                    <h4>Aucun utilisateur trouvé</h4>
                  </CardBody>
                </Card>
              </Col>
              :
              usersData.map((user) => {
                return (
                  <Col md="6" lg="4" xl="3" className="box-col-6" key={usersData.indexOf(user)}>
                    <Card className="custom-card">
                      <div className="card-profile mb-3">
                        <Media body className="rounded-circle user-img" alt="" src={require(`../../assets/images/user.png`)} />
                      </div>
                      <div className="text-center profile-details o-hidden">
                        <h5>{user.user_lastname} {user.user_firstname}</h5>
                        <p className='mb-1'>{user.user_email}</p>
                        <p className='mb-1'>{user.user_phone}</p>
                        <h6 className='mt-3 mb-1' style={{ color: "black" }}>{user.user_type == "admin" ? "Administrateur" : user.user_type == "manager" ? "Chéf réseau" : user.user_type == "supervisor" ? "Superviseur" : user.user_type == "agent" ? "Délégué" : ""}</h6>
                        <h6 style={{ color: "DarkBlue" }}>{user.user_network}</h6>
                      </div>
                      <div className="row justify-content-center">
                        <Button className='mt-1 mb-2' color='dark' onClick={() => history.push({
                          pathname: `${process.env.PUBLIC_URL}/users/user:${user.user_id}`,
                          state: {
                            user: user,
                            supervisor_id: user.user_id
                          }
                        })}>
                          Voir détails
                        </Button>
                      </div>
                    </Card>
                  </Col>
                );
              })
          }
        </Row>
      </Container>
    </Fragment>
  )
}
