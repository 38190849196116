import React, { useState, useEffect } from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, TabContent, TabPane } from 'reactstrap'
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'

const Logins = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false)

  const [value, setValue] = useState(
    localStorage.getItem('profileURL' || man)
  );
  const [name, setName] = useState(
    localStorage.getItem('Name')
  );

  useEffect(() => {

    localStorage.setItem('profileURL', value);
    localStorage.setItem('Name', name);
  }, [value, name]);

  const loginWithJwt = async (email, password) => {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
      },
      body: JSON.stringify({ username: email, password: password })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/loginmanagers", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        document.cookie = ("jwt=" + data.accesstoken);
        window.location.href = `${process.env.PUBLIC_URL}/`
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <span className="logo">
                  <img className="img-fluid for-light" width="100" src={require("../assets/images/logo/login.png")} alt="" />
                  <img className="img-fluid for-dark" src={require("../assets/images/logo/logo_dark.png")} alt="" />
                </span>
              </div>
              <div className="login-main login-tab">
                <TabContent activeTab={"jwt"} className="content-login">
                  <TabPane className="fade show" tabId={/*selected === "firebase" ? "firebase" :*/ "jwt"}>
                    <Form className="theme-form" onSubmit={(e) => e.preventDefault()}>
                      <h4>Se connecter</h4>
                      <p>{"Entrer votre email / tél et mot de passe"}</p>
                      <FormGroup>
                        <Label className="col-form-label">Email / Tél</Label>
                        <Input className="form-control" type="text" required="" onChange={e => setEmail(e.target.value)} placeholder='Entrer votre Email / Tél ici' />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">Mot de passe</Label>
                        <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={e => setPassword(e.target.value)} placeholder='Entrer votre Mot de passe ici' required="" />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                      </FormGroup>
                      <div className="form-group mt-5">
                        <Button color="primary" type='submit' className="btn-block" onClick={() => loginWithJwt(email, password)}>Se connecter</Button>
                      </div>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(Logins);