import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, Nav, NavItem, FormGroup, Form, Table, Card, CardHeader, CardBody, Pagination, PaginationItem, PaginationLink, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import jwt from 'jsonwebtoken';
import speciality from '../contacts/auxiliary/speciality.json';
import wilayas from '../contacts/auxiliary/wilayas.json';
import communes from '../contacts/auxiliary/communes.json';
import * as FileSaver from 'file-saver';
import XlsxPopulate from "xlsx-populate";

const customStyles = {
  content: {
    top: '55%',
    left: '52.5%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "85%",
    height: "80%"
  },
};

Modal.setAppElement('body');

export default function PendingDetailed() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const location = useLocation();
  const history = useHistory();
  const { slug } = useParams();
  const currentGTId = slug;
  const [loading, setLoading] = useState(false);
  const [modalloading, setmodalloading] = useState(false);
  const [data, setdata] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [tour, settour] = useState({ tour_contacts: [] });
  const [contacts, setcontacts] = useState([]);
  const [filterParams, setFilterParams] = useState({
    nomContact: "",
    speciality: "Tous",
    wilaya: "Tous",
    wilayaID: "0",
    commune: "Tous",
    adress: "",
    secteur: "Tous",
    potentiel: "Tous",
    patients: "Tous",
    KOL: "Tous",
    delaiAttente: "Tous",
    SONCAS: "Tous",
    interetProduit: "Tous",
    generique: "Tous",
    prescription: "Tous",
    informatique: "Tous"
  });
  const [page, setPage] = useState(1);
  const [exportList, setExportList] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        global_tour_id: currentGTId
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/tours/getpendingdetail", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setdata(data);
        settour(data[0]);
        let exports = data.map((jour) => {
          return jour.tour_contacts.map((contact) => {
            return (
              [jour.tour_title,
              moment(jour.tour_start_date).format("YYYY-MM-DD"),
              jour.tour_type,
              location.state == null ? "" : location.state.user_lastname + " " + location.state.user_firstname,
              contact.contact_lastname + " " + contact.contact_firstname,
              contact.contact_speciality,
              contact.contact_sector,
              contact.contact_potential[location.state == null ? "" : location.state.user_network] ?? contact.contact_potential["Visite Pharma"],
              contact.contact_wilaya,
              contact.contact_city,
              contact.contact_id]
            )

          })

        })
        setExportList(exports.flat())
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  async function saveAsExcel() {
    let header = ["Journée", "Date", "Type", "Visiteur", "Contact", "Spécialité",
      "Secteur", "Potentiel", "Wilaya", "Commune", "ID Compte"];
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0).name("Plan de Tournée");
      const sheetData = [header, [exportList]]
      const totalColumns = header.length;
      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "339EFF");
      range.style("border", true);

      return workbook.outputAsync().then((res) => {
        FileSaver.saveAs(res, location.state && ("Plan de tournée " + location.state.user_lastname + " " + location.state.user_firstname + " (" + location.state.startDate + " - " + location.state.endDate + ")") + ".xlsx");
      });
    });
  }

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0)
  }, []);

  const validateTours = async () => {
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        global_tour_id: currentGTId,
        tours: data
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/tours/validate", request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success("Tounée valider avec success");
        history.push(`${process.env.PUBLIC_URL}/tournee/valides`);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const deleteTours = async () => {
    setLoading(true);
    const request = {
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        global_tour_id: currentGTId,
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/tours/delete", request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success(await responce.json());
        history.push(`${process.env.PUBLIC_URL}/tournee/enattente`)
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const fetchContacts = async () => {
    setmodalloading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        item_per_page: 20,
        page: page,
        contact_name: filterParams.nomContact,
        contact_wilaya: filterParams.wilaya,
        contact_city: filterParams.commune,
        contact_speciality: filterParams.speciality,
        potentiel: filterParams.potentiel,
        contact_address: filterParams.adress,
        sector: filterParams.secteur,
        nbr_patient: filterParams.patients,
        kol: filterParams.KOL,
        waiting_duration: filterParams.delaiAttente,
        soncas: filterParams.SONCAS,
        products_intrest: filterParams.interetProduit,
        generic_princeps: filterParams.generique,
        prescription_possibility: filterParams.prescription,
        application: filterParams.informatique
      })
    }
    console.log(request.body);
    try {
      var responce = await fetch("https://lsreport.shls.care/api/contacts/all", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setcontacts(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setmodalloading(false);
      toast.error(err.message);
    }
    setmodalloading(false);
  }

  useEffect(() => {
    fetchContacts();
    window.scrollTo(0, 0);
  }, [page])

  const removeContact = (contact) => {
    let newTour = JSON.parse(JSON.stringify(tour));
    newTour.tour_contacts = tour.tour_contacts.filter(obj => obj.contact_id != contact.contact_id);
    settour(() => newTour);
    const index = data.indexOf(data.filter(obj => obj.tour_id == newTour.tour_id)[0]);
    let array = [...data];
    array.splice(index, 1, newTour);
    setdata(array);
  }

  const addContact = (contact) => {
    let newTour = JSON.parse(JSON.stringify(tour));
    newTour.tour_contacts.push(contact);
    settour(() => newTour);
    const index = data.indexOf(data.filter(obj => obj.tour_id == newTour.tour_id)[0]);
    let array = [...data];
    array.splice(index, 1, newTour);
    setdata(array);
  }
  const modifyTourType = (type) => {
    let newTour = JSON.parse(JSON.stringify(tour));
    newTour.tour_type = type;
    settour(() => newTour);
    const index = data.indexOf(data.filter(obj => obj.tour_id == newTour.tour_id)[0]);
    let array = [...data];
    array.splice(index, 1, newTour);
    setdata(array);
  }

  const filterFunction = () => {
    let newArray = communes.filter(item => item.wilaya_id == filterParams.wilayaID);
    newArray.unshift({ id: 0, nom: "Tous" });
    return newArray;
  }

  const paginationNumber = (page) => {
    setPage(page);
  }

  const totalpages = () => {
    if (contacts.length > 0) {
      const total = Math.ceil(contacts[0].full_count / 25);
      return total;
    } else {
      return 0
    }
  }
  // modal code 
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Tournée" title="Tournée Validés" />
      <Container fluid={true} className='pb-4 mb-4'>
        <div className="email-wrap bookmark-wrap">
          <Row>
            <Col xs={3} className="box-col-6">
              <div>
                <Modal
                  isOpen={modalIsOpen}
                  onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Add Contact Modal"
                >
                  <Loader isLoading={modalloading} />
                  <div className="modal-header">
                    <h4 ref={(_subtitle) => (subtitle = _subtitle)}>Selectioner un contact</h4>
                    <Button className='f-right' onClick={closeModal}>X</Button>
                  </div>
                  <Row className='o-auto'>
                    <Col sm={9} className='o-auto'>
                      <div className="add-contact-table">
                        <Table>
                          <thead>
                            <tr>
                              <td>Nom / Prénom</td>
                              <td>Specialité</td>
                              <td>Secteur</td>
                              <td>Potentiel</td>
                              <td>Wilaya</td>
                              <td>Commune</td>
                              <td>Ajouter</td>
                            </tr>
                          </thead>
                          <tbody>
                            {contacts.map((contact) => {
                              return (
                                <tr key={contacts.indexOf(contact)}>
                                  <td>{contact.contact_lastname + " " + contact.contact_firstname}</td>
                                  <td>{contact.contact_speciality}</td>
                                  <td>{contact.contact_sector}</td>
                                  <td>{contact.contact_potential[jwt.decode(token).network] ?? contact.contact_potential["Visite Pharma"]}</td>
                                  <td>{contact.contact_wilaya}</td>
                                  <td>{contact.contact_city}</td>
                                  <td><Button onClick={() => { addContact(contact); closeModal() }}>+</Button></td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col sm={3} className='o-auto'>
                      <h4>Filtre</h4>
                      <div>
                        <Form onSubmit={(e) => e.preventDefault()}>
                          <FormGroup>
                            <div className="input-wrapper">
                              <input
                                name='nomContact'
                                value={filterParams.nomContact}
                                placeholder='Nom Contact'
                                onChange={(e) => { setFilterParams({ ...filterParams, nomContact: e.target.value }) }}
                              />
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="specialty"><span>Spécialité: {' '}</span></label>
                              <select name="specialty" value={filterParams.speciality} onChange={(e) => { setFilterParams({ ...filterParams, speciality: e.target.value }) }}>
                                {speciality.map((s) => {
                                  return (
                                    <option key={speciality.indexOf(s)} value={s}>{s}</option>
                                  )
                                })}
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="wilayas">Wilaya: </label>
                              <select name="wilayas" value={filterParams.wilaya} onChange={(e) => { setFilterParams({ ...filterParams, wilaya: e.target.value, wilayaID: wilayas.findIndex((wilaya) => wilaya.nom === e.target.value) }) }}>
                                {wilayas.map((w) => {
                                  return (
                                    <option key={wilayas.indexOf(w)} value={w.nom}>{w.nom}</option>
                                  )
                                })}
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="communes">Communes: </label>
                              <select name="communes" disabled={filterParams.wilaya === "Tous" ? true : false} onChange={(e) => { setFilterParams({ ...filterParams, commune: e.target.value }) }}>
                                {filterFunction().map(c => {
                                  return (
                                    <option key={communes.indexOf(c)} value={c.nom}>{c.nom}</option>
                                  )
                                })}
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <input
                                name='adress'
                                value={filterParams.adress}
                                placeholder='Adress'
                                onChange={(e) => { setFilterParams({ ...filterParams, adress: e.target.value }) }}
                              />
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="secteur">Sécteur: </label>
                              <select name="secteur" value={filterParams.secteur} onChange={(e) => { setFilterParams({ ...filterParams, secteur: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="Privé">Privé</option>
                                <option value="Public">Public</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="potentiel">Potentiel: </label>
                              <select name="potentiel" value={filterParams.potentiel} onChange={(e) => { setFilterParams({ ...filterParams, potentiel: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="A+">A+</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="patients">Nombre patients: </label>
                              <select name="patients" value={filterParams.patients} onChange={(e) => { setFilterParams({ ...filterParams, patients: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="< 10">{"< 10"}</option>
                                <option value="10 - 20">{"10 - 20"}</option>
                                <option value="> 20">{"> 20"}</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="kol">KOL: </label>
                              <select name="kol" value={filterParams.KOL} onChange={(e) => { setFilterParams({ ...filterParams, KOL: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="true">Oui</option>
                                <option value="false">Non</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="delai">Délai d'attente: </label>
                              <select name="delai" value={filterParams.delaiAttente} onChange={(e) => { setFilterParams({ ...filterParams, delaiAttente: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="Trés long">Trés long</option>
                                <option value="Long">Long</option>
                                <option value="Standard">Standard</option>
                                <option value="Rapide">Rapide</option>
                                <option value="Immédiat">Immédiat</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="SONCAS">SONCAS: </label>
                              <select name="SONCAS" value={filterParams.SONCAS} onChange={(e) => { setFilterParams({ ...filterParams, SONCAS: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="Sympathique">Sympathique</option>
                                <option value="Confort">Confort</option>
                                <option value="Argent">Argent</option>
                                <option value="Nouveauté">Nouveauté</option>
                                <option value="Orgueil">Orgueil</option>
                                <option value="Scientifique">Scientifique</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="interet">Inéret pour notre produit / marque: </label>
                              <select name="interet" value={filterParams.interetProduit} onChange={(e) => { setFilterParams({ ...filterParams, interetProduit: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="Neutre">Neutre</option>
                                <option value="Favorable">Favorable</option>
                                <option value="Trés favorable">Trés favorable</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="generique">Princeps contre génerique: </label>
                              <select name="generique" value={filterParams.generique} onChange={(e) => { setFilterParams({ ...filterParams, generique: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="Génerique">Génerique</option>
                                <option value="Princeps">Princeps</option>
                                <option value="Notre génerique">Notre génerique</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="prescription">Possibilité de prescription: </label>
                              <select name="prescription" value={filterParams.prescription} onChange={(e) => { setFilterParams({ ...filterParams, prescription: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="Nulle">Nulle</option>
                                <option value="Faible">Faible</option>
                                <option value="Moyenne">Moyenne</option>
                                <option value="Forte">Forte</option>
                              </select>
                            </div>
                            <div className="input-wrapper">
                              <label htmlFor="informatique">Informatique: </label>
                              <select name="informatique" value={filterParams.informatique} onChange={(e) => { setFilterParams({ ...filterParams, informatique: e.target.value }) }}>
                                <option value="Tous">Tous</option>
                                <option value="J'aime pas">J'aime pas</option>
                                <option value="Non">Non</option>
                                <option value="J'ai besion d'aide">J'ai besion d'aide</option>
                                <option value="Oui">Oui</option>
                              </select>
                            </div>
                            <div className='search-btn my-3'>
                              <Button type='submit' color='success' onClick={() => {
                                if (page === 1) {
                                  fetchContacts()
                                } else {
                                  setPage(1);
                                }
                              }}
                              >Rechercher</Button>
                            </div>
                            <div className='search-btn my-3'>
                              <Button color='warning' onClick={() => setFilterParams({
                                nomContact: "",
                                speciality: "Tous",
                                wilaya: "Tous",
                                wilayaID: "0",
                                commune: "Tous",
                                adress: "",
                                secteur: "Tous",
                                potentiel: "Tous",
                                patients: "Tous",
                                KOL: "Tous",
                                delaiAttente: "Tous",
                                SONCAS: "Tous",
                                interetProduit: "Tous",
                                generique: "Tous",
                                prescription: "Tous",
                                informatique: "Tous"
                              })}>Réinitialiser</Button>
                            </div>
                          </FormGroup>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                  <Row className='my-1'>
                    <Col>
                      <Pagination aria-label="Page navigation example" className="pagination justify-content-center pagination-primary" >
                        <PaginationItem><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : 1)}><span aria-hidden="true">«</span><span className="sr-only">Previous</span></PaginationLink></PaginationItem>
                        {(totalpages() === 1) &&
                          <PaginationItem active={true}><PaginationLink >{page}</PaginationLink></PaginationItem>
                        }
                        {(totalpages() === 2) &&
                          <>
                            <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 1 ? page : page - 1)}>{page === 1 ? page : page - 1}</PaginationLink></PaginationItem>
                            <PaginationItem active={page === 2 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 2 ? page : page + 1)}>{page === 2 ? page : page + 1}</PaginationLink></PaginationItem>
                          </>
                        }
                        {(totalpages() > 2) &&
                          <>
                            <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : page)}>{page === totalpages() ? page - 2 : page > 1 ? page - 1 : page}</PaginationLink></PaginationItem>
                            <PaginationItem active={page != 1 && page != totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page - 1 : page > 1 ? page : page + 1)}>{page === totalpages() ? page - 1 : page > 1 ? page : page + 1}</PaginationLink></PaginationItem>
                            <PaginationItem active={page === totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page : page > 1 ? page + 1 : page + 2)}>{page === totalpages() ? page : page > 1 ? page + 1 : page + 2}</PaginationLink></PaginationItem>
                          </>
                        }
                        <PaginationItem><PaginationLink onClick={() => paginationNumber((totalpages() <= page) ? page : page + 1)}><span aria-hidden="true">»</span><span className="sr-only">Next</span></PaginationLink></PaginationItem>
                      </Pagination>
                    </Col>
                  </Row>
                </Modal>
              </div>
              <div className="email-left-aside">
                <Card>
                  <CardHeader>
                    <h6>{location.state == null ? "" : location.state.user_lastname} {location.state == null ? "" : location.state.user_firstname}</h6>
                    <p className='mt-0' style={{ color: "DarkBlue" }}>{location.state == null ? "" : location.state.user_network}</p>
                    <p className='mt-3 mb-1'>Du: {location.state == null ? "" : location.state.startDate}</p>
                    <p className='mb-3'>Au: {location.state == null ? "" : location.state.endDate}</p>
                    <Row>
                      <div className="buttons-group row justify-content-center" style={{ textAlign: "center" }}>
                        <Col>
                          <Button className='mt-2' color='info' onClick={() => { validateTours() }}>Valider</Button>
                        </Col>
                        <Col>
                          <Button className='mt-2' color='danger' onClick={() => { deleteTours() }}>Supprimer</Button>
                        </Col>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardHeader>
                    <button className='btn btn-block btn-success' onClick={saveAsExcel}>Exporter <i className='fa fa-file-excel-o ml-2'></i></button>
                  </CardHeader>
                  <CardBody>
                    <div className="email-app-sidebar left-bookmark">
                      <Nav className="main-menu" role="tablist">
                        <NavItem>
                          <span className="main-title">Tournées</span>
                        </NavItem>
                        {data.map((tour) => {
                          return (
                            <NavItem style={{ cursor: "pointer" }} onClick={() => settour(tour)} key={data.indexOf(tour)}>
                              <a className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                <span className="title"> {tour.tour_title}{" (" + moment(tour.tour_start_date).format("YYYY-MM-DD") + ")"}</span>
                              </a>
                            </NavItem>
                          )
                        })
                        }
                      </Nav>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col xs="9">
              <Card>
                <CardBody >
                  <h5>{data.length > 0 && tour.tour_id != "" ? tour.tour_title : ""} : {data.length > 0 && tour.tour_id != "" ? tour.tour_type : ""}
                    <UncontrolledDropdown className='d-inline-block m-3'>
                      <DropdownToggle caret className="b-r-0 ourBtn" color='dark'>
                        {tour.tour_type}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => modifyTourType("Commune")}>Commune</DropdownItem>
                        <DropdownItem onClick={() => modifyTourType("Sous résidence")}>Sous résidence</DropdownItem>
                        <DropdownItem onClick={() => modifyTourType("Hors résidence")}>Hors résidence</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </h5>
                  <h6>{data.length > 0 && tour.tour_id != "" ? moment(tour.tour_start_date).format("DD/MM/YYYY") : ""}</h6>
                  <div className="contact-listheader mt-3" style={{ lineHeight: "35px" }}>
                    <h6 style={{ display: "inline-block" }}>Liste des contacts</h6>
                    <Button className='mb-2 f-right' onClick={() => { openModal(); fetchContacts(); }}>Ajouter</Button>
                  </div>
                  <br />
                  <Row className='o-auto'>
                    <Table>
                      <thead>
                        <tr>
                          <th>Nom / Prénom</th>
                          <th>Spécialité</th>
                          <th>Secteur</th>
                          <th>Potentiel</th>
                          <th>Wilaya</th>
                          <th>Commune</th>
                          <th>Supprimer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 && tour.tour_id != "" ? tour.tour_contacts.map((contact) => {
                          return (
                            <tr key={tour.tour_contacts.indexOf(contact)}>
                              <td>{contact.contact_lastname + " " + contact.contact_firstname}</td>
                              <td>{contact.contact_speciality}</td>
                              <td>{contact.contact_sector}</td>
                              <td>{contact.contact_potential[location.state == null ? "" : location.state.user_network] ?? contact.contact_potential["Visite Pharma"]}</td>
                              <td>{contact.contact_wilaya}</td>
                              <td>{contact.contact_city}</td>
                              <td style={{ textAlign: "center" }}>
                                {contact.report_id != null ?
                                  <Link to={{
                                    pathname: `${process.env.PUBLIC_URL}/visites/rapports/detailed`,
                                    state: {
                                      reportID: contact.report_id,
                                      contact_lastname: contact.contact_lastname,
                                      contact_firstname: contact.contact_firstname,
                                      contact_speciality: contact.contact_speciality,
                                      contact_address: contact.contact_address,
                                      contact_wilaya: contact.contact_wilaya,
                                      contact_prescription_possibility: contact.contact_prescription_possibility,
                                      contact_potentiel: (contact.contact_potential[location.state == null ? "" : location.state.user_network] ?? contact.contact_potential["Visite Pharma"]),
                                      agent_lastname: location.state == null ? "" : location.state.user_lastname,
                                      agent_firstname: location.state == null ? "" : location.state.user_firstname,
                                      agent_network: location.state == null ? "" : location.state.user_network,
                                      contact_id: contact.contact_id,
                                      agent_id: location.state == null ? "" : location.state.user_id
                                    }
                                  }}>
                                    <i className="fa fa-table" id="TooltipExample"></i>
                                  </Link>
                                  :
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => removeContact(contact)}
                                  >
                                    <i className="f-18 fa fa-trash" style={{ color: "red" }}></i>
                                  </a>

                                }
                              </td>
                            </tr>
                          )
                        }) : <></>}
                      </tbody>
                    </Table>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  )
}