import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Table, Card, CardBody, Pagination, PaginationItem, PaginationLink, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import "./Reports.css";
import jwt from 'jsonwebtoken';
import DatePicker from "react-datepicker";

export default function Reports() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const [selectedteam, setTeam] = useState(token.length > 0 ? jwt.decode(token).network : "");
  const role = token.length > 0 ? jwt.decode(token).type : "";
  
  //Fetch API call
  const fetchData = async () => {
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ item_per_page: 25, page: page,  team: selectedteam, start_date: startDate, end_date: endDate })
    }
    try {
      const responce = await fetch("https://lsreport.shls.care/api/reports/focus/all", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setData(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }
  const paginationNumber = (page) => {
    setPage(page);
  }
  const totalpages = () => {
    if (data.length > 0) {
      const total = Math.ceil(data[0].focus.full_count / 25);
      return total;
    } else {
      return 0
    }
  }
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [page])
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [selectedteam])

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Focus" title="Rapports" />
      <Container fluid={true} className='pb-4 mb-4'>
      <Row>
          <Col md="2">
            <label htmlFor="">Du:</label>
            <DatePicker dateFormat={"dd/MM/yyyy"} selected={startDate} maxDate={new Date()} onChange={(date) => setStartDate(date)} />
          </Col>
          <Col md="2">
            <label htmlFor="">Au:</label>
            <DatePicker dateFormat={"dd/MM/yyyy"} minDate={startDate} maxDate={new Date()} selected={endDate} onChange={(date) => setEndDate(date)} />
          </Col>
          <Col md="2">
            <Button color='success' onClick={() => { fetchData() }} style={{ marginTop: "32px" }}>Rechercher</Button>
          </Col>
        </Row>  
        <Row className='d-flex justify-content-end mb-2'>
          <Col md={2} className="feature-products ">
            {role === "admin" ?
              <UncontrolledDropdown>
                <DropdownToggle color='dark' caret className="b-r-0 ourBtn btn-block btn-lg">
                  {selectedteam}
                </DropdownToggle>
                <DropdownMenu >
                  <DropdownItem onClick={() => { setTeam("Antidouleur") }}>Antidouleur</DropdownItem>
                  <DropdownItem onClick={() => { setTeam("Gynéco") }}>Gynéco</DropdownItem>
                  <DropdownItem onClick={() => { setTeam("Neuropsychiatrie /HGE") }}>Neuropsychiatrie /HGE</DropdownItem>
                  <DropdownItem onClick={() => { setTeam("Visite Pharma") }}>Visite Pharma</DropdownItem>
                  <DropdownItem onClick={() => { setTeam("Diabète") }}>Diabète</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> : ''}
          </Col>
          {/* <Col md={10} className="feature-products">
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Nom contact"
                  defaultValue=""
                  onChange={(e) => handleSearchKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (page === 1) {
                        fetchData();
                      } else {
                        setPage(1);
                      }

                    }
                  }}
                />
                <i className="fa fa-search"></i>
              </FormGroup>
            </Form>
          </Col> */}
        </Row>
        <Row>
          <Col xs="12" className='o-auto'>
            {data.length === 0 ?
              <Card className='text-align-center'>
                <CardBody style={{ textAlign: "center" }}>
                  <h4>Aucun rapport à afficher</h4>
                </CardBody>
              </Card>
              :
              <Table style={{ backgroundColor: "white" }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Visiteur</th>
                    <th>Catégorie</th>
                    <th>Nbr Contacts</th>
                    <th>Ville</th>
                    <th>Adresse</th>
                    <th>Budget</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => {
                    return (
                      <tr key={data.indexOf(item)} >
                        <th scope='row'>{(data.indexOf(item) + 1) + ((page - 1) * 25)}
                          {/* <Badge color="secondary">urgent</Badge> */}
                        </th>
                        <td>{moment(item.focus.focus_done_on).format("DD/MM/yyyy à HH:mm")}</td>
                        <td>
                          <Link to={`${process.env.PUBLIC_URL}/users/user:${item.agent.user_id}`}>
                            {item.agent.user_lastname + " " + item.agent.user_firstname}
                          </Link>
                        </td>
                        <td>{item.focus.focus_category}</td>
                        <td className='text-center'>{item.focus.contacts_ids.length}</td>
                        <td>{item.focus.focus_wilaya + " " + item.focus.focus_city}</td>
                        <td>{item.focus.focus_address}</td>
                        <td>{isNaN(parseFloat(item.focus.focus_budget)) ?0.00 :parseFloat(item.focus.focus_budget).toFixed(2)} DZD</td>
                        <td style={{ textAlign: "center" }}>
                          <Link to={{
                            pathname: `${process.env.PUBLIC_URL}/focus/rapports/detailed`,
                            state: {
                              reportID: item.focus.focus_id,
                              agent_lastname: item.agent.user_lastname,
                              agent_firstname: item.agent.user_firstname,
                              agent_network: item.agent.user_network,
                              contact_ids: item.focus.contact_ids,
                              agent_id: item.agent.user_id,
                            }
                          }}>
                            <i className="fa fa-table" id="TooltipExample"></i>
                          </Link>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            }
          </Col>
        </Row>
        <Row className='pb-5'>
          <Col className='py-5'>
            <Pagination aria-label="Page navigation example" className="pagination justify-content-center pagination-primary" >
              <PaginationItem><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : 1)}><span aria-hidden="true">«</span><span className="sr-only">Previous</span></PaginationLink></PaginationItem>
              {(totalpages() === 1) &&
                <PaginationItem active={true}><PaginationLink >{page}</PaginationLink></PaginationItem>
              }
              {(totalpages() === 2) &&
                <>
                  <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 1 ? page : page - 1)}>{page === 1 ? page : page - 1}</PaginationLink></PaginationItem>
                  <PaginationItem active={page === 2 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 2 ? page : page + 1)}>{page === 2 ? page : page + 1}</PaginationLink></PaginationItem>
                </>
              }
              {(totalpages() > 2) &&
                <>
                  <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : page)}>{page === totalpages() ? page - 2 : page > 1 ? page - 1 : page}</PaginationLink></PaginationItem>
                  <PaginationItem active={page != 1 && page != totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page - 1 : page > 1 ? page : page + 1)}>{page === totalpages() ? page - 1 : page > 1 ? page : page + 1}</PaginationLink></PaginationItem>
                  <PaginationItem active={page === totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page : page > 1 ? page + 1 : page + 2)}>{page === totalpages() ? page : page > 1 ? page + 1 : page + 2}</PaginationLink></PaginationItem>
                </>
              }
              <PaginationItem><PaginationLink onClick={() => paginationNumber((totalpages() <= page) ? page : page + 1)}><span aria-hidden="true">»</span><span className="sr-only">Next</span></PaginationLink></PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
