import React, { Fragment, useState, useEffect } from "react";
import Breadcrumbs from "../../layout/breadcrumb";
import Loader from "../../layout/loader";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Table,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import "./Reports.css";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";

export default function Reports() {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const token = document.cookie.split(`jwt=`).pop().split(";").shift();
  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword);
  };
  //Fetch API call
  const fetchData = async () => {
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ start_date: startDate, end_date: endDate }),
    };
    try {
      const responce = await fetch(
        `https://lsreport.shls.care/api/users/user/duo/all?user_id=${
          location.state && location.state.user?.user_id
        }`,
        request,
        { mode: "cors" }
      );
      if (responce.status === 200) {
        let data = await responce.json();
        console.log(data);
        setData(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  };

  const exportData = async () => {
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        // 'Accept': GetMineType(ext),
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ start_date: startDate, end_date: endDate }),
    };
    try {
      var responce = await fetch(
        `https://lsreport.shls.care/api/users/duo/all/export?user_id=${
          location.state && location.state.user.user_id
        }`,
        request,
        { mode: "cors" }
      );
      if (responce.status === 200) {
        const blob = await responce.blob();
        const downloadLink = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download =
          location.state && location.state.user ?
          location.state.user.user_firstname +
            "_" +
            location.state.user.user_lastname +
            ".xlsx" : "Rapport_duo_all.xlsx"; // Set the desired file name
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up resources
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
        toast.success("success");
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Visites Duo" title="Fiches Duo" />
      <Container fluid={true} className="pb-4 mb-4">
        <Row className="mb-3 d-flex justify-content-between">
          <Col md="2">
            <label htmlFor="">Du:</label>
            <DatePicker
              dateFormat={"dd/MM/yyyy"}
              selected={startDate}
              maxDate={new Date()}
              onChange={(date) => setStartDate(date)}
            />
          </Col>
          <Col md="2">
            <label htmlFor="">Au:</label>
            <DatePicker
              dateFormat={"dd/MM/yyyy"}
              minDate={startDate}
              maxDate={new Date()}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Col>
          <Col md="2">
            <Button
            className="btn-block"
              color="warning"
              onClick={() => {
                fetchData();
              }}
              style={{ marginTop: "32px" }}
            >
              Rechercher
            </Button>
          </Col>
          <Col md={2} className="d-flex align-items-end">
            <button
              className="btn btn-info b-r-0 ourBtn btn-block "
              onClick={() =>
                history.push({
                  pathname: `${process.env.PUBLIC_URL}/visitesDuo/add`,
                  state: {
                    agent_id: location.state && location.state.user?.user_id,
                    agent_lastname:
                      location.state && location.state.user?.user_lastname,
                    agent_firstname:
                      location.state && location.state.user?.user_firstname,
                  },
                })
              }
            >
              Ajouter
            </button>
          </Col>
          <Col md={2} className="d-flex align-items-end">
          <button
            className="btn btn-block btn-success mr-2"
            onClick={() => {
              exportData();
            }}
          >
            {" "}
            Exporter
          </button>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col xs="12" className="o-auto pb-4">
            {data.length === 0 ? (
              <Card className="text-align-center">
                <CardBody style={{ textAlign: "center" }}>
                  <h4>Aucun rapport à afficher</h4>
                </CardBody>
              </Card>
            ) : (
              <Table className="mb-4" style={{ backgroundColor: "white" }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Visiteur</th>
                    <th>Superviseur</th>
                    <th>Secteur</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((fiche) => {
                    return (
                      <tr key={data.indexOf(fiche)}>
                        <td>{data.indexOf(fiche) + 1}</td>
                        <td>
                          {location.state &&
                            moment(fiche.duo_date).format("YYYY-MM-DD")}
                        </td>
                        <td>
                          {location.state &&
                            location.state?.user?.user_lastname}{" "}
                          {location.state &&
                            location.state?.user?.user_firstname}
                        </td>
                        <td>
                          {fiche?.supervisor?.user_lastname}{" "}
                          {fiche.supervisor?.user_firstname}
                        </td>
                        <td>{location.state && fiche.duo_wilaya}</td>
                        <td style={{ textAlign: "center" }}>
                          <Link
                            to={{
                              pathname: `${process.env.PUBLIC_URL}/visiteDuo/rapportDetails`,
                              state: {
                                fiche,
                                visiteur: `${
                                  location.state &&
                                  location.state.user?.user_lastname
                                } ${
                                  location.state &&
                                  location.state.user?.user_firstname
                                }`,
                              },
                            }}
                          >
                            <i className="fa fa-table" id="TooltipExample"></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
