import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import { toast } from 'react-toastify';
import { Container, Row, Col, CardHeader, Table, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Button, CardFooter } from 'reactstrap';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import Loader from '../../layout/loader';
import jwt from 'jsonwebtoken';

export default function GrossisteDetailed() {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const role = token.length > 0 ? jwt.decode(token).type : "";
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [products, setProducts] = useState([]);
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => { setVerticalcenter(!Verticalcenter) };
  const [grossisteDetails, setGrossiteDetails] = useState({
    grossiste_name: location.state ? location.state.grossiste.grossiste_name : "",
    grossiste_reseau: location.state ? location.state.grossiste.grossiste_reseau : "",
    grossiste_wilaya: location.state ? location.state.grossiste.grossiste_wilaya : "",
    grossiste_phone: location.state ? location.state.grossiste.grossiste_phone : "",
    grossiste_region: location.state ? location.state.grossiste.grossiste_region : "",
    grossiste_commune: location.state ? location.state.grossiste.grossiste_commune : "",
    grossiste_id: location.state ? location.state.grossiste.grossiste_id : "",
  });

  useEffect(() => {
    if (location.state) {
      setProducts(location.state.grossiste.products)
    }
    window.scrollTo(0, 0);
  }, []);


  const updateGrossiste = async (id) => {
    setLoading(true);

    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        grossiste_name: grossisteDetails.grossiste_name,
        grossiste_region: grossisteDetails.grossiste_region,
        grossiste_commune: grossisteDetails.grossiste_commune,
        grossiste_wilaya: grossisteDetails.grossiste_wilaya,
        grossiste_reseau: grossisteDetails.grossiste_reseau,
        grossiste_phone: grossisteDetails.grossiste_phone,
        grossiste_id: grossisteDetails.grossiste_id
      })
    }
    try {
      var response = await fetch(`https://lsreport.shls.care/api/grossiste/update`, request, { mode: "cors" });
      if (response.status === 200) {
        toast.success("Grossiste mis à jour avec succès");
        history.push(`${process.env.PUBLIC_URL}/grossiste/list`);
      } else {
        toast.error("Erreur !");
      }
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  }

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Grossiste" title="Détails de grossiste" />
      <Container fluid={true} className='pb-4 mb-4'>
        <Row className='pb-4'>
          <Col xl="4" className='mb-4'>
            <Card>
              <CardHeader className='text-center'>
                <h4>{location.state && location.state.grossiste.grossiste_name}
                  <i className="fa fa-edit mr-2 text-warning " onClick={() => {
                    Verticalcentermodaltoggle()
                  }} role="button" ></i>
                </h4>
              </CardHeader>
              <CardBody className='pb-4'>
                <Row className="mb-2 pb-4">
                  <Col>
                    <Table>
                      <tbody>
                        {/* <tr>
                          <th>Manager:</th>
                          <td>{location.state && (location.state.grossiste.supervisor_lastname + " " + location.state.grossiste.supervisor_firstname)}</td>
                        </tr> */}
                        <tr>
                          <th>Grossiste ID:</th>
                          <td>{location.state && location.state.grossiste.grossiste_id}</td>
                        </tr>
                        <tr>
                          <th>Reseau:</th>
                          <td>{location.state && location.state.grossiste.grossiste_reseau}</td>
                        </tr>
                        <tr>
                          <th>Region:</th>
                          <td>{location.state && location.state.grossiste.grossiste_region}</td>
                        </tr>
                        <tr>
                          <th>Phone:</th>
                          <td>{location.state && location.state.grossiste.grossiste_phone}</td>
                        </tr>
                        <tr>
                          <th>Wilaya:</th>
                          <td>{location.state && location.state.grossiste.grossiste_wilaya}</td>
                        </tr>
                        <tr>
                          <th>Commune:</th>
                          <td>{location.state && location.state.grossiste.grossiste_commune}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
          <Col xl="8" className='mb-4'>
            <Card>
              <CardHeader className='d-flex justify-content-between'>
                <h4 className="card-title mb-0">Liste des Produits</h4>
              </CardHeader>
              {(location.state && location.state.grossiste.products === null) ?
                <CardBody>
                  <h5>Aucun produit à afficher</h5>
                </CardBody>
                :
                <>
                  <CardBody>
                    <Table className='text-center'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Nom Produit</th>
                          <th>Produit DCI</th>
                          <th>Form de produit</th>
                          <th>Prix de produit</th>
                          <th>Produit en vente</th>
                          <th>Unite de vente</th>
                        </tr>
                      </thead>
                      <tbody>
                        {location.state && location.state.grossiste.products.map((item) => {
                          return (
                            <tr key={location.state && location.state.grossiste.products.indexOf(item)}>
                              <td> {(location.state && location.state.grossiste.products.indexOf(item) + 1)}</td>
                              <td>{item.product_name}</td>
                              <td>{item.product_dci}</td>
                              <td>{item.product_form}</td>
                              <td>{item.product_price}</td>
                              <td>{item.product_can_sale}</td>
                              <td>{item.product_sales_unit}</td>
                            </tr>
                          )
                        })}

                      </tbody>
                    </Table>
                  </CardBody>
                </>
              }
            </Card>
            <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle} centered>
              <ModalHeader toggle={Verticalcentermodaltoggle}>
                Modifier Grossiste
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md="12" className='input--group'>
                    <FormGroup>
                      <Label className="form-label" >Nom: </Label>
                      <Input type="text" className="form-control" placeholder="Nom" onChange={(e) => { setGrossiteDetails({ ...grossisteDetails, grossiste_name: e.target.value }) }} defaultValue={location.state && location.state.grossiste.grossiste_name} />
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label" >Wilaya: </Label>
                      <Input type="text" className="form-control" placeholder="Wilaya" onChange={(e) => { setGrossiteDetails({ ...grossisteDetails, grossiste_wilaya: e.target.value }) }} defaultValue={location.state && location.state.grossiste.grossiste_wilaya} />
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label" >Commune: </Label>
                      <Input type="text" className="form-control" placeholder="Commune" onChange={(e) => { setGrossiteDetails({ ...grossisteDetails, grossiste_commune: e.target.value }) }} defaultValue={location.state && location.state.grossiste.grossiste_commune} />
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label" >Region: </Label>
                      <Input type="text" className="form-control" placeholder="Region" onChange={(e) => { setGrossiteDetails({ ...grossisteDetails, grossiste_region: e.target.value }) }} defaultValue={location.state && location.state.grossiste.grossiste_region} />
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label" >Phone: </Label>
                      <Input type="text" className="form-control" placeholder="Phone" onChange={(e) => { setGrossiteDetails({ ...grossisteDetails, grossiste_phone: e.target.value }) }} defaultValue={location.state && location.state.grossiste.grossiste_phone} />
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label" >Reseau: </Label>
                      <input type="text" className="form-control" placeholder="Reseau" onChange={(e) => { setGrossiteDetails({ ...grossisteDetails, grossiste_reseau: e.target.value }) }} defaultValue={location.state && location.state.grossiste.grossiste_reseau} />
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className='d-block'>
                <Row>
                  <Col>
                    <Button color='dark' onClick={() => { updateGrossiste() }}>
                      Mise à jour
                    </Button>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
