import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import "./ParametersObjectives.css";

export default function ParametersRatio() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const [loading, setloading] = useState(false);
  const [rant, setrant] = useState({ objectif_doctors: "", objectif_pharmacy: "" });
  const [rgyn, setrgyn] = useState({ objectif_doctors: "", objectif_pharmacy: "" });
  const [rneu, setrneu] = useState({ objectif_doctors: "", objectif_pharmacy: "" });
  const [rvis, setrvis] = useState({ objectif_doctors: "", objectif_pharmacy: "" });
  const [rdia, setrdia] = useState({ objectif_doctors: "", objectif_pharmacy: "" });

  const modifySettings = async (obj) => {
    setloading(true);
    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(obj)
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/settings/update", request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success("Modifier avec success");
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }

  const getSettings = async () => {
    setloading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      }
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/settings/all", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setrant(data.filter(obj => obj.setting_title === "Antidouleur")[0]);
        setrgyn(data.filter(obj => obj.setting_title === "Gynéco")[0]);
        setrneu(data.filter(obj => obj.setting_title === "Neuropsychiatrie /HGE")[0]);
        setrvis(data.filter(obj => obj.setting_title === "Visite Pharma")[0]);
        setrdia(data.filter(obj => obj.setting_title === "Diabète")[0]);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }

  useEffect(() => {
    getSettings();
  }, []);


  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Paramétres" title="Paramétres objectifs" />
      <Container fluid={true} className='pb-4 mb-4'>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <div>
                  <p>Antidouleur</p>
                  <label htmlFor="ant-roa+">Ratio A+</label>
                  <input
                    name='ant-roa+'
                    type="number"
                    value={rant.setting_ro_a_plus}
                    onChange={e => setrant({ ...rant, setting_ro_a_plus: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-roa">Ratio A</label>
                  <input
                    name='ant-roa'
                    type="number"
                    min={1} max={100}
                    value={rant.setting_ro_a}
                    onChange={e => setrant({ ...rant, setting_ro_a: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-rob">Ratio B</label>
                  <input
                    name='ant-rob'
                    type="number"
                    min={1} max={100}
                    value={rant.setting_ro_b}
                    onChange={e => setrant({ ...rant, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="ant-roc">Ratio C</label>
                  <input
                    name='ant-roc'
                    type="number"
                    min={1} max={100}
                    value={rant.setting_ro_c}
                    onChange={e => setrant({ ...rant, setting_ro_c: parseInt(e.target.value) })}
                  />
                  <Button onClick={() => modifySettings(rant)}>Modifier</Button>
                </div>
                <div>
                  <p>Gynéco</p>
                  <label htmlFor="gyn-doc">Ratio A+</label>
                  <input
                    name='gyn-roa+'
                    type="number"
                    value={rgyn.setting_ro_a_plus}
                    onChange={e => setrgyn({ ...rgyn, setting_ro_a_plus: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="gyn-roa">Ratio A</label>
                  <input
                    name='gyn-roa'
                    type="number"
                    min={1} max={100}
                    value={rgyn.setting_ro_a}
                    onChange={e => setrgyn({ ...rgyn, setting_ro_a: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="gyn-rob">Ratio B</label>
                  <input
                    name='gyn-rob'
                    type="number"
                    min={1} max={100}
                    value={rgyn.setting_ro_b}
                    onChange={e => setrgyn({ ...rgyn, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="gyn-roc">Ratio C</label>
                  <input
                    name='gyn-roc'
                    type="number"
                    min={1} max={100}
                    value={rgyn.setting_ro_c}
                    onChange={e => setrgyn({ ...rgyn, setting_ro_c: parseInt(e.target.value) })}
                  />
                  <Button onClick={() => modifySettings(rgyn)}>Modifier</Button>
                </div>
                <div>
                  <p>Neuropsychiatrie /HGE</p>
                  <label htmlFor="neu-doc">Ratio A+</label>
                  <input
                    name='neu-roa+'
                    type="number"
                    value={rneu.setting_ro_a_plus}
                    onChange={e => setrneu({ ...rneu, setting_ro_a_plus: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="neu-roa">Ratio A</label>
                  <input
                    name='neu-roa'
                    type="number"
                    min={1} max={100}
                    value={rneu.setting_ro_a}
                    onChange={e => setrneu({ ...rneu, setting_ro_a: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="neu-rob">Ratio B</label>
                  <input
                    name='neu-rob'
                    type="number"
                    min={1} max={100}
                    value={rneu.setting_ro_b}
                    onChange={e => setrneu({ ...rneu, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="neu-roc">Ratio C</label>
                  <input
                    name='neu-roc'
                    type="number"
                    min={1} max={100}
                    value={rneu.setting_ro_b}
                    onChange={e => setrneu({ ...rneu, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <Button onClick={() => modifySettings(rneu)}>Modifier</Button>
                </div>
                <div>
                  <p>Visite Pharma</p>
                  <label htmlFor="vis-doc">Ratio A+</label>
                  <input
                    name='vis-roa+'
                    type="number"
                    value={rvis.setting_ro_a_plus}
                    onChange={e => setrvis({ ...rvis, setting_ro_a_plus: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="vis-roa">Ratio A</label>
                  <input
                    name='vis-roa'
                    type="number"
                    min={1} max={100}
                    value={rvis.setting_ro_a}
                    onChange={e => setrvis({ ...rvis, setting_ro_a: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="vis-rob">Ratio B</label>
                  <input
                    name='vis-rob'
                    type="number"
                    min={1} max={100}
                    value={rvis.setting_ro_b}
                    onChange={e => setrvis({ ...rvis, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="vis-roc">Ratio C</label>
                  <input
                    name='vis-roc'
                    type="number"
                    min={1} max={100}
                    value={rvis.setting_ro_b}
                    onChange={e => setrvis({ ...rvis, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <Button onClick={() => modifySettings(rvis)}>Modifier</Button>
                </div>
                <div>
                  <p>Diabète</p>
                  <label htmlFor="dia-doc">Ratio A+</label>
                  <input
                    name='dia-roa+'
                    type="number"
                    value={rdia.setting_ro_a_plus}
                    onChange={e => setrdia({ ...rdia, setting_ro_a_plus: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="dia-roa">Ratio A</label>
                  <input
                    name='dia-roa'
                    type="number"
                    min={1} max={100}
                    value={rdia.setting_ro_a}
                    onChange={e => setrdia({ ...rdia, setting_ro_a: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="dia-rob">Ratio B</label>
                  <input
                    name='dia-rob'
                    type="number"
                    min={1} max={100}
                    value={rdia.setting_ro_b}
                    onChange={e => setrdia({ ...rdia, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <br />
                  <label htmlFor="dia-roc">Ratio C</label>
                  <input
                    name='dia-roc'
                    type="number"
                    min={1} max={100}
                    value={rdia.setting_ro_b}
                    onChange={e => setrdia({ ...rdia, setting_ro_b: parseInt(e.target.value) })}
                  />
                  <Button onClick={() => modifySettings(rdia)}>Modifier</Button>
                </div>
                <div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
