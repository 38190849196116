import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col, Table, Card, CardBody, CardHeader, } from 'reactstrap';
import { toast } from 'react-toastify';
import { useLocation, Link } from 'react-router-dom';
import Breadcrumbs from '../../layout/breadcrumb';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import XlsxPopulate from "xlsx-populate";

export default function DetailedReport() {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState({ products_reports: [], contacts_ids:[] });
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const location = useLocation();
  const [contact_rows, setContactRows]=useState([])
  const [product_rows, setProductRows]=useState([])
  const reportID = location.state != null ? location.state.reportID : "";
  const agent = {
    user_id: location.state == null ? "" : location.state.agent_id,
    lastname: location.state == null ? "" : location.state.agent_lastname,
    firstname: location.state == null ? "" : location.state.agent_firstname,
    network: location.state == null ? "" : location.state.agent_network,
  }

  //Fetch API call
  const fetchData = async () => {
    if (!reportID) {
      return;
    }
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ focus_id: reportID })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/reports/focus", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setReport(data);
        const contacts =data.contacts_ids.map((cont)=>{
            return [cont.contact_id,cont.contact_lastname+" "+cont.contact_firstname,
            cont.contact_speciality,
            cont.contact_potential[agent.network],
            cont.contact_prescription_possibility[agent.network],
            cont.contact_wilaya ]
          })
        setContactRows(contacts)

        const produits =data.products_reports.map((prod)=>{
          return [prod.prodcut_name,
            prod.product_report_availabel ? "Oui" : "Non",
            prod.product_report_not_availabel,
            prod.product_report_rotation,
            prod.product_report_renewal,
            prod.product_report_refusal,
            prod.product_report_comment
           ]
        })
        setProductRows(produits)
        
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }
  
  
  useEffect(() => {
    if (reportID != "") {
      fetchData();
    }
    window.scrollTo(0, 0);
  }, [location.pathname])


  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Visites" title="Rapports"  hide={false}/>
      <Container fluid={true} className='pb-4 mb-4'>
      <Row className='d-none d-print-block' style={{marginTop:"50px"}}>
          <Col col={12}>
            <Card>
              <CardHeader className='text-center'>
               <h1>
                Rapport De Visite
                </h1> 
              </CardHeader>
              <CardBody>
                <Table className='table table-borderless'>
                  <tbody>
                    <tr>
                      <th>Visiteur</th>
                      <td>
                        <Link to={`${process.env.PUBLIC_URL}/users/user:${agent.user_id}`}>
                          {`${agent.lastname} ${agent.firstname}`}
                        </Link>
                      </td>

                      <th>Equipe</th>
                      <td>{agent.network}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='d-print-none'>
          <Col col={12}>
            <Card>
              <CardBody>
                <Table className='table table-borderless'>
                  <tbody>
                    <tr>
                      <th>Visiteur</th>
                      <td>
                        <Link to={`${process.env.PUBLIC_URL}/users/user:${agent.user_id}`}>
                          {`${agent.lastname} ${agent.firstname}`}
                        </Link>
                      </td>

                      <th>Equipe</th>
                      <td>{agent.network}</td>

                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
          <h5>Detail Contacts</h5>
            <Card>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Contact</th>
                      <th>Specialité</th>
                      <th>Potentiel</th>
                      <th>Possibilité de prescription</th>
                      <th>Adresse</th>
                    </tr>
                  </thead>
                  <tbody>
                    {report.contacts_ids.map((contact)=>{
                      return(
                        <tr key={contact.contact_id}>
                          <td>{contact.contact_lastname} {contact.contact_firstname}</td>
                          <td>{contact.contact_speciality}</td>
                          <td>{contact.contact_potential && contact.contact_potential[agent.network]}</td>
                          <td>{contact.contact_prescription_possibility && contact.contact_prescription_possibility[agent.network]}</td>
                          <td colSpan={3}>{contact.contact_address} {contact.contact_wilaya}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        
        </Row>
        <Row>
          <Col md={12}>
            <h5>Detail visite</h5>
            <Card>
              <CardBody style={{ overflow: "auto" }}>
                <Table>
                  <thead>
                    <tr>
                      <th>Catégorie</th>
                      <th>Ville</th>
                      <th>Adresse</th>
                      <th>Budget</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{report.focus_category}</td>
                      <td>{report.focus_wilaya} {report.focus_city}</td> 
                      <td>{report.focus_adress}</td> 
                      <td>{isNaN(parseFloat(report.focus_budget))? 0.00:parseFloat(report.focus_budget).toFixed(2)} DZD</td> 
                      <td>{moment(report.focus_done_on).format("DD/MM/yyyy à HH:mm")}</td> 
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className='o-auto'>
            <h5>Produits présentés</h5>
            <Card className='o-auto'>
              <CardBody>
                {report.report_type === "pharmacy" ?
                  <Table>
                    <thead>
                      <tr>
                        <th>Produit</th>
                        <th>Disponible</th>
                        <th>Indisponible?</th>
                        <th>Rotation</th>
                        <th>Renouvellement commande</th>
                        <th>Pourquoi refus</th>
                        <th>Commentaire</th>
                        <th>Echantillon?</th>
                        <th>Quantité</th>
                      </tr>
                    </thead>
                    <tbody>
                      {report.products_reports.map((prod) => {
                        return (
                          <tr key={report.products_reports.indexOf(prod)}>
                            <td>{prod.prodcut_name}</td>
                            <td>{prod.product_report_availabel ? "Oui" : "Non"}</td>
                            <td>{prod.product_report_not_availabel}</td>
                            <td>{prod.product_report_rotation}</td>
                            <td>{prod.product_report_renewal}</td>
                            <td>{prod.product_report_refusal}</td>
                            <td>{prod.product_report_comment}</td>
                            <td>{(prod?.product_report_remis && prod?.product_report_quantity >0)?"Oui":"Non"}</td>
                            <td>{(prod?.product_report_quantity >0)?  prod?.product_report_quantity:0}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  :
                  <Table>
                    <thead>
                      <tr>
                        <th>Produit</th>
                        <th>Préscrit</th>
                        <th>Fréquence prescription</th>
                        <th>Pourquoi non préscrit?</th>
                        <th>Pourquoi non interessé?</th>
                        <th>Commentaire</th>
                      </tr>
                    </thead>
                    <tbody>
                      {report.products_reports.map((prod) => {
                        return (
                          <tr key={report.products_reports.indexOf(prod)}>
                            <td>{prod.prodcut_name}</td>
                            <td>{prod.product_report_prescribed ? "Oui" : "Non"}</td>
                            <td>{prod.product_report_frequancy}</td>
                            <td>{prod.product_report_not_prescribed}</td>
                            <td>{prod.product_report_intrest}</td>
                            <td>{prod.product_report_comment}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Commentaire visiteur</h5>
            <Card>
              <CardBody>
                <em>{report.focus_visitor_comment}</em>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='pb-4'>
          <Col className='pb-4'>
            <h5>Commentaire de la personne visitée</h5>
            <Card>
              <CardBody>
                <em>{report.focus_visited_comment}</em>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment >
  )
}