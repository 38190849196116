import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, Form, FormGroup, Table, Card, CardBody, Modal, ModalBody, ModalFooter, Label, Input, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link, useLocation, useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import Swal from 'sweetalert2';

export default function GrossisteList() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const user_id = token.length > 0 ? jwt.decode(token).id : "";
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => { setVerticalcenter(!Verticalcenter) };
  const [grossisteDetails, setGrossiteDetails] = useState({
    grossiste_name: "",
    grossiste_reseau: "",
    grossiste_wilaya: "",
    grossiste_phone: "",
    grossiste_region: "",
    grossiste_commune: "",
    grossiste_id: "",
  });

  const fetchData = async () => {
    setLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      }
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/grossiste/all?user_id=" + user_id, request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setData(data);
      } else {

        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const confirmDelete = (user_id) => {
    Swal.fire({
      title: "Voulez vous vraiment supprimer le grossiste ?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      denyButtonText: `Annuler`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteGrossiste(user_id)
      } else if (result.isDenied) {
        return
      }
    });
  }

  const deleteGrossiste = async (user_id) => {
    if (user_id == null) {
      return;
    }
    setLoading(true);
    const request = {
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ grossiste_id: user_id })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/grossiste/delete", request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success("Grossiste supprimé avec succès");
        fetchData()
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const createGrossiste = async (id) => {
    setLoading(true);

    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        grossiste_name: grossisteDetails.grossiste_name,
        grossiste_region: grossisteDetails.grossiste_region,
        grossiste_commune: grossisteDetails.grossiste_commune,
        grossiste_wilaya: grossisteDetails.grossiste_wilaya,
        grossiste_reseau: grossisteDetails.grossiste_reseau,
        grossiste_phone: grossisteDetails.grossiste_phone,
        grossiste_id: grossisteDetails.grossiste_id
      })
    }
    try {
      var response = await fetch(`https://lsreport.shls.care/api/grossiste/new`, request, { mode: "cors" });
      if (response.status === 200) {
        toast.success("Grossiste mis à jour avec succès");
        history.push(`${process.env.PUBLIC_URL}/grossiste/list`);
      } else {
        toast.error("Erreur !");
      }
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  }



  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [page])


  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Grossiste" title="Grossiste" />

      <Container fluid={true} className='pb-4 mb-4'>
        <Row>
          <Col>
            <Button color='dark' onClick={() => { Verticalcentermodaltoggle() }}>
              Ajouter
            </Button>
          </Col>
        </Row>
        <br />

        {data.length === 0 ?
          <Card className='text-align-center'>
            <CardBody style={{ textAlign: "center" }}>
              <h4>Aucun Grossiste à afficher</h4>
            </CardBody>
          </Card>
          :
          <>
            <Row>
              <Col xs={12} className='o-auto'>
                <Table style={{ background: "white" }}>
                  <thead>
                    <tr className='text-center'>
                      <th>#</th>
                      <th>Name</th>
                      <th>Reseau</th>
                      <th>Phone</th>
                      <th>Region</th>
                      <th>Wilaya</th>
                      <th>Commune</th>
                      {/* <th>Superviseur</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((grossiste) => {
                      return (
                        <tr key={data.indexOf(grossiste)} className='text-center'>
                          <th scope='row'> {(data.indexOf(grossiste) + 1)}</th>
                          {/* <td>{grossiste.grossiste_id}</td> */}
                          <td>{grossiste.grossiste_name}</td>
                          <td>{grossiste.grossiste_reseau}</td>
                          <td>{grossiste.grossiste_phone}</td>
                          <td>{grossiste.grossiste_region}</td>
                          <td>{grossiste.grossiste_wilaya}</td>
                          <td>{grossiste.grossiste_commune}</td>
                          {/* <td>{grossiste.supervisor_firstname} {grossiste.supervisor_lastname}</td> */}
                          <td style={{ textAlign: "center" }}>
                            <Link to={{
                              pathname: `${process.env.PUBLIC_URL}/grossiste/detailed`,
                              state: {
                                grossiste: grossiste,
                              }
                            }}>
                              <i className="fa fa-table mr-2" id="TooltipExample"></i>
                            </Link>
                            <i className="fa fa-trash text-danger ml-2" id="TooltipExample" style={{ cursor: "pointer" }} onClick={() => confirmDelete(grossiste.grossiste_id)}></i>
                          </td>
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </Table>
                <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle} centered>
                  <ModalHeader toggle={Verticalcentermodaltoggle}>
                    Ajouter Grossiste
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col md="12" className='input--group'>
                        <FormGroup>
                          <Label className="form-label" >Nom: </Label>
                          <Input type="text" className="form-control" placeholder="Nom" onChange={(e) => { setGrossiteDetails({ ...grossisteDetails, grossiste_name: e.target.value }) }} />
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-label" >Wilaya: </Label>
                          <Input type="text" className="form-control" placeholder="Wilaya" onChange={(e) => { setGrossiteDetails({ ...grossisteDetails, grossiste_wilaya: e.target.value }) }} />
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-label" >Commune: </Label>
                          <Input type="text" className="form-control" placeholder="Commune" onChange={(e) => { setGrossiteDetails({ ...grossisteDetails, grossiste_commune: e.target.value }) }} />
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-label" >Region: </Label>
                          <Input type="text" className="form-control" placeholder="Region" onChange={(e) => { setGrossiteDetails({ ...grossisteDetails, grossiste_region: e.target.value }) }} />
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-label" >Phone: </Label>
                          <Input type="text" className="form-control" placeholder="Phone" onChange={(e) => { setGrossiteDetails({ ...grossisteDetails, grossiste_phone: e.target.value }) }} />
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-label" >Reseau: </Label>
                          <input type="text" className="form-control" placeholder="Reseau" onChange={(e) => { setGrossiteDetails({ ...grossisteDetails, grossiste_reseau: e.target.value }) }} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter className='d-block'>
                    <Row>
                      <Col>
                        <Button color='dark' onClick={() => { createGrossiste() }}>
                          Ajouter
                        </Button>
                      </Col>
                    </Row>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>

          </>
        }

      </Container>
    </Fragment>
  )
}
