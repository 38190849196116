import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Table, Card, CardBody, } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';

import "react-datepicker/dist/react-datepicker.css";



export default function ProductFilteredReports() {
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const [data, setdata] = useState([]);

  const fetchData = async () => {
    setloading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        mindate: location.state.mindate,
        maxdate: location.state.maxdate,
        team: location.state.team,
        product_id: location.state.product_id,
        search_column: location.state.search_column,
        search_key: location.state.search_key
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/reports/statsdetail", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
       
        setdata(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Statistiques" title="statistiques Produits" />
      <Container fluid={true} className='pb-4 mb-4'>
        <Row>
          <Col md="4"><h6>Filtre: {location.state && location.state.Filtre}</h6></Col>
          <Col md="4"><h6>Paramétre: {location.state && location.state.Paramétre}</h6></Col>
          <Col md="4"><h6>Nombre de Rapport: {data.length}</h6></Col>
        </Row>
        <Row>
          <Col md="12" className='o-auto'>
            {data.length === 0 ?
              <Card className='text-align-center'>
                <CardBody style={{ textAlign: "center" }}>
                  <h4>Aucun rapport à afficher</h4>
                </CardBody>
              </Card>
              :
              <Table style={{ backgroundColor: "white" }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Visituer</th>
                    <th>Contact</th>
                    <th>Specialité</th>
                    <th>Potentiel</th>
                    <th>Secteur</th>
                    <th>Ville</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => {
                    const diffrence = (moment(item.report.report_end_time).diff(moment(item.report.report_start_time), "minutes"))
                    return (
                      <tr key={data.indexOf(item)} style={{ backgroundColor: (diffrence < 5 || Number.isNaN(diffrence)) ? "rgb(242, 255, 102,0.3)" : "" }}>
                        <td> {(data.indexOf(item) + 1)}</td>
                        <td>{moment(item.report.report_done_on).format("DD/MM/yyyy à HH:mm")}</td>
                        <td>
                          <Link to={`${process.env.PUBLIC_URL}/users/user:${item.agent.user_id}`}>
                            {item.agent.user_lastname + " " + item.agent.user_firstname}
                          </Link>
                        </td>
                        <td>
                          <Link to={`${process.env.PUBLIC_URL}/contacts/contact:${item.contact.contact_id}`}>
                            {item.contact.contact_lastname + " " + item.contact.contact_firstname}
                          </Link>
                        </td>
                        <td>{item.contact.contact_speciality}</td>
                        <td>{item.contact.contact_potential[item.agent.user_network] ?? item.contact.contact_potential["Visite Pharma"]}</td>
                        <td>{item.contact.contact_sector}</td>
                        <td>{item.contact.contact_wilaya + " " + item.contact.contact_city}</td>
                        <td style={{ textAlign: "center" }}>
                          <Link to={{
                            pathname: `${process.env.PUBLIC_URL}/visites/rapports/detailed`,
                            state: {
                              reportID: item.report.report_id,
                              contact_lastname: item.contact.contact_lastname,
                              contact_firstname: item.contact.contact_firstname,
                              contact_speciality: item.contact.contact_speciality,
                              contact_address: item.contact.contact_address,
                              contact_wilaya: item.contact.contact_wilaya,
                              contact_prescription_possibility: item.contact.prescription_possibility,
                              contact_potentiel: (item.contact.contact_potential[item.agent.user_network] ?? item.contact.contact_potential["Visite Pharma"]),
                              agent_lastname: item.agent.user_lastname,
                              agent_firstname: item.agent.user_firstname,
                              agent_network: item.agent.user_network,
                              contact_id: item.contact.contact_id,
                              agent_id: item.agent.user_id
                            }
                          }}>
                            <i className="fa fa-table" id="TooltipExample"></i>
                          </Link>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            }
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
