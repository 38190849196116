import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, Table, } from 'reactstrap'
import Loader from "../../layout/loader";
import Chart from 'react-apexcharts'
import { toast } from 'react-toastify';
import { useLocation, Link, useHistory } from 'react-router-dom';
import moment from 'moment';

const DetailedDashboard = () => {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const [data, setData] = useState({
    progress: 0,
    nbrdoctors: 0,
    nbrpharmacy: 0,
    nbrtotal: 0,
    objectifs: 0,
    objectif_doctors: 0,
    objectif_pharmacy: 0,
    nbrpotentiala: 0,
    nbrpotentialb: 0,
    nbrpotentialc: 0,
    roa: 0,
    rob: 0,
    roc: 0,
    reports: [],
    locations: []
  });
  const [isLoading, setIsloading] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const userID = location.state != null ? location.state.userID : "";
  const periode = location.state != null ? location.state.period : "";
  const userNetwork = location.state != null ? location.state.network : "";
  const userLastname = location.state != null ? location.state.userLastname : "";
  const userFirstname = location.state != null ? location.state.userFirstname : "";


  function teamOptions(tour) {
    return {
      series: [tour.progress.toFixed(2)],
      options: {
        chart: {
          height: 450,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: '75%',
            },
            dataLabels: {
              name: {
                show: false,
                color: '#fff'
              },
              value: {
                show: true,
                color: tour.progress > 85 ? "#00B187" : tour.progress > 60 ? "#FF8162" : "#FF4149",
                offsetY: 0,
                fontSize: '30px'
              }
            }
          }
        },
        fill: {
          colors: tour.progress > 85 ? ["#00B187"] : tour.progress > 60 ? ["#FF8162"] : ["#FF4149"],
          type: 'gradient',
          gradient: {
            gradientToColors: tour.progress > 85 ? ["#00B187"] : tour.progress > 60 ? ["#FF8162"] : ["#FF4149"]
          }
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['Volatility'],

      }
    }
  }

  // API call function
  const fetchData = async (periode, userID) => {
    setIsloading(true);
    if (userID === "") {
      return (window.location.href = `${process.env.PUBLIC_URL}/pages/errors/error404`)
    }
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ start_date:location.state.start_date && location.state.start_date
        ,end_date: location.state.end_date && location.state.end_date
        ,user_id: userID })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/stats/webdashboarduserstats", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setData(data);
      } else {
        toast.error(await responce.json());
        history.replace("/dashboard/default")
      }
    } catch (err) {
      toast.error(err.message);
    }
    setIsloading(false);
  }

  useEffect(() => {
    fetchData(periode, userID);
    window.scrollTo(0, 0);
    return () => { }
  }, [])

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb title="Tableau de bord" hide={true} />
      <Container fluid={true} className='pb-4 mb-4'>
        <Col md="12" className="">
          <Card className="o-hidden">
            <CardHeader>
              <h5>Visiteur</h5>
              <br />
              <p><strong>{userLastname} {userFirstname}</strong></p>
              <p style={{ color: "darkblue" }}><strong>{userNetwork}</strong></p>
            </CardHeader>
            <div className="bar-chart-widget">
              <div className="top-content bg-primary"></div>
              <div className="bottom-content card-body">
                <Row>
                  <Col xs="12">
                    <div id="chart-widget5">
                      <Chart options={teamOptions(data).options} series={teamOptions(data).series} height="360" type="radialBar" />
                    </div>
                  </Col>
                </Row>
                <Row className="text-center f-w-600">
                  <Col xs="4" className="b-r-light">
                    <div>
                      <h4 className="num m-0"><span className="counter color-bottom">Visites</span></h4>
                      <span className="text-muted mt-2 mb-2 block-bottom">Total: {data.nbrtotal} / {data.objectifs}</span>
                      <span className="text-muted mt-2 mb-2 block-bottom">Médecins: {data.nbrdoctors} / {data.objectif_doctors}</span>
                      <span className="text-muted mt-2 mb-2 block-bottom">Pharmaciens: {data.nbrpharmacy} / {data.objectif_pharmacy}</span>
                    </div>
                  </Col>
                  <Col xs="4" className="b-r-light">
                    <div>
                      <h4 className="num m-0"><span className="counter color-bottom">Par potentiel</span></h4>
                      <span className="text-muted mt-2 mb-2 block-bottom">A: {data.nbrpotentiala}</span>
                      <span className="text-muted mt-2 mb-2 block-bottom">B: {data.nbrpotentialb}</span>
                      <span className="text-muted mt-2 mb-2 block-bottom">C: {data.nbrpotentialc}</span>
                    </div>
                  </Col>
                  <Col xs="4" className="b-r-light">
                    <div>
                      <h4 className="num m-0"><span className="counter color-bottom">Ratio</span></h4>
                      <span className="text-muted mt-2 mb-2 block-bottom">Ratio A: {data.roa}</span>
                      <span className="text-muted mt-2 mb-2 block-bottom">Ratio B: {data.rob}</span>
                      <span className="text-muted mt-2 mb-2 block-bottom">Ratio C: {data.roc}</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Card>
        </Col>
        <Col md="12" className="">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12" className='o-auto'>
                  <h5>Rapports</h5>
                  <br />
                  <Table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Contact</th>
                        <th>Specialité</th>
                        <th>Potentiel</th>
                        <th>Secteur</th>
                        <th>Ville</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      {data.reports.map((report) => {
                        const diffrence = (moment(report.report_end_time).diff(moment(report.report_start_time), "minutes"))
                        return (
                          <tr key={data.reports.indexOf(report)} style={{ backgroundColor: (diffrence < 5 || Number.isNaN(diffrence)) ? "rgb(242, 255, 102,0.3)" : "" }}>
                            <th scope="row">{data.reports.indexOf(report) + 1}</th>
                            <td>{moment(report.report_done_on).format("DD/MM/yyyy")}</td>
                            <td >
                              <Link to={{ pathname: `${process.env.PUBLIC_URL}/contacts/contact:${report.contact_id}` }}>
                                {report.contact_lastname + ' ' + report.contact_firstname}
                              </Link>
                            </td>
                            <td>{report.speciality}</td>
                            <td>{report.contact_potential[userNetwork] ?? report.contact_potential["Visite Pharma"]}</td>
                            <td>{report.sector}</td>
                            <td>{report.wilaya + ' ' + report.city}</td>
                            <td style={{ textAlign: "center" }}>
                              <Link to={{
                                pathname: `${process.env.PUBLIC_URL}/visites/rapports/detailed`,
                                state: {
                                  reportID: report.report_id,
                                  contact_lastname: report.contact_lastname,
                                  contact_firstname: report.contact_firstname,
                                  contact_speciality: report.speciality,
                                  contact_address: report.adress,
                                  contact_wilaya: report.wilaya,
                                  contact_prescription_possibility: report.prescription_possibility,
                                  contact_potentiel: report.contact_potential[userNetwork] ?? report.contact_potential["Visite Pharma"],
                                  agent_lastname: userLastname,
                                  agent_firstname: userFirstname,
                                  agent_network: userNetwork,
                                  contact_id: report.contact_id,
                                  agent_id: userID

                                }
                              }}>
                                <i className="fa fa-table" id="TooltipExample"></i>
                              </Link>
                            </td>
                          </tr>
                        )
                      }

                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>



              {/* <div className="chart-container">
                <div id="progress1">
                  <Chart options={progress1.options} series={progress1.series} height="70" width="100%" type="bar" />
                </div>
              </div> */}
            </CardBody>
          </Card>
        </Col>

      </Container>

    </Fragment>
  );
}

export default DetailedDashboard;
