import React, { Fragment, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col, Table, Card, CardBody} from 'reactstrap';
import { useLocation, Link } from 'react-router-dom';
import Breadcrumbs from '../../layout/breadcrumb';
import moment from 'moment';
import { toast } from 'react-toastify';

export default function SynthesesDetails() {
  const [loading, setloading] = useState(false);
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const location = useLocation();
  const synthese = location.state && location.state.syn;
 
  console.log(synthese )

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Visites" title="Rapports" hide={false} />
      {
        location.state &&
      <Container fluid={true} className='pb-4 mb-4'>
       
        <Row >
          <Col sm={5}>

            <Card>
              <CardBody>
                <Table>
                  <tbody>
                    <tr>
                        <th>Date</th>
                        <td>{moment(synthese.created_at).format("YYYY/MM/dd h:m")}</td>
                    </tr>
                    <tr>
                      <th>Visiteur</th>
                      <td>
                        <Link to={`${process.env.PUBLIC_URL}/users/user:${synthese.user_id}`}>
                          {`${synthese.user_lastname} ${synthese.user_firstname}`}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th>Secteur</th>
                      <td>{synthese.secteur.join(", ")}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col sm={7}>
            <Card>
              <CardBody>
                <Table>
                  <tbody>
                    <tr>
                      <th>Remarque</th>
                      <td>{synthese.remarque}</td>
                    </tr>
                    <tr>
                      <th>Objections</th>
                      <td>{synthese.objections}</td>
                    </tr>
                    <tr>
                      <th>Requetes Medecins</th>
                      <td>{synthese.requetes_medecins}</td>
                    </tr>
                    <tr>
                      <th>Materiel Professionnel</th>
                      <td>{synthese.materiels_profissionnels}</td>
                    </tr>
                    <tr>
                      <th>Concurrents</th>
                      <td>{synthese.concurrent}</td>
                    </tr>
                    <tr>
                      <th>Autres</th>
                      <td>{synthese.other}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h5>Liste des produits</h5>
            <Card>
              <CardBody style={{ overflow: "auto" }}>
                <Table>
                  <thead>
                    <tr>
                      <th>Produit</th>
                      <th>Commentaire</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        synthese.list_produit.map((prod)=>{
                            return <tr>
                                <td>{prod.produit}</td>
                                <td>{prod.commentaire}</td>
                            </tr>
                        })
                    }
                    
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <h5>Pharmacovigilance</h5>
            <Card>
              <CardBody style={{ overflow: "auto" }}>
                <Table>
                  <thead>
                    <tr>
                      <th>Produit</th>
                      <th>Commentaire</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        synthese.pharmacovigilance.map((prod)=>{
                            return <tr>
                                <td>{prod.produit}</td>
                                <td>{prod.commentaire}</td>
                            </tr>
                        })
                    }
                    
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          
          <Col md={6}>
            <h5>Experience Produit</h5>
            <Card>
              <CardBody style={{ overflow: "auto" }}>
                <Table>
                  <thead>
                    <tr>
                      <th>Produit</th>
                      <th>Commentaire</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        synthese.experiance_produit.map((prod)=>{
                            return <tr>
                                <td>{prod.produit}</td>
                                <td>{prod.commentaire}</td>
                            </tr>
                        })
                    }
                    
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <h5>Produits Concurrents</h5>
            <Card>
              <CardBody style={{ overflow: "auto" }}>
                <Table>
                  <thead>
                    <tr>
                      <th>Produit</th>
                      <th>Commentaire</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        synthese.produit_concurrent.map((prod)=>{
                            return <tr>
                                <td>{prod.produit}</td>
                                <td>{prod.commentaire}</td>
                            </tr>
                        })
                    }
                    
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <h5>Nouveau Produit</h5>
            <Card>
              <CardBody style={{ overflow: "auto" }}>
                <Table>
                  <thead>
                    <tr>
                      <th>Produit</th>
                      <th>Commentaire</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        synthese.nouveau_produit.map((prod)=>{
                            return <tr>
                                <td>{prod.produit}</td>
                                <td>{prod.commentaire}</td>
                            </tr>
                        })
                    }
                    
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      
      </Container>
      }
    </Fragment >
  )
}