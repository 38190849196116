import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import wilayas from '../contacts/auxiliary/wilayas.json';
import Select from 'react-select';

function WilayaOptions() {
  let optionsArray = []
  wilayas.map((wilaya) => {
    optionsArray.push({ value: wilaya.nom, label: wilaya.nom });
  })
  optionsArray.shift();
  return optionsArray;
}


export default function AddUser() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [formError, setFormError] = useState({
    user_lastname: "",
    user_password: "",
    user_firstname: "",
    user_email: "",
    user_network: "",
    user_wilaya: "",
    user_phone: "",
    user_supervisors: "",
    user_type: "",
    user_can_sale: "",
    user_free_tour: "",
    user_edit_pf: "",
    user_traking: ""
  });
  const [filterParams, setFilterParams] = useState({
    user_lastname: "",
    user_password: "",
    user_firstname: "",
    user_email: "",
    user_network: "",
    user_wilaya: "",
    user_phone: "",
    user_supervisors: "",
    user_type: "",
    user_can_sale: "",
    user_free_tour: "",
    user_edit_pf: "",
    user_traking: "",
  });

  const formValidation = async () => {
    for (var property in formError) {
      if (filterParams[property] === null || filterParams[property] === "") {
        formError[property] = "Champ obligatoire!";
        let obj = { ...formError };
        setFormError(obj);
      } else {
        formError[property] = "";
        let obj = { ...formError };
        setFormError(obj);
      }
    }
    await addUser();
  }

  // fetch the list of supervisors
  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      setLoading(true);
      const request = {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ user_network: "", user_name: "" })
      }
      try {
        var responce = await fetch("https://lsreport.shls.care/api/users/all", request, { mode: "cors" }, { signal: abortController.signal });
        if (responce.status === 200) {
          let data = await responce.json();
          setUsersData(data);
        } else {
          toast.error(await responce.json());
        }
      } catch (err) {
        setLoading(false);
        toast.error(err.message);
      }
      setLoading(false);
    }

    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  function SupervisorOptions() {
    let optionsArray = []
    usersData.map((supervisor) => {
      optionsArray.push({ value: supervisor.user_id, label: supervisor.user_lastname+" "+supervisor.user_firstname });
    })
    optionsArray.shift();
    return optionsArray;
  }
  const addUser = async () => {
    window.scrollTo(0, 0);
    for (var property in formError) {
      if (formError[property]) {
        return;
      }
    }
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        user_lastname: filterParams.user_lastname,
        user_firstname: filterParams.user_firstname,
        user_password: filterParams.user_password,
        user_email: filterParams.user_email,
        user_network: filterParams.user_network,
        user_wilaya: filterParams.user_wilaya,
        user_phone: filterParams.user_phone,
        user_supervisors: filterParams.user_supervisors,
        user_type: filterParams.user_type,
        user_can_sale: filterParams.user_can_sale,
        user_free_tour: filterParams.user_free_tour,
        user_edit_pf: filterParams.user_edit_pf,
        user_traking: filterParams.user_traking,
      })
    }
    try {
      setLoading(true);
      const responce = await fetch("https://lsreport.shls.care/api/users/new", request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success("Utilisateur ajouter avec success");
        setLoading(false);
        history.push(`${process.env.PUBLIC_URL}/users`);
      } else {
        toast.error(await responce.json());
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  }

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Utilisateurs" title="Ajouter utilisateur" />
      <Container fluid={true}  className='pb-4 mb-4'>
        <p>
          <em className='mb-3'>
            * Champ obligatoire
          </em>
        </p>
        <Row>
          <form
            id='addUserForm'
            onSubmit={(e) => { e.preventDefault() }}
          >
          </form>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="">Nom *</label>
              <input
                form='addUserForm'
                type="text"
                placeholder='Nom'
                value={filterParams.user_lastname}
                onChange={(e) => { setFilterParams({ ...filterParams, user_lastname: e.target.value }) }} />
            </div>
            <span style={{ color: "red" }}>{filterParams.user_lastname ? "" : formError.user_lastname}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="">Prénom *</label>
              <input
                form='addUserForm'
                type="text"
                placeholder='Prénom'
                name='firstname'
                value={filterParams.user_firstname}
                onChange={(e) => { setFilterParams({ ...filterParams, user_firstname: e.target.value }) }} />
            </div>
            <span style={{ color: "red" }}>{filterParams.user_firstname ? "" : formError.user_firstname}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="">Mot de passe *</label>
              <input
                form='addUserForm'
                type="password"
                placeholder='Mot de passe'
                value={filterParams.user_password}
                onChange={(e) => { setFilterParams({ ...filterParams, user_password: e.target.value }) }} />
            </div>
            <span style={{ color: "red" }}>{filterParams.user_password ? "" : formError.user_password}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="">Téléphone</label>
              <input
                form='addUserForm'
                type="tel"
                placeholder='Téléphone'
                value={filterParams.user_phone}
                onChange={(e) => { setFilterParams({ ...filterParams, user_phone: e.target.value }) }} />
            </div>
            <span style={{ color: "red" }}>{filterParams.user_phone ? "" : formError.user_phone}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="">Email</label>
              <input
                form='addUserForm'
                type="email"
                placeholder='Email'
                value={filterParams.user_email}
                onChange={(e) => { setFilterParams({ ...filterParams, user_email: e.target.value }) }} />
            </div>
            <span style={{ color: "red" }}>{filterParams.user_email ? "" : formError.user_email}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="wilayas">Zone *</label>
             
              <Select
                isMulti
                placeholder="Wilaya"
                options={WilayaOptions()}
                onChange={(e) => {
                  setFilterParams({
                    ...filterParams,
                    user_wilaya: (e.map(obj => { return obj.value }))
                  })
                }}
              // onChange={(e) => { setFilterParams({ ...filterParams, wilaya: e.target.value }) }}
              />
            </div>
            <span style={{ color: "red" }}>{filterParams.user_wilaya ? "" : formError.user_wilaya}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="network">Type utilisateur *</label>
              <select
                value={filterParams.user_type}
                form='addUserForm'
                name="network"
                onChange={(e) => { setFilterParams({ ...filterParams, user_type: e.target.value }) }}>
                <option value="" disabled>Type utilisateur</option>
                <option value="admin">Administrateur</option>
                <option value="manager">Chéf réseau</option>
                <option value="supervisor">Superviseur</option>
                <option value="agent">Délégué</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.user_type ? "" : formError.user_type}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="network"><span>Réseau *</span></label>
              <select
                name="network"
                value={filterParams.user_network}
                onChange={(e) => { setFilterParams({ ...filterParams, user_network: e.target.value }) }}>
                <option value="" disabled>Réseau</option>
                <option value="Antidouleur">Antidouleur</option>
                <option value="Diabète">Diabète</option>
                <option value="Gynéco">Gynéco</option>
                <option value="Neuropsychiatrie /HGE">Neuropsychiatrie /HGE</option>
                <option value="Visite Pharma">Visite Pharma</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.user_network ? "" : formError.user_network}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="kol">Tournée libre*</label>
              <select
                name="kol"
                value={filterParams.user_free_tour}
                onChange={(e) => { setFilterParams({ ...filterParams, user_free_tour: e.target.value }) }}
              >
                <option value="" disabled>Tournée libre</option>
                <option value={true}>Oui</option>
                <option value={false}>Non</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.user_free_tour ? "" : formError.user_free_tour}</span>
          </Col>

          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="kol">Tracking GPS</label>
              <select
                name="kol"
                value={filterParams.user_traking}
                onChange={(e) => { setFilterParams({ ...filterParams, user_traking: e.target.value }) }}
              >
                <option value="" disabled>Tracking GPS</option>
                <option value={true}>Oui</option>
                <option value={false}>Non</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.user_traking ? "" : formError.user_traking}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="kol">Vendre</label>
              <select
                name="kol"
                value={filterParams.user_can_sale}
                onChange={(e) => { setFilterParams({ ...filterParams, user_can_sale: e.target.value }) }}
              >
                <option value="" disabled>Vendre</option>
                <option value={true}>Oui</option>
                <option value={false}>Non</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.user_can_sale ? "" : formError.user_can_sale}</span>
          </Col>
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="kol">Modifier Portefeuille</label>
              <select
                name="kol"
                value={filterParams.user_edit_pf}
                onChange={(e) => { setFilterParams({ ...filterParams, user_edit_pf: e.target.value }) }}
              >
                <option value="" disabled>Modifier</option>
                <option value={true}>Oui</option>
                <option value={false}>Non</option>
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.user_edit_pf ? "" : formError.user_edit_pf}</span>
          </Col>
          {/* <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="supervisor">Superviseur *</label>
              <select
                form='addUserForm'
                name="supervisor"
                defaultValue={filterParams.user_supervisors}
                onChange={(e) => { setFilterParams({ ...filterParams, user_supervisors: e.target.value }) }}>
                <option value="" disabled>Superviseur</option>
                {usersData.map(user => {
                  if (user.user_type != "agent") {
                    return (
                      <option key={usersData.indexOf(user)} value={user.user_id}>{user.user_lastname} {user.user_firstname}</option>
                    )
                  }
                })}
              </select>
            </div>
            <span style={{ color: "red" }}>{filterParams.user_supervisors ? "" : formError.user_supervisors}</span>
          </Col> */}
          <Col md="4 mb-3">
            <div className="addcontact-inputgroup">
              <label htmlFor="wilayas">Superviseurs *</label>
             
              <Select
                isMulti
                placeholder="Superviseurs"
                options={SupervisorOptions()}
                onChange={(e) => {
                  setFilterParams({
                    ...filterParams,
                    user_supervisors: (e.map(obj => { return obj.value }))
                  })
                }}
              />
            </div>
            <span style={{ color: "red" }}>{filterParams.user_supervisors ? "" : formError.user_supervisors}</span>
          </Col>
        </Row>
        <Row className='pb-4'>
          <Col md={12} className='justify-content-center mt-3 pb-4' style={{ display: "flex" }}>
            <Button className='mb-4' form='addUserForm' type='submit' onClick={formValidation}>Ajouter</Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
