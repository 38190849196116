import { Home, Settings, File, GitBranch, Book, TrendingUp, Users, Package,FileText,CreditCard, ShoppingBag, Archive,Clipboard } from 'react-feather'
export const MENUITEMS = [
  {
    menutitle: "",
    menucontent: "",
    Items: [
      { path: `${process.env.PUBLIC_URL}/dashboard/default`, icon: Home, title: 'Tableau de bord', active: false, type: 'link' },
      { path: `${process.env.PUBLIC_URL}/visites/rapports`, icon: File, title: 'Rapports Visites', active: false, type: 'link' },
      { path: `${process.env.PUBLIC_URL}/focus/rapports`, icon: FileText, title: 'Rapports Focus', active: false, type: 'link' },
    
      {
        title: 'Tournée', icon: GitBranch, type: 'sub', active: false, children: [
          { path: `${process.env.PUBLIC_URL}/tournee/valides`, title: 'Validés', type: 'link' },
          { path: `${process.env.PUBLIC_URL}/tournee/enattente`, title: 'En attente', type: 'link' },
        ]
      },
      { path: `${process.env.PUBLIC_URL}/bdc`, icon: CreditCard, title: 'Bons de commandes', active: false, type: 'link' },
      { path: `${process.env.PUBLIC_URL}/grossiste/list`, icon: ShoppingBag, title: "Grosistes", active: false, type: 'link' },
      { path: `${process.env.PUBLIC_URL}/contacts`, icon: Book, title: 'Contacts', active: false, type: 'link' },
      { path: `${process.env.PUBLIC_URL}/users`, icon: Users, title: 'Utilisateurs', active: false, type: 'link' },
      { path: `${process.env.PUBLIC_URL}/visitesDuo/rapports`, icon: Archive, title: 'Fiches DUO', active: false, type: 'link' },
      { path: `${process.env.PUBLIC_URL}/syntheses/liste`, icon: Clipboard, title: 'Synthèses', active: false, type: 'link' },
      { path: `${process.env.PUBLIC_URL}/products`, icon: Package, title: 'Produits', active: false, type: 'link' },
      {
        title: 'Statistiques', icon: TrendingUp, type: 'sub', active: false, children: [
          { path: `${process.env.PUBLIC_URL}/stats/agents`, title: 'Statistiques visiteurs', type: 'link' },
          { path: `${process.env.PUBLIC_URL}/stats/products`, title: 'Staitistiques produits', type: 'link' },
        ]
      },
      { path: `${process.env.PUBLIC_URL}/settings`, icon: Settings, title: 'Paramétres', active: false, type: 'link' },
    ]
  },
]