import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, FormGroup, Input, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown, Button } from 'reactstrap'
import Loader from "../../layout/loader";
import { Link } from 'react-router-dom';
import Chart from 'react-apexcharts'
import { toast } from 'react-toastify';
import jwt from 'jsonwebtoken';
import DatePicker from "react-datepicker";

const Default = () => {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const [agentsData, setAgentsData] = useState([]);
  const [visit, setVisit] = useState({});
  const [tour, setTour] = useState({ progress: 0 });
  const [isLoading, setIsloading] = useState(false);

  const [selectedteam, setTeam] = useState(token.length > 0 ? jwt.decode(token).network : "");
  const role = token.length > 0 ? jwt.decode(token).type : "";
  const [dateDebut, setDateDebut] = useState(new Date());
  const [dateFin, setDateFin] = useState(new Date());

  const [visitSeries, setVisitSeries] = useState([]);
  const visitPieChart = {

    series: visitSeries,
    options: {
      chart: {
        width: 450,
        type: 'pie',
      },
      labels: ["Médecins", "Pharmaciens"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  }
  const [potentielSeries, setPotentielSeries] = useState([]);
  const potentielPieChart = {

    series: potentielSeries,
    options: {
      chart: {
        width: 450,
        type: 'pie',
      },
      labels: ["A", "B", "C"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  }

  const [tourSeries, setTourSeries] = useState([]);

  const tourPieChart = {
    series: tourSeries,
    options: {
      chart: {
        width: 450,
        type: 'pie',
      },
      labels: ["En cours", "Terminée"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  }

  function progressOptions(agent) {
    return {
      options: {
        chart: {
          width: 990,
          height: 70,
          type: "bar",
          stacked: true,
          sparkline: {
            enabled: true
          }
        },
        stroke: {
          width: 0,
        },
        fill: {
          colors: agent.progress > 85 ? ["#00B187"] : agent.progress > 60 ? ["#FF8162"] : ["#FF4149"],
          type: 'gradient',
          gradient: {
            gradientToColors: agent.progress > 85 ? ["#00B187"] : agent.progress > 60 ? ["#FF8162"] : ["#FF4149"]
          }
        },
        legend: {
          show: true
        },
        colors: ["#158df7"],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '20%',
            colors: {
              backgroundBarColors: ['#f2f2f2']
            }
          },
        },
        title: {
          floating: true,
          offsetX: -10,
          offsetY: 5,
          text: (agent.user_lastname + " " + agent.user_firstname)
        },
        subtitle: {
          floating: true,
          align: 'right',
          offsetY: 0,
          text: agent.progress.toFixed(2).toString() + "%",
          style: {
            fontSize: '16px',
            fontWeight: 800
          }
        },
        tooltip: {
          enabled: false
        },
        xaxis: {
          categories: ['Process 1'],
        },
        yaxis: {
          max: 100
        },
      },
      series: [{
        name: 'Process 1',
        data: [agent.progress]
      }],
      labels: ['Device 1', 'Device 2']
    }
  }
  function teamOptions(tour) {
    return {
      options: {
        chart: {
          width: 990,
          height: 70,
          type: "bar",
          stacked: true,
          sparkline: {
            enabled: true
          }
        },
        stroke: {
          width: 0,
        },
        fill: {
          colors: tour.progress > 85 ? ["#00B187"] : tour.progress > 60 ? ["#FF8162"] : ["#FF4149"],
          type: 'gradient',
          gradient: {
            gradientToColors: tour.progress > 85 ? ["#00B187"] : tour.progress > 60 ? ["#FF8162"] : ["#FF4149"]
          }
        },
        legend: {
          show: true
        },
        colors: ["#158df7"],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '20%',
            colors: {
              backgroundBarColors: ['#f2f2f2']
            }
          },
        },
        title: {
          floating: true,
          offsetX: -10,
          offsetY: 5,
          text: ""
        },
        subtitle: {
          floating: true,
          align: 'right',
          offsetY: 0,
          text: tour.progress.toFixed(2).toString() + "%",
          style: {
            fontSize: '16px'
          }
        },
        tooltip: {
          enabled: false
        },
        xaxis: {
          categories: ['Process 1'],
        },
        yaxis: {
          max: 100
        },
      },
      series: [{
        name: 'Process 1',
        data: [tour.progress]
      }],
      labels: ['Device 1', 'Device 2']
    }
  }

  // API call function
  const fetchData = async (team, dateDebut, dateFin) => {
    setIsloading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ start_date: dateDebut, end_date: dateFin, team: team })
    }

    try {
      var responce = await fetch("https://lsreport.shls.care/api/stats/webdashboardstats", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();

        let visitSeries = [], potentielSeries = [], tourSeries = []
        visitSeries.push(data.visits.doctor)
        visitSeries.push(data.visits.pharmacy)
        setVisitSeries(visitSeries)
        potentielSeries.push(data.visits.a)
        potentielSeries.push(data.visits.b)
        potentielSeries.push(data.visits.c)
        setPotentielSeries(potentielSeries)
        tourSeries.push(data.tours.pending)
        tourSeries.push(data.tours.completed)
        setTourSeries(tourSeries)
        setAgentsData(data.agents);
        setVisit(data.visits);
        setTour(data.tours);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setIsloading(false);
      toast.error(err.message);
    }
    setIsloading(false);
  }
  useEffect(() => {
    if (token.length > 0) {
      fetchData(selectedteam, dateDebut, dateFin);
    }
    window.scrollTo(0, 0);
  }, [])

  return (
    <Fragment>
      <Breadcrumb title="Tableau de bord" hide={true} />
      <Loader isLoading={isLoading} />

      <Container fluid={true}>
       
            <Row className='d-flex justify-content-between'>
              <Col md={3} >
                {role === "admin" ?
                  <UncontrolledDropdown className=' m-3'>
                    <DropdownToggle color='dark' caret className="b-r-0 ourBtn">
                      {selectedteam}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => setTeam("Antidouleur")}>Antidouleur</DropdownItem>
                      <DropdownItem onClick={() => setTeam("Gynéco")}>Gynéco</DropdownItem>
                      <DropdownItem onClick={() => setTeam("Neuropsychiatrie /HGE")}>Neuropsychiatrie /HGE</DropdownItem>
                      <DropdownItem onClick={() => setTeam("Visite Pharma")}>Visite Pharma</DropdownItem>
                      <DropdownItem onClick={() => setTeam("Diabète")}>Diabète</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> : ''}
              </Col>

              <Col md={3}>

                <span>
                  Du:
                </span>
                <FormGroup className='d-inline-block my-3'>
                  <DatePicker todayButton={<Button>Aujourd'hui</Button>} dateFormat={"dd/MM/yyyy"} selected={dateDebut} maxDate={new Date()} onChange={(date) => setDateDebut(date)} />


                </FormGroup>
              </Col>
              <Col md={3}>
                <span>
                  Au:
                </span>
                <FormGroup className=' d-inline-block my-3'>
                  <DatePicker className='d-block' todayButton={<Button>Aujourd'hui</Button>} dateFormat={"dd/MM/yyyy"} selected={dateFin} maxDate={new Date()} onChange={(date) => setDateFin(date)} />

                </FormGroup>
              </Col>
              <Col md={3}>
                <Button onClick={() => fetchData(selectedteam, dateDebut, dateFin)} color='primary' className="b-r-0 m-3 ourBtn d-block">Rechercher</Button>
              </Col>
            </Row>

      </Container>

      {Object.keys(visit).length !== 0 &&

        <Container fluid={true}>
          <Row>
            <Col md="12" className="mb-5">
              <Card>
                <CardBody>
                  <h3>Détails équipe</h3>
                  <Chart options={teamOptions(tour).options} series={teamOptions(tour).series} height="70" width="100%" type="bar" />
                  <div className="bar-chart-widget">
                    <div className="top-content bg-primary"></div>
                    <div className="bottom-content card-body">
                      <Row className="text-center f-w-600">
                        <Col sm="4" className="b-r-light">

                          <h4 className="num m-0"><span className="counter color-bottom">Visites : {visit.total}</span></h4>
                          <Chart options={visitPieChart.options} series={visitPieChart.series} type="pie" />

                        </Col>
                        <Col sm="4" className="b-r-light">
                          <h4 className="num m-0"><span className="counter color-bottom">Par potentiel : {visit.a + visit.b + visit.c}</span></h4>
                          <Chart options={potentielPieChart.options} series={potentielPieChart.series} type="pie" />
                        </Col>
                        <Col sm="4" className="b-r-light">

                          <h4 className="num m-0"><span className="counter color-bottom">Tournée: {tour.total}</span></h4>
                          <Chart options={tourPieChart.options} series={tourPieChart.series} type="pie" />

                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card >
                <CardHeader>
                  <h5>Progrès par visiteur</h5>

                </CardHeader>
                <CardBody >

                  {agentsData.map((item) => {
                    return (
                      <Link key={agentsData.indexOf(item)} to={{
                        pathname: `${process.env.PUBLIC_URL}/dashboard/default/detailed`,
                        state: {
                          userID: item.user_id,
                          network: item.user_network,
                          userLastname: item.user_lastname,
                          userFirstname: item.user_firstname,
                          start_date:dateDebut,
                          end_date:dateFin
                        }
                      }}>

                        <div style={{ cursor: "pointer" }}>
                          <div className="chart-container">
                            <div >
                              <Chart options={progressOptions(item).options} series={progressOptions(item).series} height="70" width="100%" type="bar" />
                            </div>
                          </div>
                        </div>
                      </Link>


                    )
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      }

    </Fragment>
  );
}

export default Default;