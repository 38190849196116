import React, { Fragment, useState, useEffect} from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import wilayas from '../contacts/auxiliary/wilayas.json';
import Select from 'react-select';
import DatePicker from "react-datepicker"
import { useLocation } from 'react-router-dom';


export default function AddVisitDuo() {
    const location = useLocation();
    const token = document.cookie.split(`jwt=`).pop().split(';').shift();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [formError, setFormError] = useState({
        user_id: "",
        duo_date: new Date(),
        duo_products: "",
        duo_wilaya:"",
        duo_meds_pb:"",
        duo_meds_pv:"",
        duo_medg_pb:"",
        duo_medg_pv:"",
        duo_pharm:"",
    });

    const agent = {
        user_id: location.state == null ? "" : location.state.agent_id,
        lastname: location.state == null ? "" : location.state.agent_lastname,
        firstname: location.state == null ? "" : location.state.agent_firstname,
      }
    
    const [preparation, setPreparation]=useState({title:"Préparation  de la  visite",notation:"",commentaires:"",actions:"",delai:""})
    const [connaissance, setconnaissance]=useState({title:"Connaissance scientifique et l'environement",notation:"",commentaires:"",actions:"",delai:""})
    const [utilisation, setutilisation]=useState({title:"Utilisation de l'ADV et remis de visite",notation:"",commentaires:"",actions:"",delai:""})
    const [accroche, setaccroche]=useState({title:"L'accroche",notation:"",commentaires:"",actions:"",delai:""})
    const [questionnement, setquestionnement]=useState({title:"Le questionnement",notation:"",commentaires:"",actions:"",delai:""})
    const [argumentaire, setargumentaire]=useState({title:"Argumentaire",notation:"",commentaires:"",actions:"",delai:""})
    const [point_accord, setpoint_accord]=useState({title:"Points d'accords",notation:"",commentaires:"",actions:"",delai:""})
    const [respect_compagne, setrespect_compagne]=useState({title:"Respect Campagne marketing",notation:"",commentaires:"",actions:"",delai:""})
    const [ecoute, setecoute]=useState({title:"Ecoute",notation:"",commentaires:"",actions:"",delai:""})
    const [traitement_objections, settraitement_objections]=useState({title:"Traitemet des objections",notation:"",commentaires:"",actions:"",delai:""})
    const [engagement, setengagement]=useState({title:"Engagement",notation:"",commentaires:"",actions:"",delai:""})
    const [qualite_visite, setqualite_visite]=useState({title:"Qualité de la visite",notation:"",commentaires:"",actions:"",delai:""})
    const [enquete_pharmacies, setenquete_pharmacies]=useState({title:"Enquête pharmacies",notation:"",commentaires:"",actions:"",delai:""})
    const [ciblage_medecin, setciblage_medecin]=useState({title:"Ciblage Médecin",notation:"",commentaires:"",actions:"",delai:""})
    const total= (isNaN( parseInt(preparation.notation) )? 0:parseInt(preparation.notation))
    + (isNaN(parseInt(connaissance.notation)) ? 0: parseInt(connaissance.notation))
    + (isNaN(parseInt(utilisation.notation))? 0: parseInt(utilisation.notation))
    + (isNaN(parseInt(accroche.notation))? 0: parseInt(accroche.notation))
    +(isNaN(parseInt(questionnement.notation))? 0: parseInt(questionnement.notation))
    +(isNaN(parseInt(argumentaire.notation))? 0: parseInt(argumentaire.notation))
    +(isNaN(parseInt(point_accord.notation))? 0: parseInt(point_accord.notation))
    +(isNaN(parseInt(respect_compagne.notation))? 0: parseInt(respect_compagne.notation))
    + (isNaN(parseInt(ecoute.notation))? 0: parseInt(ecoute.notation))
    +(isNaN(parseInt(traitement_objections.notation))? 0:parseInt(traitement_objections.notation) )
    +(isNaN(parseInt(engagement.notation))? 0: parseInt(engagement.notation))
    + (isNaN(parseInt(qualite_visite.notation))? 0: parseInt(qualite_visite.notation))
    +(isNaN(parseInt(enquete_pharmacies.notation))? 0: parseInt(enquete_pharmacies.notation))
    +(isNaN(parseInt(ciblage_medecin.notation))? 0:parseInt(ciblage_medecin.notation))
    const [priorite_amelioration, setpriorite_amelioration]=useState("")
    const items={
        preparation,
        connaissance,
        utilisation,
        accroche,
        questionnement,
        argumentaire,
        point_accord,
        respect_compagne,
        ecoute,
        traitement_objections,
        engagement,
        qualite_visite,
        enquete_pharmacies,
        ciblage_medecin,
        total ,
        priorite_amelioration };
    function ProductOptions() {
        let productsArray = []
        products.map((product) => {
            productsArray.push(
                { value: product.product_id, label: product.product_name }
            );
        })
        productsArray.shift();
        return productsArray;
    }
    const [filterParams, setFilterParams] = useState({
        supervisor: "",
        user_id: agent.user_id,
        duo_date: new Date(),
        duo_products: "",
        duo_wilaya:"",
        duo_meds_pb:"",
        duo_meds_pv:"",
        duo_medg_pb:"",
        duo_medg_pv:"",
        duo_pharm:""
    });
    // const selectedSupervisor = supervisors.find(user => filterParams.supervisor === user.user_id);
    const formValidation = async () => {
        for (var property in formError) {
            if (filterParams[property] === null || filterParams[property] === "") {
                formError[property] = "Champ obligatoire!";
                let obj = { ...formError };
                setFormError(obj);
            } else {
                formError[property] = "";
                let obj = { ...formError };
                setFormError(obj);
            }
        }
        await addDuoFiche();
    }

    const getProducts = async () => {
        const request = {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
                'Authorization': 'Bearer ' + token
            }
        }
        try {
            var responce = await fetch("https://lsreport.shls.care/api/products", request, { mode: "cors" });
            if (responce.status === 200) {
                let data = await responce.json();
                setProducts(data);
            } else {
                toast.error(await responce.json());
            }
        } catch (err) {
            toast.error(err.message);
        }

    }

    // fetch the list of supervisors
    useEffect(() => {
        getProducts()
    }, []);

    const addDuoFiche = async () => {
        window.scrollTo(0, 0);
        for (var property in formError) {
            if (formError[property]) {
                return;
            }
        }
        const request = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(
                {...filterParams,
                items}
            )
        }
        
        try {
            setLoading(true);
            const responce = await fetch("https://lsreport.shls.care/api/users/user/duo/add", request, { mode: "cors" });
            if (responce.status === 200) {
                toast.success("Fiche ajoutée avec succes");
                setLoading(false);
                history.push({pathname: `${process.env.PUBLIC_URL}/visitesDuo/rapports`,
                state : {user:{user_id:location.state == null ? "" : location.state.agent_id,
                 user_lastname:location.state == null ? "" : location.state.agent_lastname,
                 user_firstname:location.state == null ? "" : location.state.agent_firstname},
                //  supervisor:{user_lastname:selectedSupervisor.user_lastname,
                //     user_firstname:selectedSupervisor.user_firstname},
                }
            });
            } else {
                toast.error(await responce.json());
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            toast.error(err.message);
        }
    }

    return (
        <Fragment>
            <Loader isLoading={loading} />
            <Breadcrumbs parent="Fiches Duo" title="Ajouter Fiche" />
            <Container fluid={true}  className='pb-4 mb-4'>
                <p>
                    <em className='mb-3'>
                        * Champ obligatoire
                    </em>
                </p>
                <Row>
                    <form
                        id='addDuoFicheForm'
                        onSubmit={(e) => { e.preventDefault() }}
                    >
                    </form>
                    <Col md="4 mb-3">
                        <div className="addcontact-inputgroup">
                            <label htmlFor="supervisor">Visiteur *</label>
                            <select
                                disabled
                                form='addDuoFicheForm'
                                name="supervisor"
                            >
                                { <option  value={agent.user_id}>{agent.lastname} {agent.firstname}</option>}
                            </select>
                        </div>
                        <span style={{ color: "red" }}>{filterParams.user_id ? "" : formError.user_id}</span>
                    </Col>
                    <Col md="4 mb-3">
                        <div className="addcontact-inputgroup">
                            <label htmlFor="secteur">Secteur visité *</label>
                            <select
                                form='addDuoFicheForm'
                                name="secteur"
                                defaultValue={filterParams.duo_wilaya}
                                onChange={(e) => { setFilterParams({ ...filterParams, duo_wilaya: e.target.value }) }}>
                                <option value="" disabled>Secteur</option>
                                {wilayas.map(wilaya => {
                                    
                                        return (
                                            <option key={wilayas.indexOf(wilaya)} value={wilaya.nom}>{wilaya.nom}</option>
                                        )
                                    
                                })}
                            </select>
                        </div>
                        <span style={{ color: "red" }}>{filterParams.duo_wilaya ? "" : formError.duo_wilaya}</span>
                    </Col>
                    
                    <Col md="4 mb-3">
                        <div className="addcontact-inputgroup">
                            <label htmlFor="">Date De Duo *</label>
                            <FormGroup >
                                <DatePicker form='addDuoFicheForm' todayButton={<Button>Aujourd'hui</Button>} dateFormat={"dd/MM/yyyy"} selected={filterParams.duo_date} maxDate={new Date()} 
                                onChange={(e) => { setFilterParams({ ...filterParams, duo_date: e.target.value }) }}/>
                            </FormGroup>
                        </div>
                        <span style={{ color: "red" }}>{filterParams.duo_date ? "" : formError.duo_date}</span>
                    </Col>
                    <Col md="12 mb-3">
                        <div className="addcontact-inputgroup ">
                            <label htmlFor="produits">Produits *</label>
                            <Select
                                isMulti
                                className='d-block'
                                placeholder="Produits"
                                options={ProductOptions()}
                                onChange={(e) => {
                                    setFilterParams({
                                        ...filterParams,
                                        duo_products: (e.map(obj => { return obj.value }))
                                    })
                                }}
                            />
                        </div>
                        <span style={{ color: "red" }}>{filterParams.duo_products ? "" : formError.duo_products}</span>
                    </Col>
                    

                    <Col md="4 mb-3">
                        <div className="addcontact-inputgroup">
                            <label htmlFor="">Nbr Médecins Spécialistes PB: *</label>
                            <input
                            min={0}
                                form='addDuoFicheForm'
                                type="number"
                                placeholder='Nbr Médecins Spécialistes PB'
                                value={filterParams.duo_meds_pb}
                                onChange={(e) => { setFilterParams({ ...filterParams, duo_meds_pb: e.target.value }) }} />
                        </div>
                        <span style={{ color: "red" }}>{filterParams.duo_meds_pb ? "" : formError.duo_meds_pb}</span>
                    </Col>
                    <Col md="4 mb-3">
                        <div className="addcontact-inputgroup">
                            <label htmlFor="">Nbr Médecins Spécialistes PV: *</label>
                            <input
                            min={0}
                                form='addDuoFicheForm'
                                type="number"
                                placeholder='Nbr Médecins Spécialistes PV'
                                value={filterParams.duo_meds_pv}
                                onChange={(e) => { setFilterParams({ ...filterParams, duo_meds_pv: e.target.value }) }} />
                        </div>
                        <span style={{ color: "red" }}>{filterParams.duo_meds_pv ? "" : formError.duo_meds_pv}</span>
                    </Col>

                    <Col md="4 mb-3">
                        <div className="addcontact-inputgroup">
                            <label htmlFor="">Nbr Médecins Généralistes PB: *</label>
                            <input
                            min={0}
                                form='addDuoFicheForm'
                                type="number"
                                placeholder='Nbr Médecins Généralistes PB'
                                value={filterParams.duo_medg_pb}
                                onChange={(e) => { setFilterParams({ ...filterParams, duo_medg_pb: e.target.value }) }} />
                        </div>
                        <span style={{ color: "red" }}>{filterParams.duo_medg_pb ? "" : formError.duo_medg_pb}</span>
                    </Col>
                    <Col md="4 mb-3">
                        <div className="addcontact-inputgroup">
                            <label htmlFor="">Nbr Médecins Généralistes PV: *</label>
                            <input
                            min={0}
                                form='addDuoFicheForm'
                                type="number"
                                placeholder='Nbr Médecins Généralistes PV'
                                value={filterParams.duo_medg_pv}
                                onChange={(e) => { setFilterParams({ ...filterParams, duo_medg_pv: e.target.value }) }} />
                        </div>
                        <span style={{ color: "red" }}>{filterParams.duo_medg_pv ? "" : formError.duo_medg_pv}</span>
                    </Col>
                    <Col md="4 mb-3">
                        <div className="addcontact-inputgroup">
                            <label htmlFor="">Nbr Pharma visités: *</label>
                            <input
                            min={0}
                                form='addDuoFicheForm'
                                type="number"
                                placeholder='Nbr Pharmaciens Visités'
                                value={filterParams.duo_pharm}
                                onChange={(e) => { setFilterParams({ ...filterParams, duo_pharm: e.target.value }) }} />
                        </div>
                        <span style={{ color: "red" }}>{filterParams.duo_pharm ? "" : formError.duo_pharm}</span>
                    </Col>
                    <Col  md="12 mb-3">
                    <table className='table table-bordered '>
                        <thead className='bg-primary'>
                            <tr >
                                <th className='text-center text-light'>Items</th>
                                <th className='text-center text-light'>Notation:<br/> 1-2-3-4-5</th>
                                <th className='text-center text-light'>Commentaires Responsable</th>
                                <th className='text-center text-light'>Pistes d'amélioration / actions concrètes à mettre en oeuvre </th>
                                <th className='text-center text-light'>Délai</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Préparation  de la  visite</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={preparation.notation}
                                        onChange={(e) => { setPreparation({ ...preparation, notation : e.target.value });}} 
                                        />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={preparation.commentaires} 
                                    onChange={(e) => { setPreparation({ ...preparation, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={preparation.actions}
                                        onChange={(e) => { setPreparation({ ...preparation, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={preparation.delai}
                                        onChange={(e) => { setPreparation({ ...preparation, delai : e.target.value }) }} />
                                </td>                                
                            </tr>

                            <tr>
                                <td>Connaissance scientifique et l'environement</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={connaissance.notation}
                                        onChange={(e) => { setconnaissance({ ...connaissance, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={connaissance.commentaires} 
                                    onChange={(e) => { setconnaissance({ ...connaissance, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={connaissance.actions}
                                        onChange={(e) => { setconnaissance({ ...connaissance, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={connaissance.delai}
                                        onChange={(e) => { setconnaissance({ ...connaissance, delai : e.target.value }) }} />
                                </td>                                
                            </tr>

                            <tr>
                                <td>Utilisation de l'ADV et remis de visite</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={utilisation.notation}
                                        onChange={(e) => { setutilisation({ ...utilisation, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={utilisation.commentaires} 
                                    onChange={(e) => { setutilisation({ ...utilisation, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={utilisation.actions}
                                        onChange={(e) => { setutilisation({ ...utilisation, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={utilisation.delai}
                                        onChange={(e) => { setutilisation({ ...utilisation, delai : e.target.value }) }} />
                                </td>                                
                            </tr>
                            
                            <tr>
                                <td>L'accroche</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={accroche.notation}
                                        onChange={(e) => { setaccroche({ ...accroche, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={accroche.commentaires} 
                                    onChange={(e) => { setaccroche({ ...accroche, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={accroche.actions}
                                        onChange={(e) => { setaccroche({ ...accroche, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={accroche.delai}
                                        onChange={(e) => { setaccroche({ ...accroche, delai : e.target.value }) }} />
                                </td>                                
                            </tr>
                            
                            <tr>
                                <td>Le questionnement </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={questionnement.notation}
                                        onChange={(e) => { setquestionnement({ ...questionnement, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={questionnement.commentaires} 
                                    onChange={(e) => { setquestionnement({ ...questionnement, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={questionnement.actions}
                                        onChange={(e) => { setquestionnement({ ...questionnement, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={questionnement.delai}
                                        onChange={(e) => { setquestionnement({ ...questionnement, delai : e.target.value }) }} />
                                </td>                                
                            </tr>
                            
                            <tr>
                                <td>Argumentaire</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={argumentaire.notation}
                                        onChange={(e) => { setargumentaire({ ...argumentaire, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={argumentaire.commentaires} 
                                    onChange={(e) => { setargumentaire({ ...argumentaire, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={argumentaire.actions}
                                        onChange={(e) => { setargumentaire({ ...argumentaire, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={argumentaire.delai}
                                        onChange={(e) => { setargumentaire({ ...argumentaire, delai : e.target.value }) }} />
                                </td>                                
                            </tr>
                            
                            <tr>
                                <td>Points d'accords</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={point_accord.notation}
                                        onChange={(e) => { setpoint_accord({ ...point_accord, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={point_accord.commentaires} 
                                    onChange={(e) => { setpoint_accord({ ...point_accord, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={point_accord.actions}
                                        onChange={(e) => { setpoint_accord({ ...point_accord, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={point_accord.delai}
                                        onChange={(e) => { setpoint_accord({ ...point_accord, delai : e.target.value }) }} />
                                </td>                                
                            </tr>
                            
                            <tr>
                                <td>Respect Campagne marketing</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={respect_compagne.notation}
                                        onChange={(e) => { setrespect_compagne({ ...respect_compagne, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={respect_compagne.commentaires} 
                                    onChange={(e) => { setrespect_compagne({ ...respect_compagne, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={respect_compagne.actions}
                                        onChange={(e) => { setrespect_compagne({ ...respect_compagne, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={respect_compagne.delai}
                                        onChange={(e) => { setrespect_compagne({ ...respect_compagne, delai : e.target.value }) }} />
                                </td>                                
                            </tr>
                            
                            <tr>
                                <td>Ecoute</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={ecoute.notation}
                                        onChange={(e) => { setecoute({ ...ecoute, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={ecoute.commentaires} 
                                    onChange={(e) => { setecoute({ ...ecoute, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={ecoute.actions}
                                        onChange={(e) => { setecoute({ ...ecoute, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={ecoute.delai}
                                        onChange={(e) => { setecoute({ ...ecoute, delai : e.target.value }) }} />
                                </td>                                
                            </tr>
                            
                            <tr>
                                <td>Traitemet des objections</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={traitement_objections.notation}
                                        onChange={(e) => { settraitement_objections({ ...traitement_objections, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={traitement_objections.commentaires} 
                                    onChange={(e) => { settraitement_objections({ ...traitement_objections, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={traitement_objections.actions}
                                        onChange={(e) => { settraitement_objections({ ...traitement_objections, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={traitement_objections.delai}
                                        onChange={(e) => { settraitement_objections({ ...traitement_objections, delai : e.target.value }) }} />
                                </td>                                
                            </tr>
                            
                            <tr>
                                <td>Engagement</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={engagement.notation}
                                        onChange={(e) => { setengagement({ ...engagement, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={engagement.commentaires} 
                                    onChange={(e) => { setengagement({ ...engagement, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={engagement.actions}
                                        onChange={(e) => { setengagement({ ...engagement, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={engagement.delai}
                                        onChange={(e) => { setengagement({ ...engagement, delai : e.target.value }) }} />
                                </td>                                
                            </tr>
                            
                            <tr>
                                <td>Qualité de la visite</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={qualite_visite.notation}
                                        onChange={(e) => { setqualite_visite({ ...qualite_visite, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={qualite_visite.commentaires} 
                                    onChange={(e) => { setqualite_visite({ ...qualite_visite, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={qualite_visite.actions}
                                        onChange={(e) => { setqualite_visite({ ...qualite_visite, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={qualite_visite.delai}
                                        onChange={(e) => { setqualite_visite({ ...qualite_visite, delai : e.target.value }) }} />
                                </td>                                
                            </tr>
                            
                            <tr>
                                <td>Enquête pharmacies</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5} value={enquete_pharmacies.notation}
                                        onChange={(e) => { setenquete_pharmacies({ ...enquete_pharmacies, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={enquete_pharmacies.commentaires} 
                                    onChange={(e) => { setenquete_pharmacies({ ...enquete_pharmacies, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={enquete_pharmacies.actions}
                                        onChange={(e) => { setenquete_pharmacies({ ...enquete_pharmacies, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={enquete_pharmacies.delai}
                                        onChange={(e) => { setenquete_pharmacies({ ...enquete_pharmacies, delai : e.target.value }) }} />
                                </td>                                
                            </tr>
                            
                            <tr>
                                <td> Ciblage Médecin</td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' type="number" min={0} max={5}  value={ciblage_medecin.notation}
                                        onChange={(e) => { setciblage_medecin({ ...ciblage_medecin, notation : e.target.value }); }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={ciblage_medecin.commentaires} 
                                    onChange={(e) => { setciblage_medecin({ ...ciblage_medecin, commentaires : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={ciblage_medecin.actions}
                                        onChange={(e) => { setciblage_medecin({ ...ciblage_medecin, actions : e.target.value }) }} />
                                </td>
                                <td>
                                    <input className='d-block' form='addDuoFicheForm' value={ciblage_medecin.delai}
                                        onChange={(e) => { setciblage_medecin({ ...ciblage_medecin, delai : e.target.value }) }} />
                                </td>                                
                            </tr>

                            <tr>
                                <td className='text-center align-middle'>Total</td>
                                <td>
                                    <input className='d-block' disabled form='addDuoFicheForm' value={total}
                                         />
                                </td>                              
                            </tr>
                            
                            <tr>
                                <td className='text-center'>Priorité d'amélioration </td>
                                <td colSpan={4}>
                                    <input className='d-block' form='addDuoFicheForm'  value={priorite_amelioration}
                                        onChange={(e) => { setpriorite_amelioration( e.target.value ) }} />
                                </td>                              
                            </tr>

                        </tbody>
                    </table>
                    </Col>

                </Row>
                <Row className='pb-4'>
                    <Col md={12} className='justify-content-center mt-3 pb-4' style={{ display: "flex" }}>
                        <Button className='mb-4' form='addDuoFicheForm' type='submit' onClick={formValidation}>Ajouter</Button>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
