import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import { toast } from 'react-toastify';
import { Container, Row, Col, CardHeader, Table, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Button, CardFooter } from 'reactstrap';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import Loader from '../../layout/loader';
import jwt from 'jsonwebtoken';

export default function BdcDetailed() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const role = token.length > 0 ? jwt.decode(token).type : "";
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [products, setProducts] = useState([]);
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => { setVerticalcenter(!Verticalcenter) };
  const initialBdcStatus = location.state?.bdc?.bdc_status || "";
  const [bdcStatus, setBdcStatus] = useState(initialBdcStatus);

  useEffect(() => {
    if (location.state) {
      setProducts(location.state.bdc.bdc_products)
    }
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const updatedBdcStatus = location.state?.bdc?.bdc_status || "";
    setBdcStatus(updatedBdcStatus);
  }, [location.state]);
  const handleQuantityChange = (index, newDeliveredQuantity) => {
    if (isNaN(newDeliveredQuantity)) {
      newDeliveredQuantity = 0
    }
    const updatedProducts = [...products];
    updatedProducts[index].deliveredQuantity = newDeliveredQuantity;
    let newStatus = "Honoré"
    if (updatedProducts[index].deliveredQuantity == 0) {
      newStatus = "Annulé"
    }
    else if (updatedProducts[index].deliveredQuantity < updatedProducts[index].quantity) {
      newStatus = "Semi-honoré"
    }
    else {
      newStatus = "Honoré"
    }
    updatedProducts[index].status = newStatus
    setProducts(updatedProducts);
  };

  const exportDetails = async (id) => {
    setLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      }
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/bdc/export?bdc_id=" + id, request, { mode: "cors" });
      if (responce.status === 200) {
        const blob = await responce.blob();
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = ('Bon de commande ' + id) + '.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }


  const updateBDC = async (id) => {
    setLoading(true);
    let status = "Instance"
    const rejectedProducts = products.filter((product) => product.status == "Annulé")
    const honoredProducts = products.filter((product) => product.status == "Honoré")
    if (rejectedProducts.length == products.length) {
      status = "Annulé"
    } else if (honoredProducts.length == products.length) {
      status = "Honoré"
    } else {
      status = "Semi-honoré"
    }

    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ bdc_status: status, bdc_products: products, })
    }
    try {
      var response = await fetch(`https://lsreport.shls.care/api/bdc/update?bdc_id=` + id, request, { mode: "cors" });
      if (response.status === 200) {
        setBdcStatus(status)
        toast.success("BDC mis à jour avec succès");

      } else {
        toast.error("Erreur !");
      }
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  }

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Bons de commande" title="Détails Bon de commande" />
      <Container fluid={true} className='pb-4 mb-4'>
        <Row className='pb-4'>
          <Col xl="4" className='mb-4'>
            <Card>
              <CardHeader className='text-center'>
                <h4>{location.state && location.state.bdc.bdc_id}

                </h4>
              </CardHeader>
              <CardBody className='pb-4'>
                <Row className="mb-2 pb-4">
                  <Col>
                    <Table>
                      <tbody>
                        <tr>
                          <th>Visiteur:</th>
                          <td>{location.state && (location.state.bdc.user_lastname + " " + location.state.bdc.user_firstname)}</td>
                        </tr>
                        {/* <tr>
                          <th>Manager:</th>
                          <td>{location.state && (location.state.bdc.supervisor.user_lastname + " " + location.state.bdc.supervisor.user_firstname)}</td>
                        </tr> */}
                        <tr>
                          <th>Date:</th>
                          <td>{location.state && moment(location.state.bdc.bdc_date).format("DD/MM/YYYY")}</td>
                        </tr>
                        <tr>
                          <th>Client:</th>
                          <td>{location.state && location.state.bdc.contact_lastname + " " + location.state.bdc.contact_firstname}</td>
                        </tr>
                        <tr>
                          <th>Pack:</th>
                          <td>{location.state && location.state.bdc.bdc_pack}</td>
                        </tr>
                        <tr>
                          <th>Grossiste:</th>
                          <td>{location.state && location.state.bdc.bdc_grossiste}</td>
                        </tr>
                        <tr>
                          <th>Wilaya:</th>
                          <td>{location.state && location.state.bdc.bdc_wilaya}</td>
                        </tr>
                        <tr>
                          <th>Commune:</th>
                          <td>{location.state && location.state.bdc.bdc_commune}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
          <Col xl="8" className='mb-4'>
            <Card>
              <CardHeader className='d-flex justify-content-between'>
                <h4 className="card-title mb-0">Liste des Produits</h4>
                {
                  (() => {
                    if (bdcStatus === "Annulé" ||bdcStatus === "Annulé")
                      return <span className="badge badge-pill badge-danger text-light p-2">{bdcStatus}</span>
                    if (bdcStatus === "Honoré" || bdcStatus === "Honoré")
                      return <span className="badge badge-pill badge-success text-light p-2">{bdcStatus}</span>
                      if (bdcStatus === "Instance" || bdcStatus === "Instance")
                      return <span className="badge badge-pill badge-primary text-light p-2">En {bdcStatus}</span>
                    else
                      return <span className="badge badge-pill badge-warning text-dark p-2">{bdcStatus}</span>
                  })()
                }
              </CardHeader>
              {(location.state && location.state.bdc.bdc_products.length === 0) ?
                <CardBody>
                  <h5>Aucun produit à afficher</h5>
                </CardBody>
                :
                <>
                  <CardBody>
                    <Table className='text-center'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Nom Produit</th>
                          <th>Prix</th>
                          <th>Quantité</th>
                          <th>Quantité délivrée</th>
                          <th>Statut</th>
                          {(bdcStatus === "Instance")&& 
                          <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {location.state && location.state.bdc.bdc_products.map((item) => {
                          return (
                            <tr key={location.state && location.state.bdc.bdc_products.indexOf(item)}>
                              <td> {(location.state && location.state.bdc.bdc_products.indexOf(item) + 1)}</td>
                              <td>{item.product_name}</td>
                              <td>{item.product_price + " DA"}</td>
                              <td>{item.quantity}</td>
                              <td>{item.deliveredQuantity}</td>
                              <td>
                                {
                                  (() => {
                                    if (item.status === "Annulé" || item.status === "Annulé")
                                      return <span className="badge badge-pill badge-danger p-2">{item.status}</span>
                                    if (item.status === "Semi-honoré" || item.status === "Semi-honoré")
                                      return <span className="badge badge-pill badge-warning p-2">{item.status}</span>
                                    else
                                      return <span className="badge badge-pill badge-success p-2">{item.status}</span>
                                  })()
                                }
                              </td>
                              {
                            ((bdcStatus === "Instance") && ( role==="admin" || role==="manager"))&& 
                              <td>
                                <i className="fa fa-edit mr-2 text-warning " onClick={() => {
                                  location.state && setSelectedIndex(location.state.bdc.bdc_products.indexOf(item));
                                  Verticalcentermodaltoggle()
                                }} role="button" ></i>
                              </td>
                              }
                            </tr>
                          )
                        })}

                      </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      {((bdcStatus === "Instance") && ( role==="admin" || role==="manager"))?
                        <>
                          <Col md={"6"} >
                            <button className='btn btn-block btn-primary  mt-0 mb-2' onClick={() => {
                              if (location.state) {
                                updateBDC(location.state.bdc.bdc_id)
                              }
                            }}><i className='fa fa-check-circle mr-2'> </i> Valider</button>
                          </Col>
                          <Col md={"6"} >
                            <button className='btn btn-block btn-success  mt-0 mb-2' onClick={() => {
                              if (location.state) {
                                exportDetails(location.state.bdc.bdc_id)
                              }
                            }}><i className='fa fa-file-excel-o mr-2'> </i> Exporter</button>
                          </Col>
                        </>
                        :
                        <Col md={"12"} >
                          <button className='btn btn-block btn-success  mt-0 mb-2' onClick={() => {
                            if (location.state) {
                              exportDetails(location.state.bdc.bdc_id)
                            }
                          }}><i className='fa fa-file-excel-o mr-2'> </i> Exporter</button>
                        </Col>}
                    </Row>
                  </CardFooter>
                </>
              }
            </Card>
            <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle} centered>
              <ModalHeader toggle={Verticalcentermodaltoggle}>
                Modifier la quantité
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md="12" className='input--group'>
                    <FormGroup>
                      <Label className="form-label" >Produit: </Label>
                      <Input type="text" className="form-control" placeholder="Type" disabled value={products[selectedIndex]?.product_info?.product_name} />
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label" >Quantité: </Label>
                      <Input type="text" className="form-control" placeholder="Type" disabled value={products[selectedIndex]?.quantity} />
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label" >Quantité délivrée: </Label>
                      <Input type="text" className="form-control" placeholder="Type" max={products[selectedIndex]?.quantity} value={products[selectedIndex]?.deliveredQuantity} onChange={
                        (e) => handleQuantityChange(selectedIndex, parseInt(e.target.value, 10))} />
                    </FormGroup>

                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className='d-block'>
                <Row>
                  <Col md="12">
                    {
                      (() => {
                        if (products[selectedIndex]?.status === "Annulé" || products[selectedIndex]?.status === "Annulé")
                          return <span className="badge badge-pill badge-danger p-3 d-block">{products[selectedIndex]?.status}</span>
                        if (products[selectedIndex]?.status === "Semi-honoré" || products[selectedIndex]?.status === "Semi-honoré")
                          return <span className="badge badge-pill badge-warning p-3 d-block">{products[selectedIndex]?.status}</span>
                        else
                          return <span className="badge badge-pill badge-success p-3 d-block">{products[selectedIndex]?.status}</span>
                      })()
                    }
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
