import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, Form, FormGroup, Table, Card, CardBody, Pagination, PaginationItem, PaginationLink, } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import DatePicker from "react-datepicker";

export default function SynthesesList() {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const token = document.cookie.split(`jwt=`).pop().split(';').shift();
    const exportData = async () => {
        setLoading(true);
        const request = {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({
            start_date: startDate
            , end_date: endDate
        })
        }
        try {
          var responce = await fetch("https://lsreport.shls.care/api/syntheses/export", request, { mode: "cors" });
          if (responce.status === 200) {
            const blob = await responce.blob();
            const downloadLink = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.download = 'synthese.xlsx';
            document.body.appendChild(downloadLink);
            downloadLink.click();
    
            // Clean up resources
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(url);
            toast.success("success");
    
          } else {
            toast.error(await responce.json());
          }
        } catch (err) {
          setLoading(false);
          toast.error(err.message);
        }
        setLoading(false);
      }
    const fetchData = async () => {
        setLoading(true);
        const request = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                start_date: startDate
                , end_date: endDate
            })
        }
        try {
            var responce = await fetch("https://lsreport.shls.care/api/synthese/all", request, { mode: "cors" });
            if (responce.status === 200) {
                let data = await responce.json();
                console.log(data);
                setData(data);
            } else {

                toast.error(await responce.json());
            }
        } catch (err) {
            setLoading(false);
            toast.error(err.message);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
    }, [page])


    return (
        <Fragment>
            <Loader isLoading={loading} />
            <Breadcrumbs parent="Synthèse" title="Synthèse" />

            <Container fluid={true} className='pb-4 mb-4'>
                <Row className='mb-4 justify-content-around'>
                    <Col md="3">
                        <label htmlFor="">Du:</label>
                        <DatePicker dateFormat={"dd/MM/yyyy"} selected={startDate} maxDate={new Date()} onChange={(date) => setStartDate(date)} />
                    </Col>
                    <Col md="3">
                        <label htmlFor="">Au:</label>
                        <DatePicker dateFormat={"dd/MM/yyyy"} minDate={startDate} maxDate={new Date()} selected={endDate} onChange={(date) => setEndDate(date)} />
                    </Col>
                    <Col md="2">
                        <Button color='warning' onClick={() => { fetchData() }} style={{ marginTop: "32px", width: "100%" }}>Rechercher</Button>
                    </Col>
                    <Col md="2">
                    <Link to={{
                            pathname: `${process.env.PUBLIC_URL}/syntheses/add`
                          }}>
                        <Button color='primary' style={{ marginTop: "32px", width: "100%" }}>Ajouter</Button>
                          </Link>
                    </Col>
                    {data.length !== 0 &&
                    <Col md="2">
                        <Button color='success' onClick={() => { exportData() }} style={{ marginTop: "32px", width: "100%" }}>Exporter</Button>
                          
                    </Col>
                    }
                </Row>

                {data.length === 0 ?
                    <Card className='text-align-center'>
                        <CardBody style={{ textAlign: "center" }}>
                            <h4>Aucune Synthèse à afficher</h4>
                        </CardBody>
                    </Card>
                    :
                    <>
                        <Row>
                            <Col xs={12} className='o-auto'>
                                <Table style={{ background: "white" }}>
                                    <thead>
                                        <tr className='text-center'>
                                            <th>#</th>
                                            <th>Visiteur</th>
                                            <th>Secteur</th>
                                            <th>Date</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((syn) => {
                                            return (
                                                <tr key={data.indexOf(syn)} className='text-center'>
                                                    <th scope='row'> {(data.indexOf(syn) + 1)}</th>
                                                    <td>{syn.user_lastname} {syn.user_firstname}</td>
                                                    <td>{syn.secteur.join(", ")}</td>
                                                    <td>{moment(syn.created_at).format("DD/MM/YYYY")}</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <Link to={{
                                                            pathname: `${process.env.PUBLIC_URL}/syntheses/detailed`,
                                                            state: {
                                                                syn
                                                            }
                                                        }}>
                                                            <i className="fa fa-table" id="TooltipExample"></i>
                                                        </Link>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </>
                }

            </Container>
        </Fragment>
    )
}
