import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Form, FormGroup, Input, Table, Card, CardBody, Pagination, PaginationItem, PaginationLink, UncontrolledDropdown,DropdownItem ,DropdownMenu,DropdownToggle} from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import "./Reports.css";
import jwt from 'jsonwebtoken';
import Swal from 'sweetalert2';

export default function Reports() {
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const [selectedteam, setTeam] = useState(token.length > 0 ? jwt.decode(token).network : "");
  const role = token.length > 0 ? jwt.decode(token).type : "";
  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword)
  };
  //Fetch API call
  const fetchData = async () => {
    if (selectedteam == "undefined") {
      return
    }
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ item_per_page: 25, page: page, key: searchKeyword, team:selectedteam})
    }
    try {
      const responce = await fetch("https://lsreport.shls.care/api/reports/all", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setData(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const confirmDelete = (report_id) => {
    console.log(report_id)
    Swal.fire({
      title: "Voulez vous vraiment supprimer ce rapport ?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      denyButtonText: `Annuler`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteReport(report_id)
      } else if (result.isDenied) {
        return
      }
    });
  }

  const deleteReport = async (report_id) => {
    if (report_id == null) {
      return;
    }
    console.log(report_id)
    setLoading(true);
    const request = {
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ report_id: report_id })
    }
    try {
      var responce = await fetch(`https://lsreport.shls.care/api/reports/report/delete`, request, { mode: "cors" });
      if (responce.status === 200) {
        toast.success("Rapport supprimé avec succès");
        fetchData()
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const paginationNumber = (page) => {
    setPage(page);
  }
  const totalpages = () => {
    if (data.length > 0) {
      const total = Math.ceil(data[0].report.full_count / 25);
      return total;
    } else {
      return 0
    }
  }
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [page])

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [selectedteam])

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Visites" title="Rapports" />
      <Container fluid={true} className='pb-4 mb-4'>
        <Row>
          <Col md={3} className="feature-products">
          {role === "admin" ?
                <UncontrolledDropdown>
                  <DropdownToggle color='dark' caret className="b-r-0 ourBtn btn-block btn-lg">
                    {selectedteam}
                  </DropdownToggle>
                  <DropdownMenu >
                    <DropdownItem onClick={() => {setTeam("Antidouleur")}}>Antidouleur</DropdownItem>
                    <DropdownItem onClick={() => {setTeam("Gynéco")}}>Gynéco</DropdownItem>
                    <DropdownItem onClick={() => {setTeam("Neuropsychiatrie /HGE")}}>Neuropsychiatrie /HGE</DropdownItem>
                    <DropdownItem onClick={() => {setTeam("Visite Pharma")}}>Visite Pharma</DropdownItem>
                    <DropdownItem onClick={() => {setTeam("Diabète")}}>Diabète</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> : ''}
          </Col>
          <Col md={9} className="feature-products">
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Nom contact"
                  defaultValue=""
                  onChange={(e) => handleSearchKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (page === 1) {
                        fetchData();
                      } else {
                        setPage(1);
                      }

                    }
                  }}
                />
                <i className="fa fa-search"></i>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className='o-auto'>
            {data.length === 0 ?
              <Card className='text-align-center'>
                <CardBody style={{ textAlign: "center" }}>
                  <h4>Aucun rapport à afficher</h4>
                </CardBody>
              </Card>
              :
              <Table style={{ backgroundColor: "white" }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Visiteur</th>
                    <th>Contact</th>
                    <th>Specialité</th>
                    <th>Potentiel</th>
                    <th>Secteur</th>
                    <th>Ville</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => {
                    const diffrence = (moment(item.report.report_end_time).diff(moment(item.report.report_start_time), "minutes"))
                    return (
                      <tr key={data.indexOf(item)} style={{ textDecoration: item.report.report_type === "unvailibility" ? "line-through" : "", backgroundColor: item.report.report_type === "unvailibility" ? "rgba(171,171,171,0.2)" : (diffrence < 5 || Number.isNaN(diffrence)) ? "rgba(242, 255, 102,0.3)" : "" }}>
                        <th scope='row'>{(data.indexOf(item) + 1) + ((page - 1) * 25)}
                          {/* <Badge color="secondary">urgent</Badge> */}
                        </th>
                        <td>{moment(item.report.report_done_on).format("DD/MM/yyyy à HH:mm")}</td>
                        <td>
                          <Link to={`${process.env.PUBLIC_URL}/users/user:${item.agent.user_id}`}>
                            {item.agent.user_lastname + " " + item.agent.user_firstname}
                          </Link>
                        </td>
                        <td>
                          <Link to={`${process.env.PUBLIC_URL}/contacts/contact:${item.contact.contact_id}`}>
                            {item.contact.contact_lastname + " " + item.contact.contact_firstname}
                          </Link>
                        </td>
                        <td>{item.contact.contact_speciality}</td>
                        <td>{item.contact.contact_potential[item.agent.user_network] ?? item.contact.contact_potential["Visite Pharma"]}</td>
                        <td>{item.contact.contact_sector}</td>
                        <td>{item.contact.contact_wilaya + " " + item.contact.contact_city}</td>
                        <td style={{ textAlign: "center" }}>
                          <Link to={{
                            pathname: `${process.env.PUBLIC_URL}/visites/rapports/detailed`,
                            state: {
                              reportID: item.report.report_id,
                              contact_lastname: item.contact.contact_lastname,
                              contact_firstname: item.contact.contact_firstname,
                              contact_speciality: item.contact.contact_speciality,
                              contact_address: item.contact.contact_address,
                              contact_wilaya: item.contact.contact_wilaya,
                              contact_city: item.contact.contact_city,
                              contact_prescription_possibility: item.contact.contact_prescription_possibility[item.agent.user_network] ?? "",
                              contact_potentiel: (item.contact.contact_potential[item.agent.user_network] ?? item.contact.contact_potential["Visite Pharma"]),
                              agent_lastname: item.agent.user_lastname,
                              agent_firstname: item.agent.user_firstname,
                              agent_network: item.agent.user_network,
                              contact_id: item.contact.contact_id,
                              agent_id: item.agent.user_id,
                            }
                          }}>
                            <i className="fa fa-table" id="TooltipExample"></i>
                          </Link>
                          <i className="fa fa-trash text-danger ml-2" id="TooltipExample" style={{ cursor: "pointer" }} onClick={() => confirmDelete(item.report.report_id)}></i>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            }
          </Col>
        </Row>
        <Row className='my-5'>
          <Col>
            <Pagination aria-label="Page navigation example" className="pagination justify-content-center pagination-primary" >
              <PaginationItem><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : 1)}><span aria-hidden="true">«</span><span className="sr-only">Previous</span></PaginationLink></PaginationItem>
              {(totalpages() === 1) &&
                <PaginationItem active={true}><PaginationLink >{page}</PaginationLink></PaginationItem>
              }
              {(totalpages() === 2) &&
                <>
                  <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 1 ? page : page - 1)}>{page === 1 ? page : page - 1}</PaginationLink></PaginationItem>
                  <PaginationItem active={page === 2 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 2 ? page : page + 1)}>{page === 2 ? page : page + 1}</PaginationLink></PaginationItem>
                </>
              }
              {(totalpages() > 2) &&
                <>
                  <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : page)}>{page === totalpages() ? page - 2 : page > 1 ? page - 1 : page}</PaginationLink></PaginationItem>
                  <PaginationItem active={page != 1 && page != totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page - 1 : page > 1 ? page : page + 1)}>{page === totalpages() ? page - 1 : page > 1 ? page : page + 1}</PaginationLink></PaginationItem>
                  <PaginationItem active={page === totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page : page > 1 ? page + 1 : page + 2)}>{page === totalpages() ? page : page > 1 ? page + 1 : page + 2}</PaginationLink></PaginationItem>
                </>
              }
              <PaginationItem><PaginationLink onClick={() => paginationNumber((totalpages() <= page) ? page : page + 1)}><span aria-hidden="true">»</span><span className="sr-only">Next</span></PaginationLink></PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
