import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Table, Card, CardBody, CardHeader,Button } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';

import "react-datepicker/dist/react-datepicker.css";

import Chart from 'react-apexcharts';


export default function AgentDetailedProgress() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const [loading, setloading] = useState(false);
  const location = useLocation();
  const [data, setData] = useState({ duals: [], dot: [] });

  const fetchData = async () => {
    setloading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        maxdate: location.state && location.state.maxdate,
        mindate: location.state && location.state.mindate,
        user_id: location.state && location.state.id
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/stats/agentstatsdetail", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setData(data);

      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }
  const exportData = async () => {
    setloading(true);
    const request = {
      method: "POST",
      headers: {
        // 'Accept': GetMineType(ext),
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        start_date: location.state && location.state.mindate,
        end_date: location.state && location.state.maxdate,
        user_id: location.state && location.state.id
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/contacts/export", request, { mode: "cors" });
      if (responce.status === 200) {
        const blob = await responce.blob();
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = location.state && location.state.name + '.xlsx'; // Set the desired file name
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up resources
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
        toast.success("success");

      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }


  const potentielSeries = [location.state?.nbra ? location.state?.nbra :0, location.state?.nbrb ? location.state?.nbrb:0, location.state?.nbrc ? location.state?.nbrc:0];
  const potentielPieChart = {
    series: potentielSeries,
    options: {
      chart: {
        width: 450,
        type: 'pie',
      },
      labels: [`A : ${location.state?.nbra ? location.state?.nbra:0}`,`B : ${location.state?.nbrb ? location.state?.nbrb:0 }`, `C : ${location.state?.nbrc ? location.state?.nbrc:0}` ],
      colors: ['rgba(0,227,150,1)', '#0084ff', 'rgba(254,176,25,1)'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'top'
          }
        }
      }]
    },
  }

  const workSeries = [location.state?.work ? location.state?.work:0, location.state?.nowork ? location.state?.nowork:0, location.state?.dot ? location.state?.dot:0];
  const workPieChart = {
    series: workSeries,
    options: {
      chart: {
        width: 450,
        type: 'pie',
      },
      labels: [`Jours travaillés: ${location.state?.work ? location.state?.work:0}j`, `Jours non travaillés: ${location.state?.nowork ? location.state?.nowork:0}j`, `Days off territory: ${location.state?.dot ? location.state?.dot:0}j`],
      colors: ['rgba(0,227,150,1)', '#ff0e0e', '#0084ff',],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 450
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  }

  const ratio = {

    series: [location.state?.roa ? location.state?.roa:0, location.state?.rob ? location.state?.rob:0, location.state?.roc ? location.state?.roc:0],
    options: {
      chart: {
        height: 200,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 360,
          hollow: {
            margin: 15,
            size: '40%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: true,
            },
            value: {
              show: true,
            }
          }
        }
      },
      colors: ['rgba(0,227,150,1)', '#0084ff', 'rgba(254,176,25,1)',],
      labels: ["A", "B", "C"],
      legend: {
        show: true,
        floating: false,
        fontSize: '14px',
        position: 'top',
        offsetX: 0,
        offsetY: 0,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0
        },
        formatter: function (seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
        },
        itemMargin: {
          vertical: 3
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }]
    },


  };





  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);


  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Statistiques" title="statistiques Visiteurs" />
      <Container fluid={true} className='pb-4 mb-4' style={{ paddingTop: "50px" }}>
        {location.state &&
          <>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody >
                    <Table className='table table-borderless'>
                      <tbody>
                        <tr>
                          <th><h4>Visiteur: <span style={{ color: "red" }}>{location.state && location.state.name}</span></h4></th>
                          <th><h4>{location.state && moment(location.state.mindate).format("DD/MM/yyyy")} - {location.state && moment(location.state.maxdate).format("DD/MM/yyyy")}</h4></th>
                          <td>
                            <button className='btn btn-success d-print-none btn-lg' onClick={(e) => { e.preventDefault(); window.print() }}><i className='fa fa-print'></i> Imprimer</button>
                          </td>
                        </tr>

                      </tbody>
                    </Table>

                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className='d-print-table pb-0'>
              <Col md="12" lg="6" className='d-print-table-cell' >
                <Card style={{ height: "400px" }}>

                  <CardBody>
                   {
                    (location.state.progres_visites && location.state.total ) && <Chart options={progressOptions(location.state.progres_visites && location.state.progres_visites, `Visites ${location.state.total && location.state.total}`).options} series={progressOptions(location.state.progres_visites && location.state.progres_visites).series} height="70" width="100%" type="bar" />
                   }
                    {
                      location.state.progres_doctors && <Chart options={progressOptions(location.state.progres_doctors && location.state.progres_doctors, `Médecins ${location.state.doctors && location.state.doctors}`).options} series={progressOptions(location.state.progres_doctors && location.state.progres_doctors).series} height="70" width="100%" type="bar" />
                    }
                    {
                      location.state.progres_pharmacy &&<Chart options={progressOptions(location.state.progres_pharmacy && location.state.progres_pharmacy, `Pharmacies ${location.state.pharmacy && location.state.pharmacy}`).options} series={progressOptions(location.state.progres_pharmacy && location.state.progres_pharmacy).series} height="70" width="100%" type="bar" />
                    }
                    
                  </CardBody>

                </Card>
              </Col>
              <Col md="12" lg="6" className='d-print-table-cell'>
                <Card style={{ height: "400px" }}>
                  <CardHeader className='text-center py-4'>
                    <h4 className='mb-0'>Jours De Travail</h4>
                  </CardHeader>
                  <CardBody>
                    {
                      (location.state.work && location.state.nowork && location.state.dot) ?<Chart options={workPieChart.options} series={workPieChart.series} type="pie" height={"100%"} />:""
                    }
                    
                  </CardBody>

                </Card>
              </Col>
            </Row>
            <Row className='d-print-table'>

              <Col md="12" lg="6" className='d-print-table-cell'>
                <Card style={{ height: "400px" }}>
                  <CardHeader className='text-center py-4'>
                    <h4 className='mb-0'>Potentiel</h4>
                  </CardHeader>
                  <CardBody>
                    {
                      (location.state.nbra && location.state.nbrb && location.state.nbrc)&&<Chart options={potentielPieChart.options} series={potentielPieChart.series} type="pie" height={"100%"} />
                    }
                   

                  </CardBody>
                </Card>
              </Col>
              <Col md="12" lg="6" className='d-print-table-cell'>
                <Card style={{ height: "400px" }}>
                  <CardHeader className='text-center py-4'>
                    <h4 className='mb-0'>Ratio</h4>
                  </CardHeader>
                  <CardBody>
                     {
                      (location.state.roa && location.state.rob && location.state.roc)&&<Chart options={ratio.options} series={ratio.series} type="radialBar" height={"100%"} />
                     }
                    
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </>
        }
        {data?.dot.length > 0 &&
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <h6 className='mb-2'>Days off territory</h6>
                  <Table>
                    <thead>
                      <tr>
                        <th>Début</th>
                        <th>Fin</th>
                        <th>Durée</th>
                        <th>Motif</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.dot.map((dot) => {
                        return (
                          <tr key={data?.dot.indexOf(dot)}>
                            <td>{moment(dot.dot_start_date).format("DD/MM/yyyy")}</td>
                            <td>{moment(dot.dot_end_date).format("DD/MM/yyyy")}</td>
                            <td>{(moment(dot.dot_end_date).diff(moment(dot.dot_start_date), "Days"))} jours</td>
                            <td>{dot.dot_type}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
        {location.state && location.state.user_type != "agent" ?
          <Row id='duals' className='d-print-none'>
            <Col xs="12" className='o-auto'>
              {data?.duals && data?.duals.length === 0 ?
                <Card className='text-align-center'>
                  <CardBody style={{ textAlign: "center" }}>
                    <h4>Aucune visite en duo à afficher</h4>
                  </CardBody>
                </Card>
                :
                <>
                  <h4>Visite en duo</h4>
                  <Table className='mb-5' style={{ backgroundColor: "white" }}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Visituer</th>
                        <th>Contact</th>
                        <th>Specialité</th>
                        <th>Potentiel</th>
                        <th>Secteur</th>
                        <th>Ville</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.duals && data?.duals.map((item) => {
                        const diffrence = (moment(item.report.report_end_time).diff(moment(item.report.report_start_time), "minutes"))
                        return (
                          <tr key={data?.duals.indexOf(item)} style={{ textDecoration: item.report.report_type === "unvailibility" ? "line-through" : "", backgroundColor: item.report.report_type === "unvailibility" ? "rgba(171,171,171,0.2)" : (diffrence < 5 || Number.isNaN(diffrence)) ? "rgba(242, 255, 102,0.3)" : "" }}>
                            <th scope='row'> {(data?.reports.indexOf(item) + 1)}</th>
                            <td>{moment(item.report.report_done_on).format("DD/MM/yyyy à HH:mm")}</td>
                            <td>
                              <Link to={`${process.env.PUBLIC_URL}/users/user:${item.agent.user_id}`}>
                                {item.agent.user_lastname + " " + item.agent.user_firstname}
                              </Link>
                            </td>
                            <td>
                              <Link to={`${process.env.PUBLIC_URL}/contacts/contact:${item.contact.contact_id}`}>
                                {item.contact.contact_lastname + " " + item.contact.contact_firstname}
                              </Link>
                            </td>
                            <td>{item.contact.contact_speciality}</td>
                            <td>{item.contact.contact_potential[item.agent.user_network] ?? item.contact.contact_potential["Visite Pharma"]}</td>
                            <td>{item.contact.contact_sector}</td>
                            <td>{item.contact.contact_wilaya + " " + item.contact.contact_city}</td>
                            <td style={{ textAlign: "center" }}>
                              <Link to={{
                                pathname: `${process.env.PUBLIC_URL}/visites/rapports/detailed`,
                                state: {
                                  reportID: item.report.report_id,
                                  contact_lastname: item.contact.contact_lastname,
                                  contact_firstname: item.contact.contact_firstname,
                                  contact_speciality: item.contact.contact_speciality,
                                  contact_address: item.contact.contact_address,
                                  contact_wilaya: item.contact.contact_wilaya,
                                  contact_prescription_possibility: item.contact.prescription_possibility,
                                  contact_potentiel: (item.contact.contact_potential[item.agent.user_network] ?? item.contact.contact_potential["Visite Pharma"]),
                                  agent_lastname: item.agent.user_lastname,
                                  agent_firstname: item.agent.user_firstname,
                                  agent_network: item.agent.user_network,
                                  contact_id: item.contact.contact_id,
                                  agent_id: item.agent.user_id
                                }
                              }}>
                                <i className="fa fa-table" id="TooltipExample"></i>
                              </Link>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </>
              }
            </Col>
          </Row>
          : <></>}
        <Row id='reports' className='d-print-none'>
          <Col xs="12" className='o-auto'>
            {data?.reports && data?.reports?.length === 0 ?
              <Card className='text-align-center'>
                <CardBody style={{ textAlign: "center" }}>
                  <h4>Aucun rapport à afficher</h4>
                </CardBody>
              </Card>
              :
              < >
                <div className='d-flex justify-content-between mb-2'>

                  <h4>Rapports</h4>
                  <Button color='success' className='my-3' onClick={() => exportData()}>
                    Exporter &nbsp;
                    <i className="fa fa-table" id="TooltipExample"></i>
                  </Button>
                </div>
                <Table className='mb-5' style={{ backgroundColor: "white" }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Visituer</th>
                      <th>Contact</th>
                      <th>Specialité</th>
                      <th>Potentiel</th>
                      <th>Secteur</th>
                      <th>Ville</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.reports && data?.reports.map((item) => {
                      const diffrence = (moment(item.report.report_end_time).diff(moment(item.report.report_start_time), "minutes"))
                      return (
                        <tr key={data?.reports.indexOf(item)} style={{ textDecoration: item.report.report_type === "unvailibility" ? "line-through" : "", backgroundColor: item.report.report_type === "unvailibility" ? "rgba(171,171,171,0.2)" : (diffrence < 5 || Number.isNaN(diffrence)) ? "rgba(242, 255, 102,0.3)" : "" }}>
                          <th scope='row'> {(data?.reports.indexOf(item) + 1)}</th>
                          <td>{moment(item.report.report_done_on).format("DD/MM/yyyy à HH:mm")}</td>
                          <td>
                            <Link to={`${process.env.PUBLIC_URL}/users/user:${item.agent.user_id}`}>
                              {item.agent.user_lastname + " " + item.agent.user_firstname}
                            </Link>
                          </td>
                          <td>
                            <Link to={`${process.env.PUBLIC_URL}/contacts/contact:${item.contact.contact_id}`}>
                              {item.contact.contact_lastname + " " + item.contact.contact_firstname}
                            </Link>
                          </td>
                          <td>{item.contact.contact_speciality}</td>
                          <td>{item.contact.contact_potential[item.agent.user_network] ?? item.contact.contact_potential["Visite Pharma"]}</td>
                          <td>{item.contact.contact_sector}</td>
                          <td>{item.contact.contact_wilaya + " " + item.contact.contact_city}</td>
                          <td style={{ textAlign: "center" }}>
                            <Link to={{
                              pathname: `${process.env.PUBLIC_URL}/visites/rapports/detailed`,
                              state: {
                                reportID: item.report.report_id,
                                contact_lastname: item.contact.contact_lastname,
                                contact_firstname: item.contact.contact_firstname,
                                contact_speciality: item.contact.contact_speciality,
                                contact_address: item.contact.contact_address,
                                contact_wilaya: item.contact.contact_wilaya,
                                contact_prescription_possibility: item.contact.prescription_possibility,
                                contact_potentiel: (item.contact.contact_potential[item.agent.user_network] ?? item.contact.contact_potential["Visite Pharma"]),
                                agent_lastname: item.agent.user_lastname,
                                agent_firstname: item.agent.user_firstname,
                                agent_network: item.agent.user_network,
                                contact_id: item.contact.contact_id,
                                agent_id: item.agent.user_id
                              }
                            }}>
                              <i className="fa fa-table" id="TooltipExample"></i>
                            </Link>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </>
            }
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}


function progressOptions(progress, title) {
  return {
    options: {
      chart: {
        width: 990,
        height: 70,
        type: "bar",
        stacked: true,
        sparkline: {
          enabled: true
        }
      },
      stroke: {
        width: 0,
      },
      fill: {
        colors: parseInt(progress) > 85 ? ["#00B187"] : parseInt(progress) > 60 ? ["#FF8162"] : ["#FF4149"],
        type: 'gradient',
        gradient: {
          gradientToColors: parseInt(progress) > 85 ? ["#00B187"] : parseInt(progress) > 60 ? ["#FF8162"] : ["#FF4149"]
        }
      },
      legend: {
        show: true
      },
      colors: ["#158df7"],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '20%',
          colors: {
            backgroundBarColors: ['#f2f2f2']
          }
        },
      },
      title: {
        floating: true,
        offsetX: -10,
        offsetY: 5,
        text: title,
        style: {
          fontSize: '12px'
        }
      },
      subtitle: {
        floating: true,
        align: 'right',
        offsetY: 0,
        text: progress,
        style: {
          fontSize: '16px'
        }
      },
      tooltip: {
        enabled: false
      },
      xaxis: {
        categories: ['Process 1'],
      },
      yaxis: {
        max: 100
      },
    },
    series: [{
      name: 'Process 1',
      data: [progress]
    }],
    labels: ['Device 1', 'Device 2']
  }
}