import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Table, Pagination, PaginationItem, PaginationLink,Button } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import jwt from 'jsonwebtoken';

export default function Valides() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const user_id = token.length > 0 ? jwt.decode(token).id : "";
  const [loading, setLoading] = useState(false);
  const [tours, settours] = useState([]);
  const [page, setPage] = useState(1);

  const paginationNumber = (page) => {
    setPage(page);
  }
  const totalpages = () => {
    if (tours.length > 0) {
      const total = Math.ceil(tours[0].full_count / 25);
      return total;
    } else {
      return 0
    }
  }

  const exportData = async () => {
    setLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
     
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/tours/valid/export?user_id="+user_id, request, { mode: "cors" });
 if (responce.status === 200) {
        const blob = await responce.blob();
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "Plans de tournée valides.xlsx"; 
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
        toast.success("success");

      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const fetchData = async () => {
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        item_per_page: 25,
        page: page,
      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/tours/getvalid", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        settours(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0)
  }, [page])

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Tournée" title="Tournée validés" />
      <Container fluid={true}  className='pb-4 mb-4'>
        <Row>
        <Col col="12">
            <Button color='success' className='my-3' onClick={() => exportData()}>
                    Exporter &nbsp;
                    <i className="fa fa-table" id="TooltipExample"></i>
                  </Button>
            </Col>
            <Col className='col-12'>
            <Table style={{ backgroundColor: "white" }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Visiteur</th>
                  <th>Début</th>
                  <th>Fin</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tours.map((tour) => {
                  return (
                    <tr key={tours.indexOf(tour)}>
                      <td>{(tours.indexOf(tour) + 1) + ((page - 1) * 25)}</td>
                      <td>{tour.global_tour_title}</td>
                      <td>
                        <Link to={`${process.env.PUBLIC_URL}/users/user:${tour.user.user_id}`}>
                          {tour.user.user_lastname} {tour.user.user_firstname}
                        </Link>
                      </td>
                      <td>{moment(tour.global_tour_start_date).format("DD/MM/yyyy")}</td>
                      <td>{moment(tour.global_tour_end_date).format("DD/MM/yyyy")}</td>
                      <td style={{ textAlign: "center" }}>
                        <Link to={{
                          pathname: `${process.env.PUBLIC_URL}/tournee/valides/detailed:${tour.global_tour_id}`,
                          state: {
                            user_lastname: tour.user.user_lastname,
                            user_firstname: tour.user.user_firstname,
                            user_network: tour.user.user_network,
                            user_id: tour.user.user_id,
                            startDate: moment(tour.global_tour_start_date).format("DD/MM/yyyy"),
                            endDate: moment(tour.global_tour_end_date).format("DD/MM/yyyy"),
                          }
                        }}>
                          <i className="fa fa-table" id="TooltipExample"></i>
                        </Link>
                      </td>
                    </tr>
                  )
                })
                }
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className='my-5 pagination-row'>
          <Col>
            <Pagination aria-label="Page navigation example" className="pagination justify-content-center pagination-primary" >
              <PaginationItem><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : 1)}><span aria-hidden="true">«</span><span className="sr-only">Previous</span></PaginationLink></PaginationItem>
              {(totalpages() === 1) &&
                <PaginationItem active={true}><PaginationLink >{page}</PaginationLink></PaginationItem>
              }
              {(totalpages() === 2) &&
                <>
                  <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 1 ? page : page - 1)}>{page === 1 ? page : page - 1}</PaginationLink></PaginationItem>
                  <PaginationItem active={page === 2 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 2 ? page : page + 1)}>{page === 2 ? page : page + 1}</PaginationLink></PaginationItem>
                </>
              }
              {(totalpages() > 2) &&
                <>
                  <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : page)}>{page === totalpages() ? page - 2 : page > 1 ? page - 1 : page}</PaginationLink></PaginationItem>
                  <PaginationItem active={page != 1 && page != totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page - 1 : page > 1 ? page : page + 1)}>{page === totalpages() ? page - 1 : page > 1 ? page : page + 1}</PaginationLink></PaginationItem>
                  <PaginationItem active={page === totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page : page > 1 ? page + 1 : page + 2)}>{page === totalpages() ? page : page > 1 ? page + 1 : page + 2}</PaginationLink></PaginationItem>
                </>
              }
              <PaginationItem><PaginationLink onClick={() => paginationNumber((totalpages() <= page) ? page : page + 1)}><span aria-hidden="true">»</span><span className="sr-only">Next</span></PaginationLink></PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
