import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, Card, CardBody, } from 'reactstrap';
import { toast } from 'react-toastify';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { Chart } from "react-google-charts";

const dataMaker = function (obj) {
  let array = [];
  array.push(["param", "value"]);
  for (let key in obj) {
    if (key === "true") {
      array.push(["Oui", obj[key]])
    }
    else if (key === "false") {
      array.push(["Non", obj[key]])
    }
    else if (key != "null") {
      array.push([key, obj[key]])
    }
  }
  return array;
}

export default function ProductStats() {
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const role = token.length > 0 ? jwt.decode(token).type : ""
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  const [team, setteam] = useState("Diabète");
  const [products, setProducts] = useState([]);
  const [formProduct, setFormProduct] = useState("");
  const [selectedId, setselectedId] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const history = useHistory();


  const selectedProductID = function (productName) {
    const selectedProduct = products.filter((product) => (product.product_name === productName))[0];
    setselectedId(selectedProduct && selectedProduct.product_id);
  }

  const getProducts = async () => {
    setloading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      }
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/products", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setProducts(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }

  const fetchData = async () => {
    setloading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        mindate: startDate,
        maxdate: endDate,
        team: team,
        product_id: selectedId

      })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/reports/stats", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        for (let keyy in data.stats) {
          for (let key in data.stats[keyy]) {
            if (key === "null") {
              delete data.stats[keyy][key]
            }
          }
        }
        setdata(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
    }
    setloading(false);
  }

  useEffect(() => {
    getProducts();
  }, [])
  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Statistiques" title="statistiques Produits" />
      <Container fluid={true} className='pb-4 mb-4'>
        <Row>
          <Col md={3}>
            <label htmlFor="users">Selectionner un produit</label>
            <input style={{ appearance: "none" }} name="products" placeholder='Selectionner un produit' type="text" list="data" value={formProduct} onChange={(e) => { setFormProduct(e.target.value); selectedProductID(e.target.value); }} />
            <datalist id="data">
              {products.map((product) => {
                return (
                  <option key={products.indexOf(product)}>{product.product_name}</option>
                )
              })}
            </datalist>
          </Col>
          {role === "admin" &&
            <Col md="3">
              <label htmlFor="users">Equipe</label>
              <select
                value={team}
                onChange={(e) => { setteam(e.target.value) }}
              >
                <option value="Antidouleur">Antidouleur</option>
                <option value="Diabète">Diabète</option>
                <option value="Gynéco">Gynéco</option>
                <option value="Neuropsychiatrie /HGE">Neuropsychiatrie /HGE</option>
                <option value="Visite Pharma">Visite Pharma</option>
              </select>
            </Col>
          }
          <Col md="2">
            <label htmlFor="">Du:</label>
            <DatePicker dateFormat={"dd/MM/yyyy"} selected={startDate} maxDate={new Date()} onChange={(date) => setStartDate(date)} />
          </Col>
          <Col md="2">
            <label htmlFor="">Au:</label>
            <DatePicker dateFormat={"dd/MM/yyyy"} minDate={startDate} maxDate={new Date()} selected={endDate} onChange={(date) => setEndDate(date)} />
          </Col>
          <Col md="2">
            <Button color='success' onClick={fetchData} style={{ marginTop: "32px" }}>Rechercher</Button>
          </Col>
        </Row>
        <br />
        <Row>
          {data.stats && data.reports.length > 0 ?
            <Col md="12" className='mb-3'>
              <h4>Médecins</h4>
            </Col>
            :
            <></>
          }
          {data.stats && Object.keys(data.stats.prescribed).length > 1 ?
            <Col md="6">
              <Card>
                <CardBody>
                  <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="PieChart"
                    loader={<div>{"Loading Chart"}</div>}
                    data={dataMaker(data.stats.prescribed)}
                    options={{
                      title: 'Préscrit',
                      colors: ["#a927f9", "#7366ff", "#51bb25", "#f8d62b", "#f73164"]
                    }}
                    rootProps={{ 'data-testid': '1' }}
                    chartEvents={[
                      {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                          var handler = function (e) {
                            const index = e.targetID.split("#")[1];
                            const value = Object.keys(data.stats.prescribed)[index];
                            if (value != undefined) {
                              history.push({
                                pathname: "/stats/products/reports",
                                state: {
                                  maxdate: endDate,
                                  mindate: startDate,
                                  product_id: selectedId,
                                  search_column: "prescribed",
                                  search_key: value,
                                  team: team,
                                  Filtre: "Préscrit",
                                  Paramétre: value === "true" ? "Oui" : value === "false" ? "Non" : value
                                }
                              });
                            }
                          };
                          google.visualization.events.addListener(
                            chartWrapper.getChart(),
                            "click",
                            handler
                          );
                        }
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
            : <></>
          }
          {data.stats && Object.keys(data.stats.frequancy).length > 1 ?
            <Col md="6">
              <Card>
                <CardBody>
                  <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="PieChart"
                    loader={<div>{"Loading Chart"}</div>}
                    data={dataMaker(data.stats.frequancy)}
                    options={{
                      title: 'Fréquance de préscription',
                      colors: ["#7366ff", "#51bb25", "#f8d62b", "#f73164", "#a927f9"] // need lots of colors maybe an external array
                    }}
                    rootProps={{ 'data-testid': '1' }}
                    chartEvents={[
                      {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                          var handler = function (e) {
                            const index = e.targetID.split("#")[1];
                            const value = Object.keys(data.stats.frequancy)[index];
                            if (value != undefined) {
                              history.push({
                                pathname: "/stats/products/reports",
                                state: {
                                  maxdate: endDate,
                                  mindate: startDate,
                                  product_id: selectedId,
                                  search_column: "frequancy",
                                  search_key: value,
                                  team: team,
                                  Filtre: "Fréquance de préscription",
                                  Paramétre: value === "true" ? "Oui" : value === "false" ? "Non" : value
                                }
                              });
                            }
                          };
                          google.visualization.events.addListener(
                            chartWrapper.getChart(),
                            "click",
                            handler
                          );
                        }
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
            : <></>
          }
          {data.stats && Object.keys(data.stats.notprescribed).length > 1 ?
            <Col md="6">
              <Card>
                <CardBody>
                  <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="PieChart"
                    loader={<div>{"Loading Chart"}</div>}
                    data={dataMaker(data.stats.notprescribed)}
                    options={{
                      title: 'Pourquoi non préscrit',
                      colors: ["#7366ff", "#51bb25", "#f8d62b", "#f73164", "#a927f9"] // need lots of colors maybe an external array
                    }}
                    rootProps={{ 'data-testid': '1' }}
                    chartEvents={[
                      {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                          var handler = function (e) {
                            const index = e.targetID.split("#")[1];
                            const value = Object.keys(data.stats.notprescribed)[index];
                            if (value != undefined) {
                              history.push({
                                pathname: "/stats/products/reports",
                                state: {
                                  maxdate: endDate,
                                  mindate: startDate,
                                  product_id: selectedId,
                                  search_column: "notprescribed",
                                  search_key: value,
                                  team: team,
                                  Filtre: "Pourquoi non préscrit",
                                  Paramétre: value === "true" ? "Oui" : value === "false" ? "Non" : value
                                }
                              });
                            }
                          };
                          google.visualization.events.addListener(
                            chartWrapper.getChart(),
                            "click",
                            handler
                          );
                        }
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
            : <></>
          }
          {data.stats && Object.keys(data.stats.notintrest).length > 1 ?
            <Col md="6">
              <Card>
                <CardBody>
                  <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="PieChart"
                    loader={<div>{"Loading Chart"}</div>}
                    data={dataMaker(data.stats.notintrest)}
                    options={{
                      title: 'Pourquoi non intéressée',
                      colors: ["#7366ff", "#51bb25", "#f8d62b", "#f73164", "#a927f9"] // need lots of colors maybe an external array
                    }}
                    rootProps={{ 'data-testid': '1' }}
                    chartEvents={[
                      {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                          var handler = function (e) {
                            const index = e.targetID.split("#")[1];
                            const value = Object.keys(data.stats.notintrest)[index];
                            if (value != undefined) {
                              history.push({
                                pathname: "/stats/products/reports",
                                state: {
                                  maxdate: endDate,
                                  mindate: startDate,
                                  product_id: selectedId,
                                  search_column: "notintrest",
                                  search_key: value,
                                  team: team,
                                  Filtre: "Pourquoi non intéressée",
                                  Paramétre: value === "true" ? "Oui" : value === "false" ? "Non" : value
                                }
                              });
                            }

                          };
                          google.visualization.events.addListener(
                            chartWrapper.getChart(),
                            "click",
                            handler
                          );
                        }
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
            : <></>
          }
          {data.reports && data.reports.length > 0 ?
            <Col md="12" className='mb-3'>
              <h4>Pharmaciens</h4>
            </Col>
            :
            <></>
          }
          {data.stats && Object.keys(data.stats.available).length > 1 ?
            <Col md="6">
              <Card>
                <CardBody>
                  <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="PieChart"
                    loader={<div>{"Loading Chart"}</div>}
                    data={dataMaker(data.stats.available)}
                    options={{
                      title: 'Disponibilité',
                      colors: ["#7366ff", "#51bb25", "#f8d62b", "#f73164", "#a927f9"] // need lots of colors maybe an external array
                    }}
                    rootProps={{ 'data-testid': '1' }}
                    chartEvents={[
                      {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                          var handler = function (e) {
                            const index = e.targetID.split("#")[1];
                            const value = Object.keys(data.stats.available)[index];
                            if (value != undefined) {
                              history.push({
                                pathname: "/stats/products/reports",
                                state: {
                                  maxdate: endDate,
                                  mindate: startDate,
                                  product_id: selectedId,
                                  search_column: "available",
                                  search_key: value,
                                  team: team,
                                  Filtre: "Disponibilité",
                                  Paramétre: value === "true" ? "Oui" : value === "false" ? "Non" : value
                                }
                              });
                            }
                          };
                          google.visualization.events.addListener(
                            chartWrapper.getChart(),
                            "click",
                            handler
                          );
                        }
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
            : <></>
          }
          {data.stats && Object.keys(data.stats.notavailabel).length > 1 ?
            <Col md="6">
              <Card>
                <CardBody>
                  <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="PieChart"
                    loader={<div>{"Loading Chart"}</div>}
                    data={dataMaker(data.stats.notavailabel)}
                    options={{
                      title: 'Pourquoi non disponible',
                      colors: ["#7366ff", "#51bb25", "#f8d62b", "#f73164", "#a927f9"] // need lots of colors maybe an external array
                    }}
                    rootProps={{ 'data-testid': '1' }}
                    chartEvents={[
                      {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                          var handler = function (e) {
                            const index = e.targetID.split("#")[1];
                            const value = Object.keys(data.stats.notavailabel)[index];
                            if (value != undefined) {
                              history.push({
                                pathname: "/stats/products/reports",
                                state: {
                                  maxdate: endDate,
                                  mindate: startDate,
                                  product_id: selectedId,
                                  search_column: "notavailabel",
                                  search_key: value,
                                  team: team,
                                  Filtre: "Pourquoi non disponible",
                                  Paramétre: value === "true" ? "Oui" : value === "false" ? "Non" : value
                                }
                              });
                            }
                          };
                          google.visualization.events.addListener(
                            chartWrapper.getChart(),
                            "click",
                            handler
                          );
                        }
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
            : <></>
          }
          {data.stats && Object.keys(data.stats.refusal).length > 1 ?
            <Col md="6">
              <Card>
                <CardBody>
                  <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="PieChart"
                    loader={<div>{"Loading Chart"}</div>}
                    data={dataMaker(data.stats.refusal)}
                    options={{
                      title: 'Motif de refus',
                      colors: ["#7366ff", "#51bb25", "#f8d62b", "#f73164", "#a927f9"] // need lots of colors maybe an external array
                    }}
                    rootProps={{ 'data-testid': '1' }}
                    chartEvents={[
                      {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                          var handler = function (e) {
                            const index = e.targetID.split("#")[1];
                            const value = Object.keys(data.stats.refusal)[index];
                            if (value != undefined) {
                              history.push({
                                pathname: "/stats/products/reports",
                                state: {
                                  maxdate: endDate,
                                  mindate: startDate,
                                  product_id: selectedId,
                                  search_column: "refusal",
                                  search_key: value,
                                  team: team,
                                  Filtre: "Motif de refus",
                                  Paramétre: value === "true" ? "Oui" : value === "false" ? "Non" : value
                                }
                              });
                            }
                          };
                          google.visualization.events.addListener(
                            chartWrapper.getChart(),
                            "click",
                            handler
                          );
                        }
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
            : <></>
          }
          {data.stats && Object.keys(data.stats.rotation).length > 1 ?
            <Col md="6">
              <Card>
                <CardBody>
                  <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="PieChart"
                    loader={<div>{"Loading Chart"}</div>}
                    data={dataMaker(data.stats.rotation)}
                    options={{
                      title: 'Rotation',
                      colors: ["#7366ff", "#51bb25", "#f8d62b", "#f73164", "#a927f9"] // need lots of colors maybe an external array
                    }}
                    rootProps={{ 'data-testid': '1' }}
                    chartEvents={[
                      {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                          var handler = function (e) {
                            const index = e.targetID.split("#")[1];
                            const value = Object.keys(data.stats.rotation)[index];
                            if (value != undefined) {
                              history.push({
                                pathname: "/stats/products/reports",
                                state: {
                                  maxdate: endDate,
                                  mindate: startDate,
                                  product_id: selectedId,
                                  search_column: "rotation",
                                  search_key: value,
                                  team: team,
                                  Filtre: "Rotation",
                                  Paramétre: value === "true" ? "Oui" : value === "false" ? "Non" : value
                                }
                              });
                            }
                          };
                          google.visualization.events.addListener(
                            chartWrapper.getChart(),
                            "click",
                            handler
                          );
                        }
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
            : <></>
          }
          {data.stats && Object.keys(data.stats.renewel).length > 1 ?
            <Col md="6">
              <Card>
                <CardBody>
                  <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="PieChart"
                    loader={<div>{"Loading Chart"}</div>}
                    data={dataMaker(data.stats.renewel)}
                    options={{
                      title: 'Renouvellement',
                      colors: ["#7366ff", "#51bb25", "#f8d62b", "#f73164", "#a927f9"] // need lots of colors maybe an external array
                    }}
                    rootProps={{ 'data-testid': '1' }} chartEvents={[
                      {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                          var handler = function (e) {
                            const index = e.targetID.split("#")[1];
                            const value = Object.keys(data.stats.renewel)[index];
                            if (value != undefined) {
                              history.push({
                                pathname: "/stats/products/reports",
                                state: {
                                  maxdate: endDate,
                                  mindate: startDate,
                                  product_id: selectedId,
                                  search_column: "renewel",
                                  search_key: value,
                                  team: team,
                                  Filtre: "Renouvellement",
                                  Paramétre: value === "true" ? "Oui" : value === "false" ? "Non" : value
                                }
                              });
                            }
                          };
                          google.visualization.events.addListener(
                            chartWrapper.getChart(),
                            "click",
                            handler
                          );
                        }
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
            : <></>
          }
        </Row>
      </Container>
    </Fragment>
  )
}
