import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col, Table, Card, CardBody, CardHeader, } from 'reactstrap';
import { toast } from 'react-toastify';
import { useLocation, Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '../../layout/breadcrumb';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import XlsxPopulate from "xlsx-populate";

export default function DetailedReport() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [supervisorsList, setSupervisorsList] = useState([])
  const [duoVisite, setDuoVisite] = useState(false)
  const [report, setReport] = useState({ products_reports: [] });
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const [isEditing, setIsEditing] = useState(false)
  const location = useLocation();
  const reportID = location.state != null ? location.state.reportID : "";
  const contact = {
    contact_id: location.state == null ? "" : location.state.contact_id,
    lastname: location.state == null ? "" : location.state.contact_lastname,
    firstname: location.state == null ? "" : location.state.contact_firstname,
    speciality: location.state == null ? "" : location.state.contact_speciality,
    address: location.state == null ? "" : location.state.contact_address,
    wilaya: location.state == null ? "" : location.state.contact_wilaya,
    potentiel: location.state == null ? "" : location.state.contact_potentiel,
    prescription_possibility: location.state == null ? "" : location.state.contact_prescription_possibility
  }
  const agent = {
    user_id: location.state == null ? "" : location.state.agent_id,
    lastname: location.state == null ? "" : location.state.agent_lastname,
    firstname: location.state == null ? "" : location.state.agent_firstname,
    network: location.state == null ? "" : location.state.agent_network,

  }

  const exportedRow = [
    report ? report.report_done_on : "",
    location.state == null ? "" : location.state.agent_lastname + " " + location.state.agent_firstname,
    location.state == null ? "" : location.state.contact_lastname + " " + location.state.contact_firstname,
    location.state == null ? "" : location.state.contact_speciality,
    location.state == null ? "" : location.state.contact_potentiel,
    location.state == null ? "" : location.state.contact_wilaya,
    location.state == null ? "" : location.state.contact_city,
    location.state == null ? "" : location.state.contact_id,
  ]

  //Fetch API call
  const fetchData = async () => {
    if (!reportID) {
      return;
    }
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ report_id: reportID })
    }

    const requestSupervisor = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      // body: JSON.stringify({ report_id: reportID })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/reports/report", request, { mode: "cors" });
      var response = await fetch("https://lsreport.shls.care/api/users/supervisors/all", requestSupervisor, { mode: "cors" })
      if (responce.status === 200) {
        let data = await responce.json();
        console.log(data);
        setReport(data);
        setDuoVisite(data.report_dual)
      } else {
        console.log(await responce.json());
        toast.error(await responce.json());
      }
      if (response.status === 200) {
        let data = await response.json();
        console.log(data);
        setSupervisorsList(data);
      } else {
        console.log(await response.json());
        toast.error(await response.json());
      }
    } catch (err) {
      setLoading(false);
      console.log("zzzzz");
      toast.error(err.message);
    }
    setLoading(false);
  }

  const updateReport = async (id) => {
    setLoading(true);
    console.log(report.products_reports)

    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        report_id: report?.report_id,
        report_dual: report?.report_dual,
        supervisor_id: report?.supervisor_id,
        report_category: report?.report_category,
        report_visited_person: report?.report_visited_person,
        report_visitor_comment: report?.report_visitor_comment,
        report_visited_comment: report?.report_visited_comment,
        report_done_on: report?.report_done_on,
        report_gps_valid: report?.report_gps_valid,
        products_report: report?.products_reports,
        report_type: report?.report_type,
        report_dual: duoVisite
      })
    }
    try {
      var response = await fetch(`https://lsreport.shls.care/api/reports/report/update`, request, { mode: "cors" });
      if (response.status === 200) {
        toast.success("Rapport mis à jour avec succès");
        setIsEditing(false)
        history.push(`${process.env.PUBLIC_URL}/visites/rapports/`);
      } else {
        toast.error("Erreur !");
      }
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  }


  async function saveAsExcel() {
    let header = ["Date", "Visiteur", "Contact", "Spécialité", "Potentiel", "Wilaya", "Commune", "ID Compte"];
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0).name("Rapport Visite");
      const sheetData = [header, exportedRow]
      const totalColumns = exportedRow.length;
      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "339EFF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        FileSaver.saveAs(res, "Rapport_Visite " + location.state && (location.state.agent_lastname + " " + location.state.agent_firstname) + " " + report?.report_done_on.split(" ", 1) + ".xlsx");
      });
    });
  }

  useEffect(() => {
    if (reportID != "") {
      fetchData();
    }
    window.scrollTo(0, 0);
  }, [location.pathname])

  const updateProductReport = (index, updatedData) => {
    const updatedProductsReports = [...report.products_reports];
    updatedProductsReports[index] = { ...updatedProductsReports[index], ...updatedData };
    setReport({ ...report, products_reports: updatedProductsReports });
  };


  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Visites" title="Rapports" hide={false} />
      <Container fluid={true} className='pb-4 mb-4'>
        <Row>
          <Col md={"12"} className='f-right d-print-none'>
            <button className='btn btn-success f-right mt-0 mb-2' onClick={(e) => { saveAsExcel() }}><i className='fa fa-file-excel-o mr-2'></i> Exporter</button>
            {
              isEditing ?
                <button className='btn btn-danger f-right mt-0 mb-2 mr-2' onClick={(e) => { setIsEditing(false) }}><i className='fa fa-pencil mr-2'></i> Annuler</button>
                :
                <button className='btn btn-info f-right mt-0 mb-2 mr-2' onClick={(e) => { setIsEditing(true) }}><i className='fa fa-pencil mr-2'></i> Modifier</button>
            }
          </Col>
        </Row>
        <Row >
          <Col sm={5}>

            <Card>
              <CardBody>
                <Table>
                  <tbody>
                    <tr>
                      <th>Visiteur</th>
                      <td>
                        <Link to={`${process.env.PUBLIC_URL}/users/user:${agent.user_id}`}>
                          {`${agent.lastname} ${agent.firstname}`}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th>Equipe</th>
                      <td>{agent.network}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col sm={7}>
            <Card>
              <CardBody>
                <Table>
                  <tbody>
                    <tr>
                      <th>Contact</th>
                      <td>
                        <Link to={`${process.env.PUBLIC_URL}/contacts/contact:${contact.contact_id}`}>
                          {`${contact.lastname} ${contact.firstname}`}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th>Specialité</th>
                      <td>{contact.speciality}</td>
                    </tr>
                    <tr>
                      <th>Potentiel</th>
                      <td>{contact.potentiel}</td>
                    </tr>
                    <tr>
                      <th>Adresse</th>
                      <td>{contact.address} {contact.wilaya}</td>
                    </tr>
                    <tr>
                      <th>Possibilité de prescription</th>
                      <td>{contact.prescription_possibility}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h5>Detail visite</h5>
            <Card>
              <CardBody style={{ overflow: "auto" }}>
                <Table>
                  <thead>
                    <tr>
                      <th>Date visite</th>
                      <th>Etat visite</th>
                      <th>Catégorie</th>
                      <th>Personne visitée</th>
                      <th>Visite en duo</th>
                      <th>Début visite</th>
                      <th>Fin visite</th>
                      <th>Durée</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {moment(report?.report_done_on).format("DD/MM/yyyy à HH:mm")}
                      </td>
                      <td>
                        {
                          isEditing ?
                            <select onChange={(e) => { setReport({ ...report, report_type: e.target.value }); }}>
                              <option selected={report?.report_type === "doctor" ? true : false} value={"doctor"}>Médecin</option>
                              <option selected={report?.report_type === "pharmacy" ? true : false} value={"pharmacy"}>Pharmacien</option>
                              <option selected={report?.report_type === "unvailibility" ? true : false} value={"unvailibility"}>Contact indisponible</option>
                            </select>
                            : <>{report?.report_type === "unvailibility" ? "Contact indisponible" : "Contact disponible"}</>
                        }
                      </td>
                      <td>
                        {
                          isEditing ?
                            <select onChange={(e) => { setReport({ ...report, report_category: e.target.value }); }}>
                              <option value={"Sous résidence"} selected={report?.report_category === "Sous résidence" ? true : false}>Sous résidence</option>
                              <option value={"Hors résidence"} selected={report?.report_category === "Hors résidence" ? true : false}>Hors résidence</option>
                              <option value={"Commune"} selected={report?.report_category === "Commune" ? true : false}>Commune</option>
                            </select> : <>{report?.report_category}</>
                        }
                      </td>
                      <td>
                        {
                          isEditing ?
                            <select onChange={(e) => { setReport({ ...report, report_visited_person: e.target.value }); }}>
                              <option selected={report?.report_visited_person === "Remplacent" ? true : false} value={"Remplacent"}>Remplacent</option>
                              <option selected={report?.report_visited_person === "Médecin" ? true : false} value={"Médecin"}>Médecin</option>
                              <option selected={report?.report_visited_person === "Pharmacien" ? true : false} value={"Pharmacien"}>Pharmacien</option>
                              <option selected={report?.report_visited_person === "Vendeur" ? true : false} value={"Vendeur"}>Vendeur</option>
                              <option selected={report?.report_visited_person === "Acheteur" ? true : false} value={"Acheteur"}>Acheteur</option>
                              <option selected={report?.report_visited_person === "Autre" ? true : false} value={"Autre"}>Autre</option>
                            </select> :
                            <>{report?.report_visited_person}</>
                        }
                      </td>
                      <td>
                        {
                          isEditing ?
                            <>
                              {
                                duoVisite ?
                                  <>
                                    <select onChange={(e) => { e.target.value === "false" ? setDuoVisite(false) : setReport({ ...report, supervisor_id: e.target.value }); }}>
                                      {
                                        supervisorsList.map((item, index) => (
                                          <option key={index} value={item.user_id}>{item.user_firstname + " " + item.user_lastname}</option>
                                        ))
                                      }
                                      <option value={false}>Non</option>
                                    </select>
                                  </> :
                                  <select onChange={(e) => { setDuoVisite(e.target.value) }}>
                                    <option selected={duoVisite ? true : false} value={true}>Oui</option>
                                    <option selected={!duoVisite ? true : false} value={false}>Non</option>
                                  </select>
                              }
                            </>
                            :
                            <>
                              {report?.report_dual ?
                                <Link to={`${process.env.PUBLIC_URL}/users/user:${report?.supervisor_id}`}>{report?.supervisor_name}</Link>
                                : "Non"}
                            </>
                        }
                      </td>
                      <td>
                        {report?.report_start_time == null ? "" : moment(report?.report_start_time).format("DD/MM/yyyy à HH:mm")}
                      </td>
                      <td>
                        {report?.report_end_time == null ? "" : moment(report?.report_end_time).format("DD/MM/yyyy à HH:mm")}
                      </td>
                      <td style={
                        (moment(report?.report_end_time).diff(moment(report?.report_start_time), "minutes")) > 5 ? { color: "green" } : { color: "red" }
                      }>{(moment(report?.report_end_time).diff(moment(report?.report_start_time), "minutes")) > 0 ? (moment(report?.report_end_time).diff(moment(report?.report_start_time), "minutes")) : 0} Minute(s)</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className='o-auto'>
            <h5>Produits présentés</h5>
            <Card className='o-auto'>
              <CardBody>
                {report?.report_type === "pharmacy" ?
                  <Table>
                    <thead>
                      <tr>
                        <th>Produit</th>
                        <th>Disponible</th>
                        <th>Indisponible?</th>
                        <th>Echantillon?</th>
                        <th>Quantité</th>
                        <th>Rotation</th>
                        <th>Renouvellement commande</th>
                        <th>Pourquoi refus</th>
                        <th>Commentaire</th>
                      </tr>
                    </thead>
                    <tbody>
                      {report?.products_reports.map((prod) => {
                        return (
                          <tr key={report?.products_reports.indexOf(prod)}>
                            <td>{prod?.prodcut_name}</td>
                            <td>
                              {
                                isEditing ?
                                <select onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_not_availabel: e.target.value }) }}>
                                  <option selected={prod?.product_report_availabel === true ? true : false} value={true}>Oui</option>
                                  <option selected={prod?.product_report_availabel === false ? true : false} value={false}>Non</option>
                                </select> :
                                <>{prod?.product_report_availabel}</>
                              }
                            </td>
                            <td>
                              {
                                isEditing ?
                                <select onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_not_availabel: e.target.value }) }}>
                                  <option selected={prod?.product_report_not_availabel === "Non connu" ? true : false} value={"Non connu"}>Non connu</option>
                                  <option selected={prod?.product_report_not_availabel === "Non disponible chez le fournisseur" ? true : false} value={"Non disponible chez le fournisseur"}>Non disponible chez le fournisseur</option>
                                  <option selected={prod?.product_report_not_availabel === "Non prescrit" ? true : false} value={"Non prescrit"}>Non prescrit</option>
                                  <option selected={prod?.product_report_not_availabel === "Non renouvelé / Refus de commander" ? true : false} value={"Non renouvelé / Refus de commander"}>Non renouvelé / Refus de commander</option>
                                  <option selected={prod?.product_report_not_availabel === "Renouvellement en cours" ? true : false} value={"Renouvellement en cours"}>Renouvellement en cours</option>
                                </select> :
                                <>{prod?.product_report_not_availabel}</>
                              }
                            </td>
                            <td>{(prod?.product_report_remis && prod?.product_report_quantity > 0) ? "Oui" : "Non"}</td>
                            <td>{(prod?.product_report_quantity > 0) ? prod?.product_report_quantity : 0}</td>
                            <td>
                              {
                                isEditing ?
                                <select onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_rotation: e.target.value }) }}>
                                  <option selected={prod?.product_report_rotation === "0-10/sem" ? true : false} value={"0-10/sem"}>0-10/sem</option>
                                  <option selected={prod?.product_report_rotation === "10-20/sem" ? true : false} value={"10-20/sem"}>10-20/sem</option>
                                  <option selected={prod?.product_report_rotation === "> 20/sem" ? true : false} value={"> 20/sem"}>{"> 20/sem"}</option>
                                </select> :
                                <>{prod?.product_report_rotation}</>
                              }
                            </td>
                            <td>
                              {
                                isEditing ?
                                <select onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_renewal: e.target.value }) }}>
                                  <option selected={prod?.product_report_renewal === true ? true : false} value={true}>Oui</option>
                                  <option selected={prod?.product_report_renewal === false ? true : false} value={false}>Non</option>
                                </select> :
                                <>{prod?.product_report_renewal}</>
                              }
                            </td>
                            <td>
                              {
                                isEditing ?
                                <select onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_refusal: e.target.value }) }}>
                                  <option selected={prod?.product_report_refusal === "Concurrence" ? true : false} value={"Concurrence"}>Concurrence</option>
                                  <option selected={prod?.product_report_refusal === "Produit cher / Psychotrope" ? true : false} value={"Produit cher / Psychotrope"}>Produit cher / Psychotrope</option>
                                  <option selected={prod?.product_report_refusal === "Rotation faible" ? true : false} value={"Rotation faible"}>{"Rotation faible"}</option>
                                  <option selected={prod?.product_report_refusal === "remises basses" ? true : false} value={"remises basses"}>{"remises basses"}</option>
                                </select> :
                                <>{prod?.product_report_refusal}</>
                              }
                            </td>
                            {
                                isEditing ?
                                  <input type='text' defaultValue={prod?.product_report_comment} onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_comment: e.target.value }) }} placeholder='Commentaire' />
                                  :
                                  <>{prod?.product_report_comment}</>
                              }
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  :
                  <Table>
                    <thead>
                      <tr>
                        <th>Produit</th>
                        <th>Préscrit</th>
                        <th>Fréquence prescription</th>
                        <th>Pourquoi non préscrit?</th>
                        <th>Pourquoi non interessé?</th>
                        <th>Commentaire</th>
                        <th>Echantillon?</th>
                        <th>Quantité</th>
                      </tr>
                    </thead>
                    <tbody>
                      {report?.products_reports.map((prod) => {
                        return (
                          <tr key={report?.products_reports.indexOf(prod)}>
                            <td>{prod?.prodcut_name}</td>
                            <td>
                              {
                                isEditing ?
                                  <select onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_prescribed: e.target.value }) }}>
                                    <option selected={prod?.product_report_prescribed ? true : false} value={true}>Oui</option>
                                    <option selected={!prod?.product_report_prescribed ? true : false} value={false}>Non</option>
                                  </select> :
                                  <>{prod?.product_report_prescribed ? "Oui" : "Non"}</>
                              }
                            </td>
                            <td>
                              {
                                isEditing ?
                                  <select onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_frequancy: e.target.value }) }}>
                                    <option selected={prod?.product_report_frequancy === "Fréquente" ? true : false} value={"Fréquente"}>Fréquente</option>
                                    <option selected={prod?.product_report_frequancy === "Moyenne" ? true : false} value={"Moyenne"}>Moyenne</option>
                                    <option selected={prod?.product_report_frequancy === "Rare" ? true : false} value={"Rare"}>Rare</option>
                                  </select> :
                                  <>{prod?.product_report_frequancy}</>
                              }
                            </td>
                            <td>
                              {
                                isEditing ?
                                  <select placeholder='Pourquoi non préscrit?' onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_not_prescribed: e.target.value }) }}>
                                    <option value={"Non intéressé"} selected={prod?.product_report_not_prescribed === "Non intéressé" ? true : false}>Non intéressé</option>
                                    <option value={"Non présenté"} selected={prod?.product_report_not_prescribed === "Non présenté" ? true : false}>Non présenté</option>
                                    <option value={"Oublié"} selected={prod?.product_report_not_prescribed === "Oublié" ? true : false}>Oublié</option>
                                  </select>
                                  :
                                  <>{prod?.product_report_not_prescribed}</>
                              }
                            </td>
                            <td>
                              {
                                isEditing ?
                                  <select placeholder='Pourquoi non interessé?' onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_intrest: e.target.value }) }}>
                                    <option value={"Contre les génériques"} selected={prod?.product_report_intrest === "Contre les génériques" ? true : false}>Contre les génériques</option>
                                    <option value={"Antécédents d'effets indisérables"} selected={prod?.product_report_intrest === "Antécédents d'effets indisérables" ? true : false}>Antécédents d'effets indisérables</option>
                                    <option value={"Non disponible en officine"} selected={prod?.product_report_intrest === "Non disponible en officine" ? true : false}>Non disponible en officine</option>
                                    <option value={"Non concerné par le produit"} selected={prod?.product_report_intrest === "Non concerné par le produit" ? true : false}>Non concerné par le produit</option>
                                    <option value={"Produit concurrent"} selected={prod?.product_report_intrest === "Produit concurrent" ? true : false}>Produit concurrent</option>
                                    <option value={"Pas d'indication"} selected={prod?.product_report_intrest === "Pas d'indication" ? true : false}>Pas d'indication</option>
                                  </select>
                                  :
                                  <>{prod?.product_report_intrest}</>
                              }
                            </td>
                            <td>
                              {
                                isEditing ?
                                  <input type='text' defaultValue={prod?.product_report_comment} onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_comment: e.target.value }) }} placeholder='Commentaire' />
                                  :
                                  <>{prod?.product_report_comment}</>
                              }
                            </td>
                            <td>
                              {
                                isEditing ?
                                  <select onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_remis: e.target.value }) }}>
                                    <option selected={prod?.product_report_remis ? true : false} value={true}>Oui</option>
                                    <option selected={!prod?.product_report_remis ? true : false} value={false}>Non</option>
                                  </select> :
                                  <>{(prod?.product_report_remis && prod?.product_report_quantity > 0) ? "Oui" : "Non"}</>
                              }
                            </td>
                            <td>
                              {
                                isEditing && prod?.product_report_remis ?
                                  <input type='number' onChange={(e) => { updateProductReport(report?.products_reports.indexOf(prod), { product_report_quantity: e.target.value }) }} /> :
                                  <>{(prod?.product_report_remis) ? prod?.product_report_quantity : 0}</>
                              }
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Commentaire visiteur</h5>
            <Card>
              <CardBody>
                {
                  isEditing ?
                    <input type='text' placeholder='Commentaire du visiteur ' defaultValue={report?.report_visitor_comment} onChange={(e) => { setReport({ ...report, report_visitor_comment: e.target.value }); }} /> :
                    <em>{report?.report_visitor_comment}</em>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='pb-4'>
          <Col className='pb-4'>
            <h5>Commentaire de la personne visitée</h5>
            <Card>
              <CardBody className='mb-4'>
                {
                  isEditing ?
                    <input placeholder='Commentaire de la personne visitée' onChange={(e) => { setReport({ ...report, report_visited_comment: e.target.value }); }} type='text' defaultValue={report?.report_visited_comment} /> :
                    <em>{report?.report_visited_comment}</em>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        {
          isEditing &&
          <Row md={12} className="justify-content-center mb-4">
            <button className='btn btn-success f-right mt-0 mb-2 mr-2' onClick={(e) => { updateReport() }}><i className='fa fa-pencil mr-2'></i> Sauvgarder</button>
          </Row>
        }
      </Container>
    </Fragment >
  )
}