import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Button, Form, FormGroup, Table, Card, CardBody, Pagination, PaginationItem, PaginationLink, } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import DatePicker from "react-datepicker";

export default function Bdc() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date())
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const user_id = token.length > 0 ? jwt.decode(token).id : "";
  const fetchData = async () => {
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ start_date:startDate
        ,end_date: endDate})
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/bdc/all?user_id=" + user_id, request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        data = data.map((bdc) => {
          var arraySum = bdc.bdc_products.reduce(function (total, product) {
            var price = parseFloat(product.product_info?.product_price);
            var productTotal = parseInt(product.deliveredQuantity) * price;
            if (isNaN(price) || isNaN(productTotal)) {
              return total + 0
            } else {
              return total + productTotal;
            }
          }, 0);
          arraySum = arraySum.toLocaleString('Fr-DZ', {
            style: 'currency',
            currency: 'DZD'
          });
          return {
            ...bdc,
            total: arraySum
          }
        })
        setData(data);
      } else {

        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const exportDetails = async (id) => {
    setLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      }
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/bdc/export?bdc_id=" + id, request, { mode: "cors" });
      if (responce.status === 200) {
        const blob = await responce.blob();
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = ('Bon de commande ' + id) + '.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const exportBdcCr = async () => {
    setLoading(true);
    const request = {
      method: "GET",
      headers: {
        // 'Accept': GetMineType(ext),
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
    //   body: JSON.stringify({
    //     start_date: location.state && location.state.mindate,
    //     end_date: location.state && location.state.maxdate,
    //     user_id: location.state && location.state.id,
    //     supervisor_id: location.state && location.state.supervisor_id
    //   })
    }
    try {
      var responce = await fetch(`https://lsreport.shls.care/api/bdc/extract/ca`, request, { mode: "cors" });
      if (responce.status === 200) {
        const blob = await responce.blob();
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "BDC_par_chiffre_realise" + '.xlsx'; // Set the desired file name
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up resources
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
        toast.success("success");

      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }

  const exportBdcProduit = async () => {
    setLoading(true);
    const request = {
      method: "GET",
      headers: {
        // 'Accept': GetMineType(ext),
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
    //   body: JSON.stringify({
    //     start_date: location.state && location.state.mindate,
    //     end_date: location.state && location.state.maxdate,
    //     user_id: location.state && location.state.id,
    //     supervisor_id: location.state && location.state.supervisor_id
    //   })
    }
    try {
      var responce = await fetch(`https://lsreport.shls.care/api/bdc/extract/product`, request, { mode: "cors" });
      if (responce.status === 200) {
        const blob = await responce.blob();
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "BDC_par_produit" + '.xlsx'; // Set the desired file name
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up resources
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
        toast.success("success");

      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }



  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [page])


  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="BDC" title="BDC" />

      <Container fluid={true} className='pb-4 mb-4'>
      <Row>
          <Col md="2">
            <label htmlFor="">Du:</label>
            <DatePicker dateFormat={"dd/MM/yyyy"} selected={startDate} maxDate={new Date()} onChange={(date) => setStartDate(date)} />
          </Col>
          <Col md="2">
            <label htmlFor="">Au:</label>
            <DatePicker dateFormat={"dd/MM/yyyy"} minDate={startDate} maxDate={new Date()} selected={endDate} onChange={(date) => setEndDate(date)} />
          </Col>
          <Col md="2">
            <Button color='success' onClick={() => { fetchData() }} style={{ marginTop: "32px" }}>Rechercher</Button>
          </Col>
        </Row>
        <Row className='d-flex justify-content-end mb-2'>
          <button className='btn btn-success mr-2' onClick={() => { exportBdcCr() }}> Exporter par cr</button>
          <button className='btn btn-success mr-2' onClick={() => { exportBdcProduit() }}> Exporter par produit</button>
        </Row>

        {data.length === 0 ?
          <Card className='text-align-center'>
            <CardBody style={{ textAlign: "center" }}>
              <h4>Aucun BDC à afficher</h4>
            </CardBody>
          </Card>
          :
          <>
            <Row>
              <Col xs={12} className='o-auto'>
                <Table style={{ background: "white" }}>
                  <thead>
                    <tr className='text-center'>
                      <th>#</th>
                      <th>Code</th>
                      <th>Visiteur</th>
                      <th>Client</th>
                      <th>Pack</th>
                      <th>Grossiste</th>
                      <th>Wilaya</th>
                      <th>Commune</th>
                      <th>Total</th>
                      <th>Date</th>
                      <th>Statut</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((bdc) => {
                      return (
                        <tr key={data.indexOf(bdc)} className='text-center'>
                          <th scope='row'> {(data.indexOf(bdc) + 1)}</th>
                          <td>{bdc.bdc_id}</td>
                          <td>{bdc.user_lastname} {bdc.user_firstname}</td>
                          <td>{bdc.contact_lastname} {bdc.contact_firstname}  </td>
                          <td>{bdc.bdc_pack}</td>
                          <td>{bdc.bdc_grossiste}</td>
                          <td>{bdc.bdc_wilaya}</td>
                          <td>{bdc.bdc_commune}</td>
                          <td>{bdc.bdc_total + " DA"}</td>
                          <td>{moment(bdc.bdc_date).format("DD/MM/YYYY")}</td>
                          <td>
                            {
                              (() => {
                                if (bdc.bdc_status === "Annulé" || bdc.bdc_status === "annulé")
                                  return <span className="badge badge-pill badge-danger text-light p-2">{bdc.bdc_status}</span>
                                if (bdc.bdc_status === "honoré" || bdc.bdc_status === "Honoré")
                                  return <span className="badge badge-pill badge-success text-light p-2">{bdc.bdc_status.charAt(0).toUpperCase() + bdc.bdc_status.slice(1)}</span>
                                if (bdc.bdc_status === "Instance" || bdc.bdc_status === "instance")
                                  return <span className="badge badge-pill badge-primary text-light p-2">En {bdc.bdc_status}</span>
                                else
                                  return <span className="badge badge-pill badge-warning text-dark p-2">{bdc.bdc_status.charAt(0).toUpperCase() + bdc.bdc_status.slice(1)}</span>
                              })()
                            }
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <Link to={{
                              pathname: `${process.env.PUBLIC_URL}/bdc/detailed`,
                              state: {
                                bdc: bdc,
                              }
                            }}>
                              <i className="fa fa-table mr-2" id="TooltipExample"></i>
                            </Link>
                            <i className="fa fa-file-excel-o mr-2 text-success " role="button" onClick={() => { exportDetails(bdc.bdc_id) }}></i>

                          </td>
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </Table>
              </Col>
            </Row>

          </>
        }

      </Container>
    </Fragment>
  )
}
